import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box, Button, Container, Grid, IconButton } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import { useProfile } from "../../../hooks/profile";
import { Label, RequiredStar } from "../../../styles/styles";
import { whatsAppInvoice } from "../../../services/gisService";
import {} from "styled-components/macro";
import SelectIRNModal from "../SelectIRNModal";
import { defaultErrorToast } from "../../../styles/global";

export const WhatsAppEInvoice = () => {
	const { orgId } = useProfile();

	const { enqueueSnackbar } = useSnackbar();

	const history = useHistory();

	const { register, errors, handleSubmit, reset, clearErrors } = useForm({
		mode: "onTouched",
		defaultValues: {},
	});

	const [isIRNSelected, setIRNSelected] = useState(true);
	const [selectedIRN, setSelectedIRN] = useState();

	const openIRNDialog = () => {
		setShowIRNModal(true);
	};

	const [showIRNSearchModal, setShowIRNModal] = useState(!isIRNSelected);
	const handleIRNModalClose = () => setShowIRNModal(false);

	const irnSelector = (choosenIRN) => {
		setSelectedIRN(choosenIRN);
		setIRNSelected(true);
		if (errors.irn) {
			errors.irn.message = "";
		}
	};

	// Submit
	const submitRequest = async (data) => {
		try {
			const newArray = data.phoneNumbers.split("\n");
			const whatsAppData = {
				phoneNumbers: newArray,
			};

			const resp = await whatsAppInvoice(orgId, data.irn, whatsAppData);
			if (resp !== null && resp.data !== null) {
				// const newResp = resp.data.join("<br/>");
				enqueueSnackbar("Invoice sent to whatsApp successfully.", {
					variant: "success",
				});
			}
			history.push("/");
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	return (
		<Container
			css={`
				margin-top: 28px;
			`}
		>
			<Box pt={3} pb="28px">
				<Form onSubmit={handleSubmit(submitRequest)}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<Form.Group controlId="irn">
								<Label>
									<span>
										Invoice Reference Number (IRN)
										<RequiredStar />
										<Box component={Tooltip} ml={1} title="Search">
											<IconButton
												color="primary"
												variant="contained"
												aria-label="search"
												component="span"
												size="small"
												onClick={openIRNDialog}
											>
												<SearchIcon />
											</IconButton>
										</Box>
									</span>
									{errors.irn && (
										<p className="form-error-inline">{errors.irn.message}</p>
									)}
								</Label>

								<Form.Control
									name="irn"
									type="text"
									value={selectedIRN}
									ref={register({
										required: "Required",
									})}
									onChange={(e) => {
										setSelectedIRN(e.target.value);
									}}
								/>
								<SelectIRNModal
									orgId={orgId}
									irnSelector={irnSelector}
									showIRNSearchModal={showIRNSearchModal}
									handleClose={handleIRNModalClose}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={12}>
							<Form.Group controlId="phoneNumbers">
								<Label>
									<span>
										Phone Number(s) - one Number per line. Ex: 910123456789
										<RequiredStar />
									</span>
									{errors.phoneNumbers && (
										<p className="form-error-inline">
											{errors.phoneNumbers.message}
										</p>
									)}
								</Label>
								<Form.Control
									as="textarea"
									name="phoneNumbers"
									type="text"
									rows={10}
									style={{ height: "100px" }}
									ref={register({
										required: "Required",
									})}
								/>
							</Form.Group>
						</Grid>

						<Grid item container justify="space-between" alignItems="center">
							<Grid item xs={6}>
								<Button
									color="secondary"
									onClick={() => {
										setSelectedIRN();
										reset();
										clearErrors();
									}}
									css={`
										padding: 0;
										font-weight: 600;
									`}
								>
									Clear
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="outlined"
									color="primary"
									type="submit"
									disableElevation
								>
									Send Invoice to WhatsApp
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Form>
			</Box>
		</Container>
	);
};

export default WhatsAppEInvoice;
