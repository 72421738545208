import React from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { defaultErrorToast } from "../../styles/global";
import { useProfile } from "../../hooks/profile";
import { Label, PaperBase, RequiredStar } from "../../styles/styles";
import { createOrgCustomerDetails } from "../../services/customerManagementService";

export const CustomerCreate = () => {
	const { orgId } = useProfile();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {},
	});

	// Submit
	const submitRequest = async (data) => {
		try {
			const formattedData = {
				...data,
			};
			_.mapKeys(formattedData, (value, key) => {
				if (_.isEmpty(value)) delete formattedData[key];
			});
			// console.log("data", formattedData);
			await createOrgCustomerDetails(orgId, formattedData);

			enqueueSnackbar("Customer successfully created", {
				variant: "success",
			});

			history.goBack();
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	return (
		<Container>
			<PaperBase>
				<Form onSubmit={handleSubmit(submitRequest)}>
					<Typography variant="h5" component="h1" gutterBottom>
						Add New Customer
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Form.Group controlId="name">
								<Label>
									<span>
										Name
										<RequiredStar />
									</span>
									{errors.name && (
										<p className="form-error-inline">{errors.name.message}</p>
									)}
								</Label>
								<Form.Control
									name="name"
									type="text"
									ref={register({
										required: "Required",
									})}
								/>
							</Form.Group>
						</Grid>
						<Grid item xs={12} md={6}>
							<Form.Group controlId="gstn">
								<Label>
									<span>
										GSTN
										<RequiredStar />
									</span>
									{errors.gstn && (
										<p className="form-error-inline">{errors.gstn.message}</p>
									)}
								</Label>
								<Form.Control
									name="gstn"
									type="text"
									ref={register({
										required: "Required",
									})}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="addressLine1">
								<Label>
									<span>
										Address Line 1
										<RequiredStar />
									</span>
									{errors.addressLine1 && (
										<p className="form-error-inline">
											{errors.addressLine1.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="addressLine1"
									type="text"
									ref={register({
										required: "Required",
									})}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="addressLine2">
								<Label>
									<span>Address Line 2</span>
									{errors.addressLine2 && (
										<p className="form-error-inline">
											{errors.addressLine2.message}
										</p>
									)}
								</Label>
								<Form.Control name="addressLine2" type="text" ref={register({})} />
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="city">
								<Label>
									<span>
										City
										<RequiredStar />
									</span>
									{errors.city && (
										<p className="form-error-inline">{errors.city.message}</p>
									)}
								</Label>
								<Form.Control
									name="city"
									type="text"
									ref={register({
										required: "Required",
									})}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="state">
								<Label>
									<span>
										State
										<RequiredStar />
									</span>
									{errors.state && (
										<p className="form-error-inline">{errors.state.message}</p>
									)}
								</Label>
								<Form.Control
									name="state"
									type="text"
									ref={register({
										required: "Required",
									})}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="country">
								<Label>
									<span>
										Country
										<RequiredStar />
									</span>
									{errors.country && (
										<p className="form-error-inline">
											{errors.country.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="country"
									type="text"
									ref={register({
										required: "Required",
									})}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="postalCode">
								<Label>
									<span>
										Postal Code
										<RequiredStar />
									</span>
									{errors.postalCode && (
										<p className="form-error-inline">
											{errors.postalCode.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="postalCode"
									type="text"
									className={errors.postalCode && "field-error"}
									ref={register({
										required: "Required",
										pattern: {
											value: /^\d*$/,
											message: "Invalid",
										},
									})}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="phoneNumber">
								<Label>
									<span>
										Phone Number
										<RequiredStar />
									</span>
									{errors.phoneNumber && (
										<p className="form-error-inline">
											{errors.phoneNumber.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="phoneNumber"
									type="text"
									className={errors.phoneNumber && "field-error"}
									ref={register({
										required: "Required",
										pattern: {
											value: /^\d*$/,
											message: "Invalid",
										},
									})}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="email">
								<Label>
									<span>
										Email
										<RequiredStar />
									</span>
									{errors.email && (
										<p className="form-error-inline">{errors.email.message}</p>
									)}
								</Label>
								<Form.Control
									name="email"
									type="text"
									ref={register({
										required: "Required",
									})}
								/>
							</Form.Group>
						</Grid>

						<Grid item container justify="space-between">
							<Button
								variant="outlined"
								color="secondary"
								onClick={history.goBack}
								disableFocusRipple
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								disableFocusRipple
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Form>
			</PaperBase>
		</Container>
	);
};

export default CustomerCreate;
