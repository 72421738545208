import axios from "axios";

axios.interceptors.response.use(null, (error) => {
	const expectedError =
		error.response && error.response.status >= 400 && error.response.status <= 500;

	// TODO: Add Unique Id to toastify at end of development, to prevent error spam for users.
	// TODO: Replace toast error system here
	// TODO: Add Return to Dashboard button / Refresh Button
	if (expectedError) {
		// console.log("Error Response: ", error.response.data);
		// toast.error(error.response.data.errorDescription ?? "Failed to get Error Description");
	} else {
		// console.log("Unexpected Error: ", error);
		// console.error(error);
		// toast.error("An Unexpected Error Occurred");
	}

	return Promise.reject(error);
});

function setJwt(jwt) {
	axios.defaults.headers.common["x-auth-token"] = jwt;
}

// TODO: setJwt not used anywhere
export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	patch: axios.patch,
	delete: axios.delete,
	setJwt,
};
