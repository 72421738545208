import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import {} from "styled-components/macro";
import moment from "moment";
import { PaperBase, Spinner } from "../../styles/styles";
import { useProfile } from "../../hooks/profile";
import { geteeWayBillStatsByDate, geteInvoiceStatsByDate } from "../../services/statsService";

const DailyCount = ({ fromDate, toDate }) => {
	const { orgId } = useProfile();

	const colors = ["#00aeff", "#ED217C", "#FF4A0D"];

	const [loading, setLoading] = useState(false);

	const [invoiceDailyData, setInvoiceDailyData] = useState({ data: [] });
	const [eWayBillDailyData, setEwayBillDailyData] = useState({ data: [] });

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const data = await geteInvoiceStatsByDate(orgId, fromDate, toDate);
				setInvoiceDailyData(data);
				// console.log("Invoice Daily Stat:", { data });
			} catch (e) {
				// console.log(e);
				setInvoiceDailyData({ data: [] });
			}
			setLoading(false);
		})();
	}, [orgId, fromDate, toDate]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const data = await geteeWayBillStatsByDate(orgId, fromDate, toDate);
				setEwayBillDailyData(data);
				// console.log("ewayBill Daily Stat:", { data });
			} catch (e) {
				setEwayBillDailyData({ data: [] });
			}
			setLoading(false);
		})();
	}, [orgId, fromDate, toDate]);

	const options = {
		colors,
		backgroundColor: "#FFF",
		calculable: true,

		title: {
			text: "Daily Count",
			left: "center",
			top: 15,
			textStyle: {
				color: "#222",
				fontFamily: "Open Sans",
				fontWeight: 600,
			},
		},

		tooltip: {
			trigger: "axis",
			axisPointer: {
				type: "line",
				label: {
					formatter: (params) => `${moment(params.value).format("MMM Do, YYYY")}`,
				},
			},
		},

		toolbox: {
			show: true,
			showTitle: false,
			top: 10,
			right: 10,
			feature: {
				mark: { show: true },
				magicType: { show: true, type: ["line", "bar"] },
				restore: { show: true },
				saveAsImage: { show: true },
			},
		},

		legend: {
			data: ["Invoices", "eWayBills"],
			bottom: 6,
		},
		grid: {
			top: 55,
			bottom: 100,
			left: 40,
			right: 35,
		},
		dataZoom: [
			{
				type: "slider",
				show: true,
				xAxisIndex: [0],
				start: 0,
				end: 100,
				backgroundColor: "#00AEEF11",
				filterMode: "none",
				labelFormatter: (value) => `${moment(value).format("MMM Do, YYYY")}`,
				bottom: 45,
				// handleStyle: {
				// 	color: "#3E444D",
				// },
				// moveHandleStyle: {
				// 	color: "#3E444D",
				// },
			},
			{
				type: "inside",
				xAxisIndex: [0],
				start: 0,
				end: 100,
				filterMode: "none",
			},
		],
		xAxis: [
			{
				type: "time",
			},
		],
		yAxis: [
			{
				type: "value",
			},
		],
		series: [
			{
				name: "Invoices",
				type: "line",
				smooth: true,
				emphasis: {
					focus: "series",
				},
				data: (() =>
					invoiceDailyData.data.map((row) => [
						// eslint-disable-next-line no-underscore-dangle
						new Date(moment(row._id)),
						row.count,
					]))(),
				lineStyle: {
					color: colors[0],
				},
			},
			{
				name: "eWayBills",
				type: "line",
				smooth: true,
				emphasis: {
					focus: "series",
				},
				data: (() =>
					eWayBillDailyData.data.map((row) => [
						// eslint-disable-next-line no-underscore-dangle
						new Date(moment(row._id)),
						row.count,
					]))(),
				lineStyle: {
					color: colors[1],
				},
				itemStyle: {
					color: "#A1064C",
				},
			},
		],
	};

	return (
		<PaperBase
			p="0px"
			radius="5px"
			css={`
				height: 100%;
				min-height: 100%;
			`}
		>
			{loading ? (
				<div
					css={`
						display: flex;
						height: 100%;
						place-items: center;
						place-content: center;
						padding: 30px;
					`}
				>
					<Spinner ml="0px" />
				</div>
			) : (
				<ReactECharts
					option={options}
					css={`
						& div canvas {
							border-radius: 5px;
						}
					`}
					style={{ height: "360px" }}
				/>
			)}
		</PaperBase>
	);
};

export default DailyCount;
