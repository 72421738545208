import React, { useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import {} from "styled-components/macro";
import { PaperBase, Spinner } from "../../styles/styles";
import { useProfile } from "../../hooks/profile";
import { geteInvoiceStatsByStatus } from "../../services/statsService";

const EInvoiceStatusCount = ({ fromDate, toDate, setTotalCount }) => {
	const { orgId } = useProfile();

	const [loading, setLoading] = useState(false);

	const [einVoiceData, setEInvoiceData] = useState({ attributes: [], data: [], total: "" });

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const data = await geteInvoiceStatsByStatus(orgId, fromDate, toDate);

				// eslint-disable-next-line no-underscore-dangle
				const formattedData = data.data.map((row) => ({ name: row._id, value: row.count }));

				const total = formattedData.reduce((prev, current) => prev + current.value || 0, 0);
				setTotalCount((r) => ({ ...r, eInvoices: total }));
				//	const data = [["Active", "30"], ["Cancelled", "12"]];
				//	const formattedData = data.map((row) => ({ name: row[0], value: row[1] }));
				setEInvoiceData({
					attributes: ["_id", "count"],
					data: formattedData,
					total,
				});
			} catch (e) {
				// console.log(e);
				setEInvoiceData({
					attributes: [],
					data: [],
					total: "",
				});
			}

			setLoading(false);
		})();
	}, [orgId, fromDate, toDate, setTotalCount]);

	const colorPalette = useMemo(
		() => ["#11A1FD", "#FF4A0D", "#5A75F8", "#536382", "#ffb300", "#ED217C"],
		[]
	);

	const options = useMemo(
		() => ({
			backgroundColor: "#FFF",

			title: {
				text: "eInvoices",
				left: "center",
				top: 15,
				textStyle: {
					color: "#222",
					fontFamily: "Open Sans",
					fontWeight: 600,
				},
			},

			tooltip: {
				trigger: "item",
			},

			toolbox: {
				show: true,
				showTitle: false,
				right: 10,
				top: 10,
				feature: {
					mark: { show: true },
					restore: { show: true },
					saveAsImage: { show: true },
				},
			},

			legend: {
				padding: [10, 5, 10, 5],
				icon: "circle",
				x: "center",
				bottom: 0,
				data: einVoiceData.data,
				textStyle: {
					color: "#222",
					padding: [5, -3],
				},
				itemGap: 5,
			},
			series: [
				{
					type: "pie",
					color: colorPalette,
					// name: "Purchase Orders",

					radius: ["40%", "60%"],
					center: ["50%", "49.5%"],

					animationDuration: 2000,
					animationEasing: "quarticInOut",
					animationDelay: () => Math.random() * 200,

					data: einVoiceData.data,
					label: {
						color: "#222",
						fontFamily: "Open Sans",
						fontWeight: 600,
						formatter: "{c}",
						fontSize: "0.95rem",
					},

					labelLine: {
						lineStyle: {
							color: "#222",
						},
						smooth: 0.3,
						length: 15,
						length2: 20,
					},

					itemStyle: {
						borderRadius: 5,
						borderColor: "#fff",
						borderWidth: 2,
					},
					markPoint: {
						name: "Total",
						tooltip: { show: false },
						label: {
							show: true,
							formatter: "{b}",
							color: "black",
							fontSize: 20,
						},
						data: [
							{
								name: einVoiceData.total,
								value: "-",
								symbol: "circle",
								itemStyle: { color: "transparent" },
								x: "50%",
								y: "51%",
							},
						],
					},
				},
			],
		}),
		[colorPalette, einVoiceData.data, einVoiceData.total]
	);
	return (
		<PaperBase p="0px" radius="5px">
			{loading ? (
				<div
					css={`
						display: flex;
						height: 100%;
						place-items: center;
						place-content: center;
						padding: 30px;
					`}
				>
					<Spinner ml="0px" />
				</div>
			) : (
				<ReactECharts
					option={options}
					css={`
						& div canvas {
							border-radius: 5px;
						}
					`}
					style={{ height: "360px" }}
					showLoading={loading}
				/>
			)}
		</PaperBase>
	);
};

export default EInvoiceStatusCount;
