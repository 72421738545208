import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
	Grid,
	Box,
	LinearProgress,
	TableContainer,
	Table,
	TableHead,
	TableSortLabel,
	TableBody,
	TableRow,
	TableCell,
	Collapse,
	TextField,
	InputLabel,
	Paper,
	FormControl,
	Select,
	MenuItem,
	useMediaQuery,
} from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { useTable, usePagination, useSortBy, useExpanded } from "react-table";
import styled from "styled-components/macro";
import _ from "lodash";

// TODO: Try using useblockLayout for indexed tables without headers

/**
 * Server-side Table
 */
export default React.memo(
	({
		columns,
		data,
		fetchData,
		loading,
		totalRecords,
		pageCount: controlledPageCount,
		renderExpanded,
		hideHeader,
		hideFooter,
		hideResults,
		hiddenColumns = [],
		defaultRowsPerPage = 10,
		currentPage = 0,
		defaultSortBy = [],
		searchQuery,
		existingSearchQuery, // When Table is initially rendered with an existing search query
	}) => {
		const themer = useTheme();

		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			prepareRow,

			page,
			canPreviousPage,
			canNextPage,
			pageCount,
			gotoPage,
			nextPage,
			previousPage,
			setPageSize,
			visibleColumns,

			state: { pageIndex, pageSize, sortBy },
		} = useTable(
			{
				columns,
				data,
				initialState: {
					hiddenColumns,
					pageIndex: currentPage,
					pageSize: defaultRowsPerPage,
					sortBy: defaultSortBy, // e.g. [{ id: "uploadDate", desc: true }]
				},
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
			},
			useSortBy,
			useExpanded,
			usePagination
		);

		const history = useHistory();
		const [isHistoryPopped, setIsHistoryPopped] = useState(false);

		// Consider the following line only if the base64 may exceed the API character length limit
		const finalSearchQuery = searchQuery?.map((sq) => _.omit(sq, ["id", "label"]));
		const finalExistingSearchQuery = existingSearchQuery?.map((sq) =>
			_.omit(sq, ["id", "label"])
		);

		useEffect(() => {
			gotoPage(currentPage);
		}, [currentPage, gotoPage]);

		// Triggers when a new Search Parameter is added.
		useEffect(() => {
			// Doesn't trigger if page is reached on a back event
			if (
				(history.location.state?.action === "POP" || history.action === "POP") &&
				data.length &&
				!isHistoryPopped
			) {
				setIsHistoryPopped(true);
				return;
			}

			if (!Array.isArray(existingSearchQuery)) {
				fetchData({
					pageIndex: 0,
					pageSize,
					sortBy,
					searchQuery: btoa(JSON.stringify(finalSearchQuery)),
				});

				// Resets pageIndex on UI for every new searchQuery
				if (Array.isArray(data)) gotoPage(0);
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [searchQuery, gotoPage]);

		// Triggers when initialised with a Search Parameter.
		useEffect(() => {
			if (Array.isArray(existingSearchQuery)) {
				fetchData({
					pageIndex: 0,
					pageSize,
					sortBy,
					searchQuery: btoa(JSON.stringify(finalExistingSearchQuery)),
				});

				// Resets pageIndex on UI for every new searchQuery
				if (Array.isArray(data)) gotoPage(0);
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [existingSearchQuery, gotoPage]);

		useEffect(() => {
			if (
				(history.location.state?.action === "POP" || history.action === "POP") &&
				!isHistoryPopped
			) {
				setIsHistoryPopped(true);
				return;
			}

			if (!Array.isArray(sortBy)) return;

			if (Array.isArray(data) && data.length)
				fetchData({
					pageIndex: 0,
					pageSize,
					sortBy,
					searchQuery: btoa(
						JSON.stringify(
							Array.isArray(existingSearchQuery) && existingSearchQuery.length
								? finalExistingSearchQuery
								: finalSearchQuery
						)
					),
				});

			// Resets pageIndex on UI for every new searchQuery
			if (Array.isArray(data)) gotoPage(0);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [sortBy]);

		useEffect(() => {
			if (Array.isArray(data) && data.length)
				fetchData({
					pageIndex,
					pageSize,
					sortBy,
					searchQuery: btoa(
						JSON.stringify(
							Array.isArray(existingSearchQuery) && existingSearchQuery.length
								? finalExistingSearchQuery
								: finalSearchQuery
						)
					),
				});

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [pageSize]);

		useEffect(() => {
			if (data.length) setIsHistoryPopped(true);
		}, [data.length]);

		// Pagination Click Listener
		const pageIndexDataRenderer = (pageIndex) => {
			fetchData({
				pageIndex,
				pageSize,
				sortBy,
				searchQuery: btoa(
					JSON.stringify(
						Array.isArray(existingSearchQuery) && existingSearchQuery.length
							? finalExistingSearchQuery
							: finalSearchQuery
					)
				),
			});
		};

		// Table Responsiveness Checkers:
		const isBelowMd = useMediaQuery((theme) => theme.breakpoints.down("sm"));

		return (
			<>
				<TablePaper component={Paper} variant="outlined">
					<TableList {...getTableProps()}>
						<TableHead style={{ display: hideHeader && "none" }}>
							{headerGroups.map((headerGroup) => (
								<TableHeadRow
									{...headerGroup.getHeaderGroupProps()}
									hasexpander={renderExpanded && 1}
								>
									{headerGroup.headers.map((column) => (
										<TableCell
											{...column.getHeaderProps()}
											style={{
												textAlign: column.flipPosition && "right",
											}}
										>
											<TableSorter
												tabIndex={
													(column.disableSorter || column.isExpander) &&
													"-1"
												}
												active={column.isSorted}
												direction={column.isSortedDesc ? "desc" : "asc"}
												hideSortIcon={
													column.isExpander || column.disableSorter
												}
												css={`
													cursor: ${column.disableSorter && "unset"};
												`}
												flipposition={column.flipPosition && 1}
												{...column.getSortByToggleProps({
													title:
														typeof column.Header === "string" &&
														!column.disableSorter
															? `Sort ${column.Header}`
															: undefined,
												})}
											>
												{column.render("Header")}
											</TableSorter>
										</TableCell>
									))}
								</TableHeadRow>
							))}
						</TableHead>
						<TableBody {...getTableBodyProps()}>
							{page.map((row) => {
								prepareRow(row);
								return (
									<>
										<TableBodyRow
											{...row.getRowProps()}
											// Row Selection
											onClick={() => {
												row.toggleRowExpanded();
											}}
											onKeyDown={(e) => {
												if (e.key === "Enter") row.toggleRowExpanded();
											}}
											// Row CSS
											css={`
												cursor: ${renderExpanded && "pointer"};
												&:focus {
													background-color: ${renderExpanded &&
													"hsl(256, 0%, 96%) !important"};
												}
												&:hover {
													background-color: hsl(256, 0%, 96%) !important;
												}
											`}
											hasexpander={renderExpanded && 1}
											tabindex={renderExpanded && "0"}
										>
											{row.cells.map((cell) => (
												<TableCell
													{...cell.getCellProps()}
													style={{
														textAlign:
															cell.column.flipPosition && "right",
													}}
												>
													{cell.render("Cell")}
												</TableCell>
											))}
										</TableBodyRow>

										{renderExpanded && (
											<TableRow>
												<TableCell
													style={{ paddingBottom: 0, paddingTop: 0 }}
													colSpan={visibleColumns.length}
												>
													<Collapse
														in={row.isExpanded}
														timeout="auto"
														unmountOnExit
													>
														<Box margin={2}>
															{row.isExpanded &&
																renderExpanded({
																	data: row.original,
																})}
														</Box>
													</Collapse>
												</TableCell>
											</TableRow>
										)}
									</>
								);
							})}
							<TableRow>
								{loading ? (
									<TableCell colSpan="12" size="small" style={{ padding: 0 }}>
										<LinearProgress />
									</TableCell>
								) : (
									<TableCell
										colSpan="12"
										size="small"
										style={{
											padding: "6px 20px",
											borderBottom: hideFooter && "unset",
											display: hideResults && "none",
										}}
									>
										Showing {(pageIndex + 1) * pageSize - (pageSize - 1)}-
										{Math.min(
											(pageIndex + 1) * pageSize -
												(pageSize - 1) +
												pageSize -
												1,
											totalRecords
										)}{" "}
										of {totalRecords} result(s)
									</TableCell>
								)}
							</TableRow>
						</TableBody>
					</TableList>

					{/* PAGINATION ROW */}

					{/* TODO: ADD react-hook-forms to make NUMBER VALIDATION PRECISE ? */}
					<TableFooter
						container
						justify="space-between"
						alignItems="center"
						style={{ display: hideFooter && "none" }}
					>
						<Grid item xs={12} md={1}>
							<Box
								component={FormControl}
								width={1}
								style={{
									minWidth: 120,
									margin: "5px 0 0 0",
								}}
								variant="outlined"
							>
								<InputLabel id="selectPageSizeLabel">Rows per page</InputLabel>
								<PageSizeSelect
									labelId="selectPageSizeLabel"
									id="selectPageSize"
									label="Rows per page"
									value={pageSize}
									onChange={(event) => {
										setPageSize(parseInt(event.target.value, 10));
										gotoPage(0);
									}}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={25}>25</MenuItem>
								</PageSizeSelect>
								{/* TODO: Menu Button here for additional options: e.g. Densify */}
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={11}
							style={{
								marginTop: isBelowMd && "1rem",
								marginBottom: isBelowMd && "0.15rem",
							}}
						>
							<Box
								display="flex"
								justifyContent={isBelowMd ? "space-between" : "flex-end"}
							>
								<Box display="flex" justifyContent="center">
									<Arrows
										onClick={() => {
											gotoPage(0);
											pageIndexDataRenderer(0);
										}}
										disabled={!canPreviousPage}
										aria-label="first page"
										id="firstPageArrow"
										title="First Page"
										className={!canPreviousPage && "disabled"}
									>
										{themer.direction === "rtl" ? (
											<LastPageIcon />
										) : (
											<FirstPageIcon />
										)}
									</Arrows>
									<Arrows
										onClick={() => {
											previousPage();
											pageIndexDataRenderer(pageIndex - 1);
										}}
										disabled={!canPreviousPage}
										aria-label="previous page"
										id="previousPageArrow"
										title="Previous Page"
										className={!canPreviousPage && "disabled"}
									>
										{themer.direction === "rtl" ? (
											<KeyboardArrowRight />
										) : (
											<KeyboardArrowLeft />
										)}
									</Arrows>
								</Box>
								<Box
									component={FormControl}
									mx="auto"
									style={{
										maxWidth: !isBelowMd && 140,
										margin: "5px 10px 0px",
									}}
								>
									<TextField
										id="goToPage"
										label={`Page ${pageIndex + 1} of ${pageCount}`}
										type="number"
										value={Number(pageIndex) + 1}
										onChange={(e) => {
											if (
												e.target.value === "" ||
												(Number(e.target.value) >= 1 &&
													Number(e.target.value) <= pageCount)
											)
												gotoPage(e.target.value - 1);
										}}
										onBlur={() => {
											gotoPage(pageIndex);
										}}
										min="1"
										max={pageCount - 1}
										variant="outlined"
										size="small"
									/>
								</Box>
								<Box display="flex" justifyContent="center">
									<Arrows
										onClick={() => {
											nextPage();
											pageIndexDataRenderer(pageIndex + 1);
										}}
										disabled={!canNextPage}
										aria-label="next page"
										id="nextPageArrow"
										title="Next Page"
										className={!canNextPage && "disabled"}
									>
										{themer.direction === "rtl" ? (
											<KeyboardArrowLeft />
										) : (
											<KeyboardArrowRight />
										)}
									</Arrows>
									<Arrows
										onClick={() => {
											gotoPage(pageCount - 1);
											pageIndexDataRenderer(pageCount - 1);
										}}
										disabled={!canNextPage}
										aria-label="last page"
										id="lastPageArrow"
										title="Last Page"
										className={!canNextPage && "disabled"}
									>
										{themer.direction === "rtl" ? (
											<FirstPageIcon />
										) : (
											<LastPageIcon />
										)}
									</Arrows>
								</Box>
							</Box>
						</Grid>
					</TableFooter>
				</TablePaper>
			</>
		);
	}
);

const TablePaper = styled(TableContainer)`
	border-radius: 0.25rem;
	border-color: ${(props) => props.theme.common.borderColor};
`;

const TableList = styled(Table)`
	& * {
		font-family: "Open Sans";
	}
`;

const TableHeadRow = styled(TableRow)`
	background-color: ${(props) => props.theme.light.secondary};
	th {
		padding: 12px 10px;
		&:first-child {
			padding-left: ${(props) => (props.hasexpander ? "15px" : "20px")};
		}
		&:last-child {
			padding-right: 20px;
		}
		span {
			color: ${(props) => props.theme.light.white}!important;
			font-size: 1rem;

			&:focus {
				outline: 2px solid rgba(255, 255, 255, 0.85);
			}
		}
	}
`;

const TableBodyRow = styled(TableRow).attrs()`
	td {
		padding: 12px 10px;
		border-bottom: ${(props) => props.hasexpander && "unset"};

		/* border-color: ${(props) => props.theme.common.borderColor}; */

		&:first-child {
			padding-left: ${(props) => (props.hasexpander ? "15px" : "20px")};
		}
		&:last-child {
			padding-right: 20px;
		}
		font-size: 0.96rem;
	}
	background-color: #fefefe !important;
`;

const TableSorter = styled(TableSortLabel)`
	flex-direction: ${(props) => props.flipposition && "row-reverse !important"};

	&:hover,
	&:focus {
		color: unset;
	}

	svg {
		color: ${(props) => props.theme.light.white};
	}

	&:hover svg,
	&:focus svg {
		opacity: 0.5 !important;
	}

	&.MuiTableSortLabel-active svg {
		color: ${(props) => props.theme.light.primary} !important;
		opacity: 1 !important;
	}
`;

const TableFooter = styled(Grid)`
	padding: 12px 20px;

	& * {
		color: ${(props) => props.theme.light.font.black};
	}

	& div div:hover fieldset {
		border-color: ${(props) => props.theme.light.primary} !important;
	}

	& fieldset {
		border-color: #2225;
		border-width: 1px;
	}

	& input {
		padding: 11.5px;
	}
`;

const PageSizeSelect = styled(Select)`
	padding-top: 2px;
	padding-left: 2px;
	margin-bottom: 1px;
	& > div {
		padding: 10.5px;
	}
`;

const Arrows = styled(IconButton).attrs({ color: "primary" })`
	&.disabled span svg {
		opacity: 0.3;
	}
`;
