import React, { useState } from "react";

import { Button, Grid } from "@material-ui/core";
import styled from "styled-components/macro";
import { useSnackbar } from "notistack";
import { PaperBase, Spinner } from "../../styles/styles";
import { useUser } from "../../hooks/user";
import { acceptTermsAndConditions } from "../../services/userService";
import { useProfile } from "../../hooks/profile";
import { defaultErrorToast } from "../../styles/global";

const TermsAndConditions = () => {
	const { logout, refreshToken } = useUser();

	const { userId } = useProfile();

	const { enqueueSnackbar } = useSnackbar();
	const [isLoadingAccept, setUpdateButton] = useState(false);
	const [isLoadingDecline, setUpdateButtonDecline] = useState(false);

	const [isAtScrollBottom, setScrollBottom] = useState(false);

	const handleScroll = (e) => {
		console.log(e);
		if (!isAtScrollBottom) {
			if (
				e.target.scrollTop > 0 &&
				Math.ceil(e.target.scrollTop + e.target.clientHeight) >= e.target.scrollHeight
			)
				setScrollBottom(true);
		}
	};

	const termsContent =
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc mattis, dolor id vehicula sollicitudin, lacus erat tristique justo, non condimentum mauris turpis at lacus. In sodales ultricies sodales. Sed a maximus risus, 	condimentum porta enim. Aenean fringilla pharetra commodo. Sed non tortor 	sem. Nullam augue purus, congue eget justo in, volutpat ultricies augue. Etiam non purus iaculis, vehicula risus efficitur, sagittis sem. Proin 	mollis ligula justo, accumsan posuere urna fringilla et. Maecenas ornare 	bibendum risus elementum bibendum. Quisque sodales felis eget velit bibendum 	viverra. Nulla semper feugiat eros non sollicitudin. Suspendisse potenti.";

	const apiDateContent = "Last Updated: 21st Jul 2021, Effective From: 21st Jul 2021";
	return (
		<PaperBase
			css={`
				max-width: min-content;

				& h2 {
					font-size: 1.5rem;
				}
			`}
		>
			<h3>Terms & Conditions</h3>
			<ScrollContainer
				id="termsDiv"
				onScroll={handleScroll}
				css={`
					font-size: clamp(14px, calc(0.875rem + ((1vw - 5.6px) * 0.8333)), 16px);
				`}
			>
				<p>
					Last Updated: 21<sup>st</sup> Jul 2021
				</p>
				<p>
					Effective From: 21<sup>st</sup> Jul 2021
				</p>
				<br />
				<h2>Introduction</h2>
				<ul>
					<li>
						<ol>
							<li>
								This Terms and Conditions Agreement (“<strong>Terms</strong>” and “
								<strong>Agreement</strong>”) governs the use of the website at{" "}
								<a href="https://einvoice.skyscend.com">
									https://einvoice.skyscend.com
								</a>{" "}
								(“<strong>Website</strong>”) and any mobile app or software (“
								<strong>Software</strong>”) that indicates the applicability of
								these Terms.
							</li>
							<li>
								The Website and Software are provided by Skycend Pay Private Limited
								(“<strong>Skyscend</strong>”, “<strong>Company</strong>
								”, “<strong>We</strong>”, “<strong>Us</strong>” and “
								<strong>Our</strong>”) for the purpose of e-invoicing, e-way billing
								and GSTR-1 filing. Skyscend Pay Private Limited is a company
								incorporated in India under the Companies Act, 2013.
							</li>
							<li>
								In these Terms, “<strong>You</strong>” and “<strong>Your</strong>”
								refers to the person visiting this Website and/or contributing
								content on this Website. If the individual accepting this Agreement
								is accepting on behalf of a company or other entity, such individual
								represents that they have the authority to bind such entity and its
								affiliates to these terms and conditions, in which case the term
								“You” shall refer to such entity and its affiliates. If the
								individual accepting this Agreement does not have such authority, or
								does not agree with these terms of service, such individual must not
								accept this Agreement and must not use the Website.
							</li>
							<li>
								By undertaking any of the following actions, You demonstrate that
								You have read and agree to this Agreement:
								<ol>
									<li>
										By using or otherwise interacting with the Website or
										Software;
									</li>
									<li>
										By using any content available on the Website or Software;
									</li>
									<li>By submitting any content to the Website or Software;</li>
									<li>By clicking a box indicating acceptance of these Terms;</li>
									<li>By creating an account on the Website or Software;</li>
									<li>
										By paying for a product or service provided on the Website
										or Software; and/or
									</li>
									<li>
										By signing this Agreement, whether digitally or physically.
									</li>
								</ol>
							</li>
							<li>
								You further indicate that You have read, understood, and agree to be
								bound by Our Privacy Policy, the latest version of each of which can
								be accessed at:{" "}
								<a href="https://skyscendpay.com/privacy-policy">
									https://skyscendpay.com/privacy-policy
								</a>{" "}
							</li>
							<li>
								Company’s direct competitors are prohibited from accessing the
								Website and/or Software, except with Company’s prior written
								consent. In addition, the Website and/or Software may not be
								accessed for purposes of monitoring their availability, performance,
								or functionality, or for any other benchmarking or competitive
								purposes.
							</li>
							<li>
								These Terms and the Privacy Policy mentioned above may be modified
								from time to time. Please review these documents regularly to ensure
								that You are aware of the applicable terms and conditions, and our
								practices, at all times. We will notify You about material changes
								to these Terms and the Privacy Policy by sending a notice to the
								email address provided by You in Your account, and/or by placing a
								notice of modifications on the Website. Modifications will apply at
								least fourteen (14) days after the date on which they are posted,
								however modifications made to provide new features or services, and
								modifications made to comply with legal obligations, will be
								effective immediately. If You do not agree to the modified Terms or
								the Privacy Policy mentioned above, You must discontinue Your use of
								the Website and Services.
							</li>
						</ol>
					</li>
				</ul>
				<h2>Definitions</h2>
				<ul>
					<li>
						<ol>
							<li>
								{" "}
								“<strong>Agreement</strong>” means this Terms and Conditions
								Agreement and includes the Privacy Policy, and any other additional
								terms and conditions as may be agreed upon by the parties prior to
								the provision of additional services by Skyscend.
							</li>
							<li>
								“<strong>Affiliate</strong>” means any entity that directly or
								indirectly controls, is controlled by, or is under common control
								with the subject entity. “Control” for the purposes of this
								definition, means direct or indirect ownership or control of more
								than 50% of the voting interests of the subject entity.
							</li>
							<li>
								“<strong>Company</strong>” has the meaning assigned to it above.
							</li>
							<li>
								“<strong>Content</strong>” means data, information, graphics, text,
								audio, or video.
							</li>
							<li>
								“<strong>Customer</strong>” means You.
							</li>
							<li>
								“<strong>Customer Content</strong>” means Your Content.
							</li>
							<li>
								“<strong>Free Services</strong>” means Services that Skyscend makes
								available to Customer free of charge. Free Services exclude
								Purchased Services.{" "}
							</li>
							<li>
								“<strong>Malicious Code</strong>” means code, files, scripts,
								agents, or programs intended to do harm, including, for example,
								viruses, worms, time bombs, and trojan horses.{" "}
							</li>
							<li>
								“<strong>Skyscend</strong>” has the meaning assigned to it above.
							</li>
							<li>
								“<strong>Order Form</strong>” means an ordering document or online
								order, specifying the Services to be provided hereunder for a fee,
								that is entered into between Skyscend and Customer or any of the
								Customer’s Affiliates, including any addenda and supplements
								thereto. By entering into an Order Form hereunder, an Affiliate
								agrees to be bound by the terms of this Agreement as if it were an
								original party hereto.{" "}
							</li>
							<li>
								“<strong>Personally Identifiable Information</strong>” means
								personal information that can be used alone or in combination with
								other information to identify a living natural person.
							</li>
							<li>
								“<strong>Privacy Policy</strong>” means the Privacy Policy mentioned
								under Section 1.5 above.
							</li>
							<li>
								“<strong>Purchased Services</strong>” means Services that Customer
								or Customer’s Affiliate purchases under an Order Form or online
								purchasing portal, as distinguished from Free Services.
							</li>
							<li>
								“<strong>Services</strong>” means and includes the Website,
								Software, and services provided by Skyscend on the Website and/or
								Software, and in particular includes e-invoicing, e-way billing, and
								GSTR-1 filing services in India.
							</li>
							<li>
								“<strong>Terms</strong>” means this Agreement.
							</li>
							<li>
								“<strong>User</strong>” means: (i) in case the Customer is an
								individual then such Customer, and (ii) in case the Customer is not
								an individual then an individual that uses the Service on behalf of
								the Customer.
							</li>
							<li>
								“<strong>We</strong>”, “<strong>Us</strong>” and “
								<strong>Our</strong>” have the meaning assigned to them above.
							</li>
							<li>
								“<strong>Website</strong>” has the meaning assigned to it above.
							</li>
							<li>
								“<strong>Your Content</strong>” means User Content that is provided
								by You.
							</li>
							<li>
								“<strong>You</strong>” and “<strong>Your</strong>” have the meaning
								assigned to them above.
							</li>
							<li>
								“<strong>Your</strong> <strong>Content</strong>” means Content that
								is uploaded or otherwise provided by You.
							</li>
						</ol>
					</li>
				</ul>
				<h2>Company’s Responsibilities</h2>
				<ul>
					<li>
						<ol>
							<li>
								<strong>Provision of Purchased Services.</strong> Company will:
								<ol>
									<li>
										Make the Purchased Services available to Customer pursuant
										to the applicable Order Form and this Agreement;
									</li>
									<li>
										Provide the Services in accordance with this Agreement,
										Privacy Policy, the applicable Order Form, and applicable
										laws and government regulations, provided however that the
										Customer’s use of the Service must be compliant with this
										Agreement, Privacy Policy, the applicable Order Form, and
										applicable laws and government regulations.
									</li>
								</ol>
							</li>
							<li>
								<strong>Protection of Personally Identifiable Information.</strong>{" "}
								Company will maintain appropriate administrative, physical, and
								technical safeguards for the protection of security,
								confidentiality, and integrity of Your Personally Identifiable
								Information, as described in detail in Our Privacy Policy. Those
								safeguards include, but are not limited to, measures designed to
								prevent unauthorized access to or disclosure of Personally
								Identifiable Information (other than by Customer or Users) including
								but not limited to compliance with industry standards and periodic
								security audits.
							</li>
							<li>
								<strong>Service Reliability and Uptime.</strong> We use commercially
								reasonable means to ensure that the Service remains accessible and
								functional at all times apart from planned maintenance and downtime
								caused by hardware failure or software errors, however, Customer
								understands and agrees that the Service may not be available at all
								times.{" "}
							</li>
							<li>
								<strong>Bug Fixing.</strong> We use commercially reasonable means to
								fix, bugs or errors in the Service within a reasonable time if such
								bugs or errors prevent the Service from providing the expected
								output. Whether or not the output matches the expected output shall
								be determined by the Company in its sole discretion.
							</li>
							<li>
								<strong>Free Services.</strong> Company may make Free Services
								available to Customer. Use of Free Services is subject to the terms
								and conditions of this Agreement. In the event of a conflict between
								this section and any other portion of this Agreement, this section
								shall control. Free Services are provided to Customer without charge
								up to certain limits as may be decided by Company in its sole
								discretion. Customer agrees that Company, in its sole discretion and
								without assigning any reason, may terminate Customer’s access to the
								Free Services or any part thereof. Customer agrees that any
								termination of Customer’s access to the Free Services may be without
								prior notice, and Customer agrees that Company will not be liable to
								Customer or any third party for such termination.
							</li>
						</ol>
					</li>
				</ul>
				<h2>Use of Services and Content</h2>
				<ul>
					<li>
						<ol>
							<li>
								<strong>Customer Responsibilities.</strong> Customer will:
								<ol>
									<li>
										Be responsible for Customer’s and User’s compliance with
										this Agreement, Privacy Policy, and Order Form;
									</li>
									<li>
										Be responsible for Customer’s conduct and the conduct of
										Users;
									</li>
									<li>
										Be solely responsible for all data, information, text,
										audio, video, graphics, and all other content provided or
										uploaded by Customer or Users, including but not limited to
										its legality, accuracy, quality, completeness, and
										truthfulness;
									</li>
									<li>
										Be solely responsible for ensuring that the Customer’s
										account is not used by or on behalf of any third-party
										without explicit written permission from Company;
									</li>
									<li>
										Be solely responsible for any activity occurring in
										Customer’s account except any conduct that is directly and
										solely attributable to Company without Customer’s
										authorization;
									</li>
									<li>
										Use Services and Content only in accordance with this
										Agreement, Privacy Policy, Order Form, and applicable laws
										and government regulations;
									</li>
									<li>
										Comply with all policies, guidelines, usage limits, and
										other restrictions, applicable to the use of the Website,
										Software and Service;
									</li>
									<li>
										Be solely responsible for compliance with all laws, rules,
										regulations, etc. applicable to Customer’s use of the
										Website, Software and Service;
									</li>
									<li>
										Provide true, accurate, and current information, at all
										times, and promptly and regularly update Your information to
										maintain its accuracy and completeness. Company reserves the
										right to suspend or terminate Your account and refuse any
										and all current or future use of the Service, in whole or in
										part, by You for providing any Customer information or any
										other information, content or data, that is untrue,
										inaccurate, incomplete, or not current; and
									</li>
									<li>
										Provide for Customer’s own access to the Internet and pay
										any fees or costs related to such access.
									</li>
								</ol>
							</li>
							<li>
								<strong>Usage Restrictions.</strong> Customer shall not:
								<ol>
									<li>
										Provide incomplete or inaccurate registration information;
									</li>
									<li>
										Make any Service or Content available to any third party, or
										use any Service or Content for the benefit of anyone other
										than Customer or its Affiliates, unless expressly stated
										otherwise in an Order Form;
									</li>
									<li>
										Sell, resell, license, sublicense, distribute, make
										available, rent, or lease, any Service or Content, or
										include any Service or Content in a service bureau or
										outsourcing offering;
									</li>
									<li>
										Share an account with any third party, share login
										credentials of an account with any third party, or use any
										third party’s account to access the Service;
									</li>
									<li>
										Use a single account on behalf of multiple third parties;{" "}
									</li>
									<li>Use a Service to store or transmit Malicious Code;</li>
									<li>
										Interfere with or disrupt the integrity or performance of
										any Service or Content contained therein;
									</li>
									<li>
										Misuse Services by interfering with their normal operation
										or attempting to access them using a method other than
										through the interfaces and instructions provided by the
										Company;
									</li>
									<li>
										Attempt to gain unauthorized access to any Service or
										Content or its related systems or networks;
									</li>
									<li>
										Attempt, permit, or undertake direct or indirect access to
										or use of any Services or Content in a way that circumvents
										a contractual usage limit, or use any Services to access or
										use any of Company’s intellectual property or any third
										party’s intellectual property except as permitted under this
										Agreement, Privacy Policy, or an Order Form;
									</li>
									<li>
										Modify, copy, or create derivative works based on, a
										Service, or any part, feature, function, or user interface
										thereof;
									</li>
									<li>
										Copy, create derivative works based on, or otherwise use,
										any Content except as explicitly permitted herein or in an
										Order Form;
									</li>
									<li>Frame or mirror any part of any Service or Content;</li>
									<li>
										Except to the extent permitted by applicable law,
										disassemble, reverse engineer, or decompile a Service or
										Content or access it to: (i) build a competitive product or
										service, (ii) build a product or service using similar
										ideas, features, functions or graphics of the Service, (iii)
										copy any ideas, features, functions or graphics of the
										Service, or (iv) determine whether the Services are within
										the scope of any patent;
									</li>
									<li>
										Probe, scan, or test the vulnerability of any Company system
										or network without explicit prior written authorization by
										the Company;
									</li>
									<li>
										Engage in abusive or excessive usage of the Services, which
										is usage significantly in excess of average usage patterns
										that adversely affects the speed, responsiveness, stability,
										availability, or functionality of the Services for other
										Users; and/or
									</li>
									<li>
										Harvest or collect any information about any third party,
										including but not limited to, usernames or other identifiers
										associated with accounts, account descriptions, photos or
										videos associated with accounts or products or services,
										reviews or testimonials posted on the Website or Services,
										statistical or other data, and/or any other data or
										information available on the Website or Service.
									</li>
								</ol>
							</li>
							<li>
								<strong>Legally Prohibited Content.</strong> Customer shall not
								host, display, upload, modify, publish, transmit, store, update or
								share any information in violation of the laws of India, and in
								particular any information that:
								<ol>
									<li>
										Belongs to another person and to which You do not have any
										right;
									</li>
									<li>
										Is defamatory, obscene, pornographic, paedophilic, invasive
										of another’s privacy, including bodily privacy, insulting or
										harassing on the basis of gender, libellous, racially or
										ethnically objectionable, relating or encouraging money
										laundering or gambling, or otherwise inconsistent with or
										contrary to the laws in force;
									</li>
									<li>Is harmful to child;</li>
									<li>
										Infringes any patent, trademark, copyright or other
										proprietary rights;
									</li>
									<li>Violates any law for the time being in force;</li>
									<li>
										Deceives or misleads the addressee about the origin of the
										message or knowingly and intentionally communicates any
										information which is patently false or misleading in nature
										but may reasonably be perceived as a fact;
									</li>
									<li>Impersonates another person;</li>
									<li>
										Threatens the unity, integrity, defence, security or
										sovereignty of India, friendly relations with foreign
										States, or public order, or causes incitement to the
										commission of any cognizable offence or prevents
										investigation of any offence or is insulting other nation;
										contains software virus or any other computer code, file or
										program designed to interrupt, destroy or limit the
										functionality of any computer resource;
									</li>
									<li>
										Is patently false and untrue, and is written or published in
										any form, with the intent to mislead or harass a person,
										entity or agency for financial gain or to cause any injury
										to any person.{" "}
									</li>
								</ol>
							</li>
							<li>
								<strong>Removal of Content.</strong> If Customer receives notice
								that Customer Content must be removed, modified, and/or disabled to
								avoid violating applicable law or third-party rights, Customer will
								promptly do so. If Customer does not take required action, or if in
								Company’s judgment continued violation is likely to reoccur, Company
								may disable or remove the applicable Content or Service. Company
								may, if required to do so by an applicable law, remove or delete any
								Content in its sole discretion at any time without prior notice.
							</li>
							<li>
								<strong>Service Retirement.</strong> Company may add, alter, or
								remove functionality from a Service at any time without prior
								notice. Company may limit, suspend, or discontinue a Service at its
								sole discretion. If Company discontinues a Service, Company will
								provide a reasonable advance notice to Customers that have an active
								paid subscription or otherwise paid for an Order Form that will be
								active at the expected time of the discontinuation of such Service.
							</li>
							<li>
								<strong>New Services.</strong> Company may introduce new products
								and services to complementing existing Services. If such new
								services are not included in Your existing subscription, Company
								reserves the right and sole discretion to decide whether or not the
								new services will be made available to You, whether or not such new
								services will be made available to You as a part of Your existing
								Subscription, and whether or not the availability of such new
								services shall be subject to payment of an additional charge by You
								at Your sole discretion.
							</li>
							<li>
								<strong>Subscriptions.</strong> Unless otherwise provided in the
								applicable Order Form or equivalent communication, (a) Purchased
								Services are purchased as subscriptions for the term stated in the
								applicable Order Form or in the applicable online purchasing portal,
								(b) subscriptions for Purchased Services may be added during a
								subscription term at the same pricing as the underlying subscription
								pricing, prorated for the portion of that subscription term
								remaining at the time the subscriptions are added, and (c) any added
								subscriptions will terminate on the same date as the underlying
								subscriptions. Customer agrees that its purchases are not contingent
								on the delivery of any future functionality or features, or
								dependent on any oral or written public comments made by Company or
								its personnel regarding future functionality or features.
							</li>
							<li>
								<strong>Usage Limits.</strong> Company may place technical or
								non-technical limitations on the use of the Service(s) by Customer,
								including but not limited to allocation of storage space or
								bandwidth; maximum e-invoices; maximum e-way bills; maximum
								customers; maximum accounts; maximum products; maximum file size;
								maximum resolution of photos or videos; formats in which photos or
								videos may be uploaded; languages in which content may be uploaded;
								and any other limitation mentioned in the Order Form, Privacy
								Policy, or as specified in the Service. Company shall not be liable
								for any loss or damage faced by the Customer or any third party as a
								result of any technical or non-technical limitation, whether or not
								such limitation is explicitly mentioned above. Company may, where
								technically possible and feasible, and subject to execution of an
								additional Order Form by Customer, offer to increase certain limits
								placed upon certain features and functionalities. Customer
								understands that Company may refuse to increase a limitation for any
								reason at its sole discretion. Customer further understands that
								certain technical limitations cannot be increased as they may be
								beyond Company’s control or may require disproportionate effort or
								resources.
							</li>
							<li>
								<strong>Accuracy of Content.</strong>
								Content, including but not limited to information regarding GSTR-1
								filing status, and calculations performed by the Services, are
								displayed on an “as is” basis. The Company shall not be responsible
								for the accuracy and completeness of Content. You are responsible
								for verifying all Content, including but not limited to whether your
								documents and information are correct, whether the calculations are
								correct, whether Your tax return has been filed and received by the
								income tax department successfully, and for taking appropriate
								alternative actions if necessary.{" "}
							</li>
						</ol>
					</li>
				</ul>
				<h2>Fees and Payment</h2>
				<ul>
					<li>
						<ol>
							<li>
								<strong>Fees.</strong> Customer will pay all fees specified in Order
								Forms. Except as otherwise specified herein or in an Order Form, (i)
								fees are based on Service subscriptions purchased, (ii) payment
								obligations are non-cancelable and fees paid are non-refundable, and
								(iii) quantities purchased cannot be decreased during the relevant
								subscription term. Company may offer personalized support,
								customized tools, or other customer/non-standard work subject to the
								payment of additional charges. Unless specified otherwise, such
								additional charges are not included in the fees specified in Order
								Forms. Customer shall be liable to pay such additional charges as
								may be specified by Company prior to providing such personalized
								support, customized tools, or other customer/non-standard work.
							</li>
							<li>
								<strong>Changes in Fees.</strong> Company may change the fees
								charged for the Services at any time, provided that, for Services
								billed on a subscription basis, the change will become effective
								only at the end of the then-current billing cycle of Your
								subscription. Company will provide You with reasonable prior written
								notice of any change in fees to give You an opportunity to cancel
								Your subscription before the price change becomes effective.
							</li>
							<li>
								<strong>Invoicing and Payment.</strong> Customer is responsible for
								providing complete and accurate billing and contact information to
								Company and notifying Company of any changes to such information.
								Customer must pay all fees and other dues by the date of renewal in
								order to avoid disruption to the Service. In case of non-payment of
								dues, by the date of renewal of subscription, Company may terminate
								or suspend Customer’s access to the Service until the Customer pays
								the applicable subscription fee and charges.{" "}
							</li>
							<li>
								<strong>Overdue Charges.</strong> If any invoiced amount is not
								received by Company by the due date, then without limiting Company’s
								rights or remedies, (a) those charges may accrue late interest at
								the rate of 1.5% of the outstanding balance per month, or the
								maximum rate permitted by law, whichever is lower, and/or (b)
								Company may condition future subscription renewals and Order Forms
								on payment terms shorter than those specified in the “Invoicing and
								Payment” section above.
							</li>
							<li>
								<strong>Chargeback.</strong> Chargebacks are prohibited under this
								Agreement. Any dispute regarding payments must be raised by the
								Customer within seven (7) days of the payment by sending a notice to
								the Company. Initiating a chargeback for any fees or payment made to
								Company shall constitute a violation of this Agreement. For
								violation of this clause, Company reserves the right to: (a) disable
								or delete Your account(s) and all associated data, (b) revoke Your
								licenses, (c) refuse to provide Services in future, (d) charge late
								interest at the rate of 1.5% of the amount per month, or the maximum
								rate permitted by law, whichever is lower, (e) condition future
								subscription renewals and Order Forms on payment terms shorter than
								those specified in the “Invoicing and Payment” section above, and /
								or (f) initiate appropriate legal action against You.
							</li>
							<li>
								<strong>Suspension of Service.</strong> If any charge owing by
								Customer under this agreement for services is overdue, Company may,
								without prior notice and without limiting its other rights and
								remedies, suspend Services until such amounts are paid in full.
							</li>
							<li>
								<strong>Reactivation Charges.</strong> Any account that has been
								terminated, suspended or any subscription that has been cancelled
								under the terms of this Agreement, may be reactivated by the
								Customer and Company on mutually agreeable terms upon payment of
								such charges and fees as may be determined by Company in its sole
								discretion.
							</li>
							<li>
								<strong>Taxes.</strong> Company&#39;s fees do not include any taxes,
								levies, duties, or similar governmental assessments of any nature,
								including, for example, value-added, sales, goods and service, use
								or withholding taxes, assessable by any jurisdiction whatsoever
								(collectively, “<strong>Taxes</strong>
								”). Customer is responsible for paying all Taxes associated with its
								purchases hereunder. If Company has the legal obligation to pay or
								collect Taxes for which Customer is responsible under this section,
								Company will invoice Customer and Customer will pay that amount
								unless Customer provides Company with a valid tax exemption
								certificate authorized by the appropriate taxing authority. For
								clarity, Company is solely responsible for taxes assessable against
								it based on its income, property, and employees.
							</li>
						</ol>
					</li>
				</ul>
				<h2>Intellectual Property Rights and Licenses</h2>
				<ul>
					<li>
						<ol>
							<li>
								<strong>Reservation of Rights.</strong> Subject to the limited
								rights expressly granted hereunder, Company, its Affiliates, its
								licensors and content providers reserve all of their rights, titles,
								and interests in and to the Services, Content, logos, templates,
								tutorials, sample surveys, sample questions and questionnaires, and
								other materials created by them, including all of their related
								intellectual property rights. Company owns all right, title, and
								interest in and to Company’s registered and unregistered, domestic
								and foreign trademarks, service marks, trademark applications,
								service mark applications, trade names, patents, patent
								applications, copyrights, copyright applications, discoveries,
								know-how and trade secrets of and relating to the Services,
								including any and all survey templates created by Company. You agree
								not to remove and to always include any and all copyright and
								intellectual property notices attached to intellectual property
								owned by or otherwise licensed to You by Company, its Affiliates,
								its licensors or its content providers, including all templates. No
								one may copy, reproduce, transmit, post, distribute or create,
								derivative works from the Services without express, prior written
								authorization from Company. No rights are granted to Customers or
								Users hereunder other than as expressly set forth herein.
							</li>
							<li>
								<strong>You Own Your Data.</strong> You own Your Content that You
								provide to Us. You provide a worldwide perpetual irrevocable
								sub-licensable royalty-free license to the Company to host, modify,
								transmit, reproduce, create derivatives from, display, perform, and
								otherwise use, Your Data for: (i) the improvement of Services, (ii)
								for the purpose of provision of Services to You, and (iii) for
								compliance with this Agreement, Privacy Policy, and applicable laws.
							</li>
							<li>
								<strong>Feedback for Company’s Website and Services.</strong>{" "}
								Customer may, at its sole discretion, choose to provide comments,
								suggestion, enhancement request, recommendation, correction, or
								other feedback (collectively, “Feedback”) to Company regarding
								Company’s Services. Company may, but shall have no obligation to,
								use the comments or feedback to improve Service(s) offered by
								Company. Customer grants to Company, its Affiliates, assignees,
								subsidiaries, and successors the following licenses relating to the
								operation of Company’s or its Affiliates’ services without any
								obligations to Customer a worldwide perpetual irrevocable
								sub-licensable royalty-free license to: (i) use and incorporate into
								its services any Feedback provided by Customer or Users; (ii) use,
								reproduce, modify, adapt, publish, translate, create derivative
								works from, distribute, perform, transmit, and display such Feedback
								(in whole or in part) and/or to incorporate it in other works in any
								form, media, or technology now known or later developed for the full
								term of any rights that may exist in such Feedback; (iii) display
								Feedback online and offline and permit others (including without
								limitation Company’s Affiliates) to do the same; and (iv) display
								portions of the Feedback online or offline and permit others to do
								the same.
							</li>
						</ol>
					</li>
				</ul>
				<h2>Representations and Disclaimers</h2>
				<ul>
					<li>
						<ol>
							<li>
								<strong>Your Representations and Warranties.</strong> You represent
								that You have validly entered into this Agreement and have the legal
								power to do so. You further represent that Your Content is owned by
								You and You have the right to upload it to the Service. You
								represent that you are at least 18 (eighteen) years of age and have
								not been previously suspended or removed by the Company, or
								disqualified for any other reason, from availing the Services. In
								addition, you represent and warrant that you have the right,
								authority and capacity to agree to the Terms and to abide by them.
								You shall not impersonate any person or entity, or falsely state or
								otherwise misrepresent your identity, age or affiliation with any
								person or entity. In the event of any violation of the Terms, the
								Company reserves the right to suspend or permanently prevent you
								from availing the Services.
							</li>
							<li>
								<strong>Company’s Representations and Warranties.</strong> Company
								represents that Company has validly entered into this Agreement and
								have the legal power to do so. Company warrants that during an
								applicable subscription term (a) the Services will perform
								materially in accordance with this Agreement, Privacy Policy, and
								the applicable Order Forms; and (b) the Services will be provided in
								a workmanlike manner that conforms to the relevant, prevailing
								industry standards. For any breach of a warranty above, Customer’s
								exclusive remedies are those described in the “Termination” and
								“Effect of Termination” sections below.
							</li>
							<li>
								<strong>Warranty Disclaimers.</strong> EXCEPT AS EXPRESSLY PROVIDED
								HEREIN, THE COMPANY DOES NOT MAKE ANY WARRANTY OF ANY KIND, WHETHER
								EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, AND THE COMPANY
								SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED
								WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
								ENJOYMENT, OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY
								APPLICABLE LAW. CONTENT AND FREE SERVICES ARE PROVIDED “AS IS,” AND
								AS AVAILABLE EXCLUSIVE OF ANY WARRANTY WHATSOEVER. THE COMPANY
								EXPRESSLY DISCLAIMS THAT SERVICES AND/OR CONTENT WILL BE
								UNINTERRUPTED, TIMELY, SECURE, VIRUS-FREE, ERROR-FREE, ACCURATE, OR
								RELIABLE.
							</li>
						</ol>
					</li>
				</ul>
				<h2>Indemnification</h2>
				<ul>
					<li>
						<ol>
							<li>
								<strong>Third Party Claims.</strong> Customer will defend Company
								against claims brought against Company, its Affiliates,
								subcontractors, or their respective directors, officers, employees,
								agents, vendors, or suppliers, by any third party related to
								Customer Content, or use of Website or Content or Service(s) by
								Customer. Customer will indemnify Company against all damages
								finally awarded against Company, its Affiliates, subcontractors, or
								their respective directors, officers, employees, agents, vendors, or
								suppliers, (or the amount of any settlement entered into) with
								respect to these claims.
							</li>
							<li>
								<strong>Claim Procedure.</strong>
								<ol>
									<li>
										Company will notify the Customer in writing of any claim,
										reasonably cooperate in the defense, and may appear through
										counsel reasonably acceptable to the Company.
									</li>
									<li>Company will have the right to control defense.</li>
									<li>
										Any settlement of a claim will not include a financial or
										specific performance obligation on, or admission of
										liability by, the Company.{" "}
									</li>
								</ol>
							</li>
						</ol>
					</li>
				</ul>
				<h2>Limitation of Liability</h2>
				<ul>
					<li>
						<ol>
							<li>
								<strong>Limitation of Liability.</strong> IN NO EVENT SHALL THE
								AGGREGATE LIABILITY OF THE COMPANY TOGETHER WITH ALL OF ITS
								AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
								AGENTS, VENDORS, AND SUPPLIERS, ARISING OUT OF OR RELATED TO THIS
								AGREEMENT, AND ALL AGREEMENTS EXECTED UNDER THIS AGREEMENT OR AS A
								PART OF THIS AGREEMENT, EXCEED THE TOTAL AMOUNT PAID BY CUSTOMER AND
								ITS AFFILIATES HEREUNDER FOR THE SERVICES GIVING RISE TO THE
								LIABILITY IN THE TWELVE MONTHS PRECEDING THE FIRST INCIDENT OUT OF
								WHICH THE LIABILITY AROSE, OR INDIAN RUPEES FIVE THOUSAND (INR
								5000), WHICHEVER IS LOWER. NOTWITHSTANDING ANYTHING ABOVE, IN NO
								EVENT SHALL THE AGGREGATE LIABILITY OF THE COMPANY TOGETHER WITH ALL
								OF ITS AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS,
								EMPLOYEES, AGENTS, VENDORS, AND SUPPLIERS, ARISING OUT OF OR RELATED
								TO FREE SERVICES PROVIDED UNDER THIS AGREEMENT, EXCEED INDIAN RUPEES
								TEN (INR 10). THE FOREGOING LIMITATION WILL APPLY WHETHER AN ACTION
								IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY BUT
								WILL NOT LIMIT CUSTOMER&#39;S AND ITS AFFILIATES’ PAYMENT
								OBLIGATIONS UNDER THE “FEES AND PAYMENT” SECTION ABOVE. COMPANY’S
								LIABILITY FOR DAMAGES UNDER THIS AGREEMENT IS LIMITED TO DIRECT
								MONETARY DAMAGES. IN NO EVENT WILL COMPANY BE LIABLE FOR PROVIDING
								SUBSTITUTE SERVICES.
							</li>
							<li>
								<strong>Exclusion of Consequential and Related Damages.</strong> IN
								NO EVENT SHALL COMPANY, ITS AFFILIATES, OFFICERS, DIRECTORS,
								EMPLOYEES, AGENTS, VENDORS, OR SUPPLIERS BE LIABLE UNDER CONTRACT,
								TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL THEORY WITH
								RESPECT TO THE WEBSITE OR SERVICES (OR ANY CONTENT OR INFORMATION
								AVAILABLE THROUGH THE WEBSITE OR SERVICES): (1) FOR ANY LOST
								PROFITS, REVENUES, DATA, GOODWILL, OR INDIRECT, SPECIAL, INCIDENTAL,
								CONSEQUENTIAL, COVER, BUSINESS INTERRUPTION OR PUNITIVE DAMAGES; (2)
								FOR AN INABILITY TO USE THE SERVICE; OR (3) FOR ANY BUGS, VIRUSES,
								TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF
								ORIGINATION), EVEN IF COMPANY OR ITS AFFILIATES HAVE BEEN ADVISED OF
								THE POSSIBILITY OF THE ABOVE OR IF COMPANY&#39;S OR ITS AFFILIATES’
								REMEDY OTHERWISE FAILS IN ITS ESSENTIAL PURPOSE. THE FOREGOING
								LIMITATIONS SHALL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE
								LAW.
							</li>
							<li>
								<strong>Essential Basis.</strong> CUSTOMER ACKNOWLEDGES THAT COMPANY
								HAS SET ITS PRICES AND ENTERED INTO THIS AGREEMENT IN RELIANCE ON
								THE DISCLAIMERS OF WARRANTIES AND LIMITATION OF LIABILITY AND THE
								SAME FORM AN ESSENTIAL BASIS FOR THE BARGAIN BETWEEN THE PARTIES.
							</li>
						</ol>
					</li>
				</ul>
				<h2>Term and Termination</h2>
				<ul>
					<li>
						<ol>
							<li>
								<strong>Term of Agreement.</strong> This Agreement commences on the
								date Customer first accepts it and continues until it is terminated
								by either party as provided below.
							</li>
							<li>
								<strong>Term of Purchased Subscriptions.</strong> The term of each
								subscription shall be as specified in the applicable Order Form.
								Except as expressly provided in the applicable Order Form,
								promotional or one-time priced subscriptions shall not be renewed at
								the promotional or one-time price, but shall instead be renewed at
								Company’s applicable list price in effect at the time of the
								renewal. Notwithstanding anything to the contrary, any renewal in
								which subscription volume for any Services has decreased from the
								prior term will result in repricing at renewal without regard to the
								prior term’s per-unit pricing.
							</li>
							<li>
								<strong>Termination by Customer.</strong> Customer may terminate
								this Agreement for cause (a) upon 30 days’ written notice to Company
								of a material breach of this Agreement by Company if such breach
								remains uncured at the expiration of such period, or (b) immediately
								upon sending a written notice if Company becomes the subject of a
								petition in bankruptcy or any other proceeding relating to
								insolvency, receivership, liquidation, or assignment for the benefit
								of creditors. For the purpose of this clause, written notice must be
								sent to{" "}
								<a href="mailto:Contact@skyscend.com">Contact@skyscend.com</a>{" "}
								INSERT_HIGH_PRIORITY_EMAIL_ADDRESS_FOR_LEGAL_COMPLIANCE with the
								subject line “Notice of Termination of Agreement”.
							</li>
							<li>
								<strong>Termination by Company.</strong> Company may terminate this
								Agreement and/or subscription(s) or Service(s) purchased under this
								Agreement (a) immediately upon written notice to Customer of a
								breach of this Agreement, Privacy Policy, or an Order Form, by
								Customer, or (b) immediately upon sending a written notice if
								Customer becomes the subject of a petition in bankruptcy or any
								other proceeding relating to insolvency, receivership, liquidation,
								or assignment for the benefit of creditors, or (c) immediately and
								without notice for failure to make timely payment, or (d) upon 30
								days’ written notice to Customer without assigning any reason.
							</li>
							<li>
								<strong>Effect of Termination.</strong> If this Agreement is
								terminated by Customer in accordance with “Termination by Customer”
								above, or by Company by way of a 30 days’ written notice without
								assigning any reason under “Termination by Company”, then Company
								shall refund to Customer any prepaid fees covering the remainder of
								the term of all Order Forms after the effective date of termination
								on a pro-rated basis. In no circumstance shall Company be liable to
								refund any amount to Customer for termination of this Agreement or
								any Service by Customer except if such termination has been done as
								described above. In no circumstance shall Company be liable to make
								any payment to Customer for termination of Free Services under this
								Agreement, or termination of this Agreement if the Customer did not
								have an active Order Form at the time of such termination. Upon
								termination of this Agreement by either party, Customer shall pay to
								Company any unpaid fees, dues, or amounts for Service(s) that have
								been provided by Company prior to the effective date of termination
								of the Agreement or Service, regardless of whether such Service(s)
								were used by Customer, regardless of which party terminated the
								Agreement or Service, and regardless of the reason for termination
								of the Agreement or Service. Company may retain Your information for
								a period of one hundred and eighty days after termination of the
								Agreement, or for such other period as may be required by applicable
								law.
							</li>
							<li>
								<strong>Surviving Provisions.</strong> The sections titled
								“Definitions”, “Customer’s Responsibilities”, “Usage Restrictions”,
								“Legally Prohibited Content”, “Removal of Content”, “Accuracy of
								Content”, “Fees and Payment”, “Intellectual Property Rights and
								Licenses”, “Representations and Disclaimers”, “Indemnification”,
								“Limitation of Liability”, “Effect of Termination”, “Surviving
								Provisions”, “General Provisions”, and such other parts of this
								Agreement that by their nature survive termination of this
								Agreement, shall survive any termination or expiration of this
								Agreement for the maximum duration permitted under applicable law.
							</li>
						</ol>
					</li>
				</ul>
				<h2>General Provisions</h2>
				<ul>
					<li>
						<ol>
							<li>
								<strong>Entire Agreement and Order of Precedence.</strong> This
								Agreement is the entire agreement between Company and Customer
								regarding Customer’s use of Services and Content and supersedes all
								prior and contemporaneous agreements, proposals, or representations,
								written or oral, concerning its subject matter. The parties agree
								that any term or condition stated in a Customer purchase order or in
								any other Customer order documentation (excluding Order Forms) is
								void. In the event of any conflict or inconsistency among the
								following documents, the order of precedence shall be: (1) this
								Agreement, (2) Privacy Policy, and (3) the applicable Order Form.
								Titles and headings of sections of this Agreement are for
								convenience only and shall not affect the construction of any
								provision of this Agreement.
							</li>
							<li>
								<strong>Relationship of Parties.</strong> The parties are
								independent. This Agreement does not create a partnership,
								franchise, joint venture, agency, fiduciary or employment
								relationship between the parties. Each party will be solely
								responsible for payment of all compensation owed to its employees,
								as well as all employment-related taxes.
							</li>
							<li>
								<strong>Third-Party Beneficiaries.</strong> There are no third-party
								beneficiaries under this Agreement.
							</li>
							<li>
								<strong>Waiver.</strong> No failure or delay by Company in
								exercising any right under this Agreement will constitute a waiver
								of that right.
							</li>
							<li>
								<strong>Force Majeure.</strong> The parties understand that there
								may be an occurrence of an event or effect that cannot be reasonably
								anticipated or controlled, which even by the exercise of reasonable
								diligence cannot prevent non-performance, and in particular,
								performance of the Services. These occurrences and events include,
								but are not limited to: acts of God; acts of government; acts of
								war; acts of public enemies; acts of terror; strikes or other labor
								problems; natural disaster; floods; fires; earthquakes; epidemic;
								pandemic; civil unrest; riots; explosions; outages; general Internet
								brown-outs or black-outs or shortage of bandwidth; hardware
								failures; hacking, denial of service, or ransomware attacks; actions
								of the elements; or other similar causes beyond the control of
								Company in the performance of this Agreement. Upon the occurrence of
								such event or effect, Customer agrees to excuse performance under
								this Agreement and not hold the Company liable for the delay in or
								failure of performance under this Agreement. Any such delay in or
								failure of performance shall not constitute default or give rise to
								any liability for damages.
							</li>
							<li>
								<strong>Severability.</strong> If any provision of this Agreement is
								held by a court of competent jurisdiction to be contrary to law, the
								provision will be deemed null and void, and the remaining provisions
								of this Agreement will remain in effect.
							</li>
							<li>
								<strong>Modifications of this Agreement.</strong> This Agreement may
								be modified by Company from time to time. If We do this, we will
								post the modified Terms on this page and will indicate the date
								these Terms were last updated. Similarly, the modified Privacy
								Policy and the modified Anti-Spam Policy shall be posted at their
								respective pages, along with indications of the date on which they
								were last updated. Any such modifications will become effective no
								earlier than fourteen (14) days after they are posted, except that
								modifications required to provide new features without negatively
								affecting Your rights under these Terms or modifications made to
								correct typographical errors or modifications made to comply with
								legal obligations, including but not limited to Our or Your
								obligations under a law, regulation, court order, or government
								order, will be effective immediately. You shall be responsible to
								review the Agreement from time to time so as to be aware of any such
								modifications to these terms. We may, for Our convenience only and
								without waiving Your obligation to periodically review the
								Agreement, attempt to notify You of significant modifications to
								this Agreement through the website user interface, in an email
								notification, or through other reasonable means. You understand and
								agree that Your continued access, registration or use of the Service
								shall be deemed to be Your acceptance of all modifications to the
								Agreement.
							</li>
							<li>
								<strong>Assignment.</strong> Company may assign any of its rights or
								obligations hereunder, whether by operation of law or otherwise,
								without the Customer’s prior written consent. Customer may not
								assign any of its rights or obligations hereunder, whether by
								operation of law or otherwise, without the Company’s prior written
								consent. Provided, however, either party may assign this Agreement
								in its entirety (including all Order Forms), without the other
								party’s consent to its Affiliate or in connection with a merger,
								acquisition, corporate reorganization, or sale of all or
								substantially all of its assets. Notwithstanding the foregoing, if a
								party is acquired by, sells substantially all of its assets to, or
								undergoes a change of control in favor of, a direct competitor of
								the other party, then such other party may terminate this Agreement
								upon written notice. Subject to the foregoing, this Agreement will
								bind and inure to the benefit of the parties, their respective
								successors, and permitted assigns.
							</li>
							<li>
								<strong>Recovery.</strong> In the event Company must initiate
								mediation, arbitration, litigation, or otherwise become a party to
								any other action at law or other proceeding against the Customer or
								related to Customer to enforce any of the terms of this Agreement,
								or by reason of any breach or default, Company shall in any such
								action or proceeding be entitled to recover from Customer all costs
								and reasonable attorneys’ fees it incurred.
							</li>
							<li>
								<strong>Governing Law and Arbitration.</strong> This Agreement is
								governed in all respects by the laws of Republic of India. Any
								controversy or claim arising out of or relating to this Agreement,
								the Website or the Service shall be settled by binding arbitration
								in accordance with India’s ‘Arbitration and Conciliation Act, 1996’.
								Any such controversy or claim shall be arbitrated on an individual
								basis, and shall not be consolidated in any arbitration with any
								claim or controversy of any other party. The place of arbitration
								and the seat of arbitration shall be in Bangalore, India only, and
								the judgment on the arbitration award may be enforced only by a
								court of appropriate jurisdiction in Bangalore, India. The award of
								the arbitrator shall be final and binding upon the parties without
								appeal or review. Notwithstanding the foregoing, the Company may
								seek any interim or preliminary relief from a court of competent
								jurisdiction in Bangalore, India. Company at its option, may bypass
								arbitration in cases of fraud or other crimes against itself,
								interference with its technical operations or violations of its
								rights or property.
							</li>
							<li>
								<strong>Manner of Giving Notice.</strong> Except as otherwise
								specified in this Agreement, all notices related to this Agreement
								will be in writing and will be effective upon (a) personal delivery
								to the party or to an executive officer of the party to whom the
								same is directed; (b) the day of sending by email if sent without
								receipt of any delivery failure notice; or (c) only in case of the
								existence of an Order Form: (i) in case of physical post within
								India, the second business day after mailing if sent by registered
								or certified mail, postage and charges prepaid, addressed to the
								party’s address, or (ii) in case of international physical post, the
								seventh business day after mailing if sent by registered or
								certified mail, postage and charges prepaid, addressed to the
								party’s address.
							</li>
							<li>
								<strong>Grievance Officer.</strong> In case Your issue with
								enforcement of these Terms has not been resolved through the usual
								means of communicating with the Company’s support, You may contact
								Our Grievance Officer,{" "}
								<a href="mailto:legal@skyscend.com">legal@skyscend.com</a>{" "}
							</li>
						</ol>
					</li>
				</ul>
			</ScrollContainer>
			<Grid container justify="space-between">
				<Button
					color="secondary"
					onClick={async () => {
						setUpdateButtonDecline(true);
						logout();
					}}
					disabled={isLoadingDecline || isLoadingAccept}
				>
					{isLoadingDecline ? "Returning" : "Decline"}
					{isLoadingDecline && <Spinner size={20} />}
				</Button>
				<Button
					color="primary"
					disabled={isLoadingDecline || isLoadingAccept}
					onClick={async () => {
						const scrollDiv = document.getElementById("termsDiv");

						if (!isAtScrollBottom && scrollDiv.scrollHeight > scrollDiv.clientHeight) {
							enqueueSnackbar("Please read the Terms & Conditions carefully", {
								variant: "error",
							});
							return;
						}

						setUpdateButton(true);

						try {
							await acceptTermsAndConditions(userId, apiDateContent, "1.0");
							await refreshToken();
							window.location.reload();
						} catch (e) {
							enqueueSnackbar(defaultErrorToast(e), {
								variant: "error",
							});
							console.log(e);
							setUpdateButton(false);
						}
					}}
				>
					{isLoadingAccept ? "Loading SkyscendPay" : "Accept"}
					{isLoadingAccept && <Spinner size={20} />}
				</Button>
			</Grid>
		</PaperBase>
	);
};

const ScrollContainer = styled.div`
	height: 65vh;

	overflow-y: auto;
	margin: 1rem 0 1rem 0;
	padding: 0 2rem;
	& p {
		padding-right: 1rem;
		margin-bottom: 0;
	}
`;

export default TermsAndConditions;
