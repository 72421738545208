import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import {} from "styled-components/macro";
import { useSnackbar } from "notistack";
import { PaperBox, Spinner } from "../../../../../styles/styles";
import { defaultErrorToast } from "../../../../../styles/global";
import { createeInvoice } from "../../../../../services/gisService";
import { updateDocumentStatus } from "../../../../../services/invoiceService";

const ConfirmStep5 = ({ orgId, documentId, invoiceData, handleBack }) => {
	const { enqueueSnackbar } = useSnackbar();
	// console.log("Step 5 Data: ", invoiceData);

	const history = useHistory();

	const [isLoading, setGenerateButton] = useState(false);

	const generateInvoiceFunction = async () => {
		try {
			setGenerateButton(true);
			const data = await createeInvoice(orgId, invoiceData);
			await updateDocumentStatus(orgId, documentId, "Invoice Generated");
			enqueueSnackbar(`Invoice Generated.  ${data.data.invoiceRefNBR}`, {
				variant: "success",
			});
			history.push("/eInvoices");
		} catch (e) {
			setGenerateButton(false);
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};
	const handleCancel = () => {
		history.push("/eInvoices");
	};

	return (
		<>
			<Typography variant="h5" component="h1">
				Summary
			</Typography>

			<PaperBox rounded>
				<Typography variant="h6" gutterBottom>
					Document Details
				</Typography>
				<Grid container spacing={2}>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Supply Type</span>
						<span>{invoiceData.supplyType || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Reverse Charge</span>
						<span>{invoiceData.regRev || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>IGST On Intra Transfer</span>
						<span>{invoiceData.igstOnIntra || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Document Type</span>
						<span>{invoiceData.docType || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Source Document Id</span>
						<span>{invoiceData.sourceDocId || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Document Date</span>
						<span>{invoiceData.docDate || "N/A"}</span>
					</Grid>
				</Grid>
			</PaperBox>

			<PaperBox rounded>
				<Typography variant="h6" gutterBottom>
					Customer Details
				</Typography>
				<Grid container spacing={2}>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Customer GSTN</span>
						<span>{invoiceData.customerInfo.gstn || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Legal Name</span>
						<span>{invoiceData.customerInfo.legalName || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Trade Name</span>
						<span>{invoiceData.customerInfo.tradeName || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Address Line 1</span>
						<span>{invoiceData.customerInfo.addressLine1 || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Address Line 2</span>
						<span>{invoiceData.customerInfo.addressLine2 || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>City</span>
						<span>{invoiceData.customerInfo.city || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Postal Code</span>
						<span>{invoiceData.customerInfo.pin || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>State</span>
						<span>{invoiceData.customerInfo.state || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Phone</span>
						<span>{invoiceData.customerInfo.phone || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Email</span>
						<span>{invoiceData.customerInfo.email || "N/A"}</span>
					</Grid>
				</Grid>
			</PaperBox>

			<PaperBox rounded>
				<Typography variant="h6" gutterBottom>
					Your Organization Details
				</Typography>
				<Grid container spacing={2}>
					<Grid item className="data-display" xs={12} md={4}>
						<span>GSTN</span>
						<span>{invoiceData.supplierInfo.gstn || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Legal Name</span>
						<span>{invoiceData.supplierInfo.legalName || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Trade Name</span>
						<span>{invoiceData.supplierInfo.tradeName || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Address Line 1</span>
						<span>{invoiceData.supplierInfo.addressLine1 || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Address Line 2</span>
						<span>{invoiceData.supplierInfo.addressLine2 || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>City</span>
						<span>{invoiceData.supplierInfo.city || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Postal Code</span>
						<span>{invoiceData.supplierInfo.pin || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>State</span>
						<span>{invoiceData.supplierInfo.state || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Phone</span>
						<span>{invoiceData.supplierInfo.phone || "N/A"}</span>
					</Grid>
					<Grid item className="data-display" xs={12} md={4}>
						<span>Email</span>
						<span>{invoiceData.supplierInfo.email || "N/A"}</span>
					</Grid>
				</Grid>
			</PaperBox>

			{invoiceData.eWayBillInfo !== undefined && invoiceData.eWayBillInfo.gstn && (
				<PaperBox rounded>
					<Typography variant="h6" gutterBottom>
						eWay Bill Details
					</Typography>
					<Grid container spacing={2}>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Name of the Transporter</span>
							<span>{invoiceData.eWayBillInfo.name || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Transporter GSTN</span>
							<span>{invoiceData.eWayBillInfo.gstn || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Mode</span>
							<span>{invoiceData.eWayBillInfo.mode || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Distance</span>
							<span>{invoiceData.eWayBillInfo.distance || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Vehicle Number</span>
							<span>{invoiceData.eWayBillInfo.number || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Vehicle Type</span>
							<span>{invoiceData.eWayBillInfo.type || "N/A"}</span>
						</Grid>
					</Grid>
				</PaperBox>
			)}

			<PaperBox rounded>
				<Typography variant="h6" gutterBottom>
					Line Items
				</Typography>
				<Table hover responsive>
					<thead className="dark-table-header">
						<tr>
							<th>HSN Code</th>
							<th>Description</th>
							<th>Quantity</th>
							<th>Unit</th>
							<th>Unit Price</th>
							<th>Tax</th>
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{invoiceData?.items?.map((item, index) => (
							<tr key={index}>
								<td>{item.hsnCode}</td>
								<td>{item.description}</td>
								<td>{item.quantity}</td>
								<td>{item.unit || "N/A"}</td>
								<td>{Number(item.unitPrice)?.toFixed(2)}</td>
								<td>{Number(item.tax)?.toFixed(2)}</td>
								<td>{Number(item.totalAmount)?.toFixed(2)}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</PaperBox>

			<Box component={Grid} mb={3} container justify="space-between">
				<Grid item>
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Exit
					</Button>
				</Grid>
				<Grid item>
					<Button onClick={handleBack} style={{ marginRight: "1rem" }} disableFocusRipple>
						Back
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={generateInvoiceFunction}
						disabled={isLoading}
						disableFocusRipple
					>
						{isLoading ? "Generating Invoice" : "Generate Invoice"}
						{isLoading && <Spinner size={20} />}
					</Button>
				</Grid>
			</Box>
		</>
	);
};

export default ConfirmStep5;
