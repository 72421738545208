import { Container } from "@material-ui/core";
import React from "react";
import { PaperBase } from "../../styles/styles";
import { Titler } from "../common/Titler";

const ContactUs = () => (
	<Container>
		<PaperBase>
			<Titler title="Help Center" />
			Work in Progress :)
		</PaperBase>
	</Container>
);

export default ContactUs;
