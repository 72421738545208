import React from "react";
import { useUser } from "./user";
import { getOrgProfile, getProfile } from "../services/profileService";

export const ProfileContext = React.createContext(null);

export const ProfileProvider = ({ children }) => {
	const [profile, setProfile] = React.useState({ hasProfileLoaded: false });

	const { user } = useUser();

	React.useEffect(() => {
		(async () => {
			if (user && user.challengeName !== "NEW_PASSWORD_REQUIRED") {
				const { data } = await getProfile();
				let orgactive = false;
				let orgsuperadmin = false;

				try {
					if (data !== null && data !== undefined) {
						const orgData = await getOrgProfile(data.organizations[0].id);
						if (
							orgData !== null &&
							orgData !== undefined &&
							orgData.data !== undefined &&
							orgData.data.active === true
						) {
							orgactive = true;

							if (orgData.data.isSuperAdmin === true) orgsuperadmin = true;
						}
					}
				} catch (e) {
					// console.log("error getting org details.");
				}

				// console.log("Profile Data: ", data);

				setProfile({
					userId: data.id,
					firstName: data.firstName,
					middleName: data.middleName,
					lastName: data.lastName,

					email: data.email,
					countryCode: data.phCountryCode,
					phoneNum: data.phNum,

					orgId: data.organizations[0].id,
					orgName: data.organizations[0].name,

					userRole: data.organizations[0].userRole.join(","),

					termsAccepted: data.termsAccepted,
					termsUrl: data.termsUrl,

					selectedOrgId: data.organizations[0].id,
					selectedOrgName: data.organizations[0].name,

					hasProfileLoaded: true,
					orgActive: orgactive,
					orgSuperAdmin: orgsuperadmin,
				});
			}
		})();
	}, [user]);

	const values = React.useMemo(() => ({ ...profile, setProfile }), [profile]);

	return <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => {
	const context = React.useContext(ProfileContext);

	if (context === undefined)
		throw new Error("`useUser` hook must be used within a `UserProvider` component");

	return context;
};
