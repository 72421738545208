import * as Yup from "yup";
import FormModel from "./FormModel";

const {
	formField: {
		orgName,
		contactName,
		contactNumber,
		contactEmail,
		addressLine1,
		city,
		state,
		country,
		postalCode,
		orgPanNumber,
		orgGstNumber,
		lastYearRevenue,
		avgRevenueForLast3yrs,
		yearOfIncorporation,
		numberOfEmployee,
		numberOfInvoicesPerMonth,
		firstName,
		lastName,
		email,
		phNum,
		phCountryCode,
	},
} = FormModel;

const EMAIL_REGEX = /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
const NUMBERONLY_REGEX = /^[0-9]+$/;
const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]+$/;
// const NUMBERWITHTWODECIMALS_REGEX = /^(\d{1,})(\.\d{1,2})?$/;

export default [
	Yup.object().shape({
		[orgName.name]: Yup.string().required(`${orgName.requiredErrorMsg}`),
		[contactName.name]: Yup.string().required(`${contactName.requiredErrorMsg}`),
		[contactNumber.name]: Yup.string()
			.required(`${contactNumber.requiredErrorMsg}`)
			.matches(NUMBERONLY_REGEX, contactNumber.invalidErrorMsg)
			.test("conlen", `${contactNumber.invalidErrorMsg}`, (val) => val && val.length === 10),
		[contactEmail.name]: Yup.string()
			.required(`${contactEmail.requiredErrorMsg}`)
			.matches(EMAIL_REGEX, contactEmail.invalidErrorMsg),
		[addressLine1.name]: Yup.string().required(`${addressLine1.requiredErrorMsg}`),
		[city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
		[state.name]: Yup.string().required(`${state.requiredErrorMsg}`),
		[country.name]: Yup.string().required(`${country.requiredErrorMsg}`),
		[postalCode.name]: Yup.string().required(`${postalCode.requiredErrorMsg}`),
		[orgPanNumber.name]: Yup.string().matches(ALPHANUMERIC_REGEX, orgPanNumber.invalidErrorMsg),
		[orgGstNumber.name]: Yup.string()
			.required(`${orgGstNumber.requiredErrorMsg}`)
			.matches(ALPHANUMERIC_REGEX, orgGstNumber.invalidErrorMsg),
		[lastYearRevenue.name]: Yup.string().required(`${lastYearRevenue.requiredErrorMsg}`),
		[avgRevenueForLast3yrs.name]: Yup.string().required(
			`${avgRevenueForLast3yrs.requiredErrorMsg}`
		),
		[yearOfIncorporation.name]: Yup.string()
			.required(`${yearOfIncorporation.requiredErrorMsg}`)
			.matches(NUMBERONLY_REGEX, yearOfIncorporation.invalidErrorMsg)
			.test(
				"len",
				`${yearOfIncorporation.invalidErrorMsg}`,
				(val) => val && val.length === 4
			),
		[numberOfEmployee.name]: Yup.string().required(`${numberOfEmployee.requiredErrorMsg}`),

		[numberOfInvoicesPerMonth.name]: Yup.string().required(
			`${numberOfInvoicesPerMonth.requiredErrorMsg}`
		),
	}),

	Yup.object().shape({
		[firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
		[lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
		[email.name]: Yup.string()
			.required(`${email.requiredErrorMsg}`)
			.matches(EMAIL_REGEX, email.invalidErrorMsg),
		[phNum.name]: Yup.string().required(`${phNum.requiredErrorMsg}`),
		[phCountryCode.name]: Yup.string().required(`${phCountryCode.requiredErrorMsg}`),
	}),
];
