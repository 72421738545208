import React from "react";
import { Container } from "@material-ui/core";
import { Titler } from "../../common/Titler";
import { PaperBase } from "../../../styles/styles";
import EWayBillForm from "./EWayBillForm";

export const GenerateEWayBill = () => (
	<Container disableGutters>
		<PaperBase p={3}>
			<Titler title="Generate eWayBill" />
			<EWayBillForm />
		</PaperBase>
	</Container>
);

export default GenerateEWayBill;
