import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box, Button, Grid, IconButton, Typography, useMediaQuery } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useForm, useFieldArray } from "react-hook-form";
import { ErrorBox, ErrorList, Label, PaperBox, RequiredStar } from "../../../../../styles/styles";

export default ({ orgId, documentId, invoiceData, setInvoiceData, handleBack, handleNext }) => {
	const history = useHistory();

	useEffect(() => {
		// console.log("Step 3 Entry: ", invoiceData);
	}, [invoiceData]);

	const { control, register, errors, handleSubmit, setError, clearErrors, watch } = useForm({
		mode: "onTouched",
		defaultValues: {
			...invoiceData,
		},
	});

	const NextStep = (data) => {
		// console.log(data);
		const formattedList = [];

		data.items.map((data) =>
			formattedList.push({
				...data,
			})
		);
		setInvoiceData({ ...invoiceData, items: formattedList });
		// console.log("Step 3 Exit: ", { ...invoiceData, items: formattedList });
		handleNext();
	};

	const { fields, append, remove } = useFieldArray({
		control,
		name: "items",
	});

	useEffect(() => {
		// Error if no Line Items
		if (!fields.length) {
			setError("items", {
				type: "minimum",
				message: "At Least 1 Line Item Required",
			});
		}
	}, [fields, setError]);

	const watchLineItems = watch("items");

	// Line Item Responsiveness Checkers:
	const isBelowMd = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const handleCancel = () => {
		history.push("/eInvoices");
	};

	const masterUnitCodes = [
		{ key: "BAG", value: "BAG" },
		{ key: "BDL", value: "BDL" },
		{ key: "BAL", value: "BAL" },
		{ key: "BKL", value: "BKL" },
		{ key: "BOU", value: "BOU" },
		{ key: "BOX", value: "BOX" },
		{ key: "BTL", value: "BTL" },
		{ key: "BUN", value: "BUN" },
		{ key: "CAN", value: "CAN" },
		{ key: "CTN", value: "CTN" },
		{ key: "DOZ", value: "DOZ" },
		{ key: "DRM", value: "DRM" },
		{ key: "GGR", value: "GGR" },
		{ key: "GRS", value: "GRS" },
		{ key: "NOS", value: "NOS" },
		{ key: "PAC", value: "PAC" },
		{ key: "PCS", value: "PCS" },
		{ key: "PRS", value: "PRS" },
		{ key: "ROL", value: "ROL" },
		{ key: "SET", value: "SET" },
		{ key: "TBS", value: "TBS" },
		{ key: "TGM", value: "TGM" },
		{ key: "THD", value: "THD" },
		{ key: "TUB", value: "TUB" },
		{ key: "UNT", value: "UNT" },
		{ key: "CBM", value: "CBM" },
		{ key: "CCM", value: "CCM" },
		{ key: "KLR", value: "KLR" },
		{ key: "MLT", value: "MLT" },
		{ key: "UGS", value: "UGS" },
		{ key: "SQF", value: "SQF" },
		{ key: "SQM", value: "SQM" },
		{ key: "SQY", value: "SQY" },
		{ key: "GYD", value: "GYD" },
		{ key: "KME", value: "KME" },
		{ key: "MTR", value: "MTR" },
		{ key: "YDS", value: "YDS" },
		{ key: "TON", value: "TON" },
		{ key: "QTL", value: "QTL" },
		{ key: "GMS", value: "GMS" },
		{ key: "KGS", value: "KGS" },
		{ key: "OTH", value: "OTH" },
	];

	return (
		<>
			<Form onSubmit={handleSubmit(NextStep)}>
				<Box component={Grid} mb={5} container justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							onClick={handleBack}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>

				<PaperBox rounded>
					<Grid container alignItems="baseline" justify="space-between">
						<Typography variant="h6" gutterBottom>
							Item List
						</Typography>
					</Grid>

					{fields.map((item, index) => (
						<div key={item.id}>
							<Grid container spacing={1}>
								<Grid item xs={12} md={3} lg>
									<Form.Group controlId={`validateLineItem${index + 1}hsnCode`}>
										{(index === 0 || isBelowMd) && (
											<Box component={Label}>
												<span>
													HSN Code <RequiredStar />
												</span>
											</Box>
										)}

										<Form.Control
											name={`items[${index}].hsnCode`}
											defaultValue={item.hsnCode || ""}
											ref={register({
												required: "HSN Code Required",
											})}
											className={
												errors.items?.[index]?.hsnCode && "field-error"
											}
											type="text"
										/>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3} lg={3}>
									<Form.Group
										controlId={`validateLineItem${index + 1}description`}
									>
										{(index === 0 || isBelowMd) && (
											<Box component={Label}>
												<span>
													Description
													<RequiredStar />
												</span>
											</Box>
										)}

										<Form.Control
											name={`items[${index}].description`}
											defaultValue={item.description || ""}
											ref={register({
												required: "Description Required",
											})}
											className={
												errors.items?.[index]?.description && "field-error"
											}
											type="text"
										/>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3} lg>
									<Form.Group controlId={`validateLineItem${index + 1}quantity`}>
										{(index === 0 || isBelowMd) && (
											<Label>
												<span>
													Qty
													<RequiredStar />
												</span>
											</Label>
										)}
										<Form.Control
											name={`items[${index}].quantity`}
											defaultValue={Number(item.quantity) || ""}
											ref={register({
												required: "Quantity Required",
												pattern: {
													value: /^\d*\.?\d*$/,
													message: "Invalid Number for Quantity",
												},
											})}
											className={
												errors.items?.[index]?.quantity && "field-error"
											}
										/>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3} lg>
									<Form.Group controlId={`validateLineItem${index + 1}unit`}>
										{(index === 0 || isBelowMd) && (
											<Label>
												<span>
													Unit
													<RequiredStar />
												</span>
											</Label>
										)}

										<Form.Control
											as="select"
											name={`items[${index}].unit`}
											defaultValue={item.unit || "PCS"}
											ref={register({
												required: "Unit Required",
											})}
											className={errors.items?.[index]?.unit && "field-error"}
										>
											{masterUnitCodes.map((unitItem) =>
												unitItem.value === item.unit ? (
													<option
														key={unitItem.key}
														value={unitItem.value}
														selected
													>
														{unitItem.key}
													</option>
												) : (
													<option
														key={unitItem.key}
														value={unitItem.value}
													>
														{unitItem.key}
													</option>
												)
											)}
										</Form.Control>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3} lg>
									<Form.Group controlId={`validateLineItem${index + 1}unitPrice`}>
										{(index === 0 || isBelowMd) && (
											<Label>
												<span>
													Unit Price
													<RequiredStar />
												</span>
											</Label>
										)}
										<Form.Control
											name={`items[${index}].unitPrice`}
											defaultValue={
												item.unitPrice === null || item.unitPrice === ""
													? 0.0
													: (
															Number(
																(item.total === undefined
																	? item.totalAmount
																	: item.total) - item.tax
															) / Number(item.quantity)
													  ).toFixed(2)
											}
											ref={register({
												required: "Unit Price Required",
												pattern: {
													value: /^\d*\.?\d*$/,
													message: "Invalid Number for Unit Price",
												},
											})}
											className={
												errors.items?.[index]?.unitPrice && "field-error"
											}
										/>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3} lg>
									<Form.Group controlId={`validateLineItem${index + 1}tax`}>
										{(index === 0 || isBelowMd) && (
											<Label>
												<span>
													Tax
													<RequiredStar />
												</span>
											</Label>
										)}
										<Form.Control
											name={`items[${index}].tax`}
											defaultValue={
												item.tax === null || item.tax === ""
													? 0.0
													: Number(item.tax).toFixed(2)
											}
											ref={register({
												required: "Tax Required",
												pattern: {
													value: /^\d*\.?\d*$/,
													message: "Invalid Number for Tax",
												},
											})}
											className={errors.items?.[index]?.tax && "field-error"}
										/>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3} lg>
									<Form.Group
										controlId={`validateLineItem${index + 1}totalAmount`}
									>
										{(index === 0 || isBelowMd) && <Label>Total</Label>}
										<Form.Control
											name={`items[${index}].totalAmount`}
											value={(
												Number(watchLineItems[index]?.quantity).toFixed(2) *
													Number(
														watchLineItems[index]?.unitPrice
													).toFixed(2) +
												Number(watchLineItems[index]?.tax)
											).toFixed(2)}
											ref={register()}
											readOnly
											type="number"
										/>
									</Form.Group>
								</Grid>

								<Grid
									item
									xs
									md
									lg={1}
									style={{
										alignSelf: "flex-end",
										marginBottom: -4,
										textAlign: "center",
									}}
								>
									<Form.Group controlId={`validateLineItem${index + 1}Delete`}>
										<IconButton
											style={{ color: "red" }}
											aria-label="delete"
											onClick={() => {
												remove(index);
											}}
										>
											<DeleteIcon />
										</IconButton>
									</Form.Group>
								</Grid>
							</Grid>

							<ErrorList errors={errors.items} index={index} />
						</div>
					))}

					{errors.items?.message && <ErrorBox>{errors.items.message}</ErrorBox>}

					<Grid container justify="center">
						<IconButton
							aria-label="add-line-item"
							onClick={() => {
								append({
									description: "",
									quantity: "",
									tax: "",
									unitPrice: "",
								});

								// Clear the error
								if (errors.items?.type === "minimum") clearErrors("items");
							}}
							color="primary"
						>
							<AddCircleIcon fontSize="large" />
						</IconButton>
					</Grid>
				</PaperBox>

				<Box component={Grid} container mt={5} mb={3} justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							onClick={handleBack}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>
			</Form>
		</>
	);
};
