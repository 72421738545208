import React, { useState, useMemo, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import { Button } from "@material-ui/core";
import ServerTable from "../../common/ServerTable";
import { getSelectedOrgList } from "../../../services/userService";
import { ErrorBox } from "../../../styles/styles";
import { paginate, sort } from "../../../utils/TableUtils";

const SelectOrgIdModal = ({
	userId,
	orgId,
	selectedOrgId,
	orgSelector,
	showOrgSearchModal,
	handleClose,
}) => {
	const [loading, setLoading] = useState(false);

	// Fetched Data
	const [orgData, setOrgData] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);

	// Error Handling
	const [orgDataError, SetOrgDataError] = useState();

	const columns = useMemo(
		() => [
			{
				Header: "Name",
				accessor: "name",
				Cell: ({ value, row }) => (
					<Button
						style={{ border: "none", padding: 0, fontSize: "1rem" }}
						id="orgNameSelect"
						color="primary"
						onClick={() => {
							orgSelector(row.original.id, row.original.name, row.original.userRole);
							handleClose();
						}}
						disableFocusRipple
					>
						{value}
					</Button>
				),
			},
		],
		[orgSelector, handleClose]
	);

	const [showLogout, setShowLogout] = useState({
		bool: false,
		message: "",
		full: "",
	});

	const fetchOrgData = useCallback(
		async ({ pageIndex, pageSize, sortBy }) => {
			try {
				setLoading(true);
				const { data } = await getSelectedOrgList(userId);

				if (data.organizations.length !== 0) {
					let final_data = paginate(data.organizations, pageIndex, pageSize);
					if (sortBy.length) final_data = sort(final_data, sortBy);
					setOrgData(final_data);
					let pageCnt = parseInt(data.organizations.length / pageSize, 10);
					if (data.organizations.length % pageSize !== 0) {
						pageCnt += 1;
					}
					setPageCount(pageCnt);
					setTotalRecords(data.organizations.length);
				} else SetOrgDataError("No Organization(s) found");

				setLoading(false);
			} catch (e) {
				// console.log("Error", e.response?.data);
				SetOrgDataError("An error has occured during organization search.");
				setShowLogout({
					bool: true,
					message: "An error has occurred. Try again.",
					full: e.response?.data.errorDescription,
				});
			}
		},
		[userId]
	);

	return (
		<Modal
			centered
			backdrop="static"
			dialogClassName="selector-modal"
			keyboard={false}
			show={showOrgSearchModal}
			onHide={handleClose}
		>
			<Modal.Header closeButton={!!selectedOrgId}>
				<Modal.Title>Select Organization</Modal.Title>
				{/* {showLogout.bool && !selectedOrgId && ( */}
				{/* )} */}
			</Modal.Header>
			<Modal.Body>
				<Col md={12} style={{ marginBottom: 20 }}>
					{/* <SearchBox
                    // value={searchQuery}
                    //  onChange={handleSearch}
                    /> */}

					{showLogout.bool && (
						<p style={{ textAlign: "center", color: "red", marginBottom: 0 }}>
							{showLogout.message}
						</p>
					)}
					{showLogout.bool && (
						<p
							style={{
								textAlign: "center",
								color: "darkred",
								fontSize: "0.9rem",
							}}
						>
							({showLogout.full})
						</p>
					)}

					{orgDataError ? (
						<ErrorBox>{orgDataError}</ErrorBox>
					) : (
						<ServerTable
							columns={columns}
							data={orgData}
							fetchData={fetchOrgData}
							loading={loading}
							pageCount={pageCount}
							totalRecords={totalRecords}
							defaultRowsPerPage={5}
							defaultSortBy={[{ id: "name", desc: false }]}
						/>
					)}
				</Col>
			</Modal.Body>
		</Modal>
	);
};

export default SelectOrgIdModal;
