import React, { useState } from "react";

import { Container, Grid, Typography } from "@material-ui/core";
// import MohawkLogo from "../../images/Mohawklogo.svg";
import SkyscendLogo from "../../images/skyscend-pay.png";
import DigitalIndia from "../../images/DigitalIndia.png";

import Login from "./LoginForm";
import ForgotPassword from "./ForgotPasswordForm";
import OTP from "./OTP";

import { TopBarSignUp } from "../Navbar/Topbar/Topbar";
import {} from "styled-components/macro";

import RegistrationPage from "./Registration/RegistrationPage";
import { PaperBase } from "../../styles/styles";

export const StartPage = ({ prevLoc, tabbedTo }) => {
	const [forgotPasswordChecker, setForgotPasswordChecker] = useState(false);

	const forgotPassword = () => {
		setForgotPasswordChecker(!forgotPasswordChecker);
	};

	const [regStep, setRegStep] = useState();

	return (
		<Container
			disableGutters
			css={`
				height: ${tabbedTo === "signup"
					? regStep === 0
						? "100%"
						: regStep === 1
						? "80%"
						: "100%"
					: "85%"};
				transition: height 0.2s ease-in-out;
			`}
		>
			<PaperBase
				css={`
					height: 100%;
					overflow: auto;
				`}
				p="0"
			>
				{tabbedTo === "signup" && (
					<>
						<TopBarSignUp />
						<RegistrationPage setRegStep={setRegStep} />
					</>
				)}

				{tabbedTo !== "signup" && (
					<Grid
						container
						css={`
							height: 100%;
						`}
					>
						<Grid
							item
							xs={4}
							container
							direction="column"
							justify="space-between"
							alignItems="space-between"
							css={`
								padding: 0 20px;
							`}
						>
							<Grid
								item
								css={`
									display: flex;
									justify-content: center;
									align-items: center;
									height: 40%;
									width: 100%;
								`}
							>
								<img
									css={`
										min-width: 10rem;
										max-width: 20rem;
									`}
									src={SkyscendLogo}
									alt="Skyscend Logo"
								/>
							</Grid>
							<Grid
								item
								css={`
									height: 60%;
									width: 100%;
								`}
							>
								{tabbedTo === "otp" && <OTP />}
								{tabbedTo === "login" &&
									(forgotPasswordChecker ? (
										<ForgotPassword switchToPassword={forgotPassword} />
									) : (
										<Login
											switchToPassword={forgotPassword}
											prevLoc={prevLoc && prevLoc}
											setRegStep={setRegStep}
										/>
									))}
							</Grid>
						</Grid>
						<Grid
							item
							xs={8}
							container
							direction="column"
							justify="center"
							alignItems="center"
							css={`
								background-color: ${(props) => props.theme.light.secondary};
								width: 100%;
								color: ${(props) => props.theme.light.font.white};
							`}
						>
							<Typography
								variant="h2"
								component="h1"
								css={`
									font-weight: 600;
									font-size: 4rem;
								`}
							>
								Skyscend e-Invoice
							</Typography>
							<Typography
								variant="h6"
								css={`
									font-size: 1.3rem;
								`}
							>
								Global e-Invoicing Solution
							</Typography>
							<img
								src={DigitalIndia}
								alt="Digital India Logo"
								width="500px"
								css={`
									margin-top: 10%;
								`}
							/>
						</Grid>
					</Grid>
				)}
			</PaperBase>
		</Container>
	);
};

export default StartPage;
