import React from "react";
import { useIdleTimer } from "react-idle-timer";
// import { useHistory } from "react-router-dom";
// import auth from "../services/authService";
import { useUser } from "../hooks/user";

const SESSION_IDLE_MINUTES = 30;

const AutoLogoutTimer = (props) => {
	const { logout } = useUser();
	const { ComposedComponent } = props;

	const handleOnIdle = () => {
		logout();
	};

	const { getLastActiveTime } = useIdleTimer({
		timeout: 1000 * 60 * SESSION_IDLE_MINUTES,
		onIdle: handleOnIdle,
		debounce: 500,
	});

	return <ComposedComponent />;
};

export default AutoLogoutTimer;
