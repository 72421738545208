import React from "react";
import { Container, Grid } from "@material-ui/core";

import { PaperBase } from "../../styles/styles";

export const StripeCancel = () => (
	<Container>
		<PaperBase>
			<Grid container spacing={20}>
				<Grid item className="data-display" xs={12} md={12}>
					<span>
						<p>Please pay/subscribe to create eInvoice(s).</p>
					</span>
				</Grid>
			</Grid>
		</PaperBase>
	</Container>
);

export default StripeCancel;
