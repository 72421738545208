import React from "react";
import { Form } from "react-bootstrap";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Label, RequiredStar } from "../../styles/styles";
import {} from "styled-components/macro";

import { reInviteOrgUserByAdmin } from "../../services/userManagementService";

export const UserResendInvitation = () => {
	const { enqueueSnackbar } = useSnackbar();

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {},
	});

	// Submit
	const submitRequest = async (data) => {
		try {
			const resp = await reInviteOrgUserByAdmin(data.email);
			if (
				resp !== null &&
				resp !== undefined &&
				resp.data !== null &&
				resp.data !== undefined
			)
				enqueueSnackbar("User Invitation sent successfully", {
					variant: "success",
				});
		} catch (e) {
			enqueueSnackbar("Error sending invitation.", {
				variant: "error",
			});
		}
	};

	return (
		<Container>
			<Form onSubmit={handleSubmit(submitRequest)}>
				<Typography
					variant="h5"
					component="h1"
					css={`
						margin-bottom: 42px;
					`}
				>
					User Resend Invitation
				</Typography>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Form.Group controlId="email">
							<Label>
								<span>
									E-mail
									<RequiredStar />
								</span>
								{errors.email && (
									<p className="form-error-inline">{errors.email.message}</p>
								)}
							</Label>
							<Form.Control
								name="email"
								type="text"
								ref={register({
									required: "Required",
								})}
							/>
						</Form.Group>
					</Grid>

					<Grid item className="data-display" xs={12} md={12}>
						<span>
							<p>
								Note: ReInvite will work for user(s) who lost their welcome email.
							</p>
						</span>
					</Grid>
				</Grid>

				<Grid
					css={`
						margin-top: 28px;
					`}
					item
					container
					justify="space-between"
				>
					<Button variant="contained" color="primary" type="submit" disableFocusRipple>
						Send Invite
					</Button>
				</Grid>
			</Form>
		</Container>
	);
};

export default UserResendInvitation;
