import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { defaultErrorToast } from "../../styles/global";
import { getOrgUserDetails, updateOrgUserDetails } from "../../services/userManagementService";
import { useProfile } from "../../hooks/profile";
import { Label, PaperBase, RequiredStar } from "../../styles/styles";

export const UserEdit = () => {
	const { orgId, email } = useProfile();

	const { enqueueSnackbar } = useSnackbar();

	const [userDetails, setUserDetails] = useState();

	const userRoles = [
		{ key: "Admin", value: "Admin" },
		{ key: "User", value: "User" },
	];

	const params = useParams();
	const { userId } = params;

	const history = useHistory();

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {},
	});

	useEffect(() => {
		(async () => {
			const { data } = await getOrgUserDetails(orgId, userId);
			// console.log({ OrgUser_Details: data });
			setUserDetails(data);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId, userId]);

	// Submit
	const submitRequest = async (data) => {
		try {
			const formattedData = {
				...data,
				email: userDetails.email,
			};

			// _.mapKeys(formattedData, function (value, key) {
			// if (_.isEmpty(value)) delete formattedData[key];
			// });

			// console.log("data", formattedData);
			await updateOrgUserDetails(orgId, userId, formattedData);
			enqueueSnackbar("User Updated Successfully", {
				variant: "success",
			});

			history.goBack();
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	return (
		<Container>
			<PaperBase>
				{userDetails ? (
					<Form onSubmit={handleSubmit(submitRequest)}>
						<Typography variant="h5" component="h1" gutterBottom>
							Modify User
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Form.Group controlId="email">
									<Label>E-mail</Label>
									<Form.Control
										name="email"
										type="text"
										readOnly
										plaintext
										value={userDetails.email}
									/>
								</Form.Group>
							</Grid>
							<Grid item xs={12} md={6}>
								<Form.Group controlId="firstName">
									<Label>
										<span>
											First Name
											<RequiredStar />
										</span>
										{errors.firstName && (
											<p className="form-error-inline">
												{errors.firstName.message}
											</p>
										)}
									</Label>
									<Form.Control
										name="firstName"
										type="text"
										defaultValue={userDetails.firstName}
										ref={register({
											required: "Required",
										})}
									/>
								</Form.Group>
							</Grid>

							<Grid item xs={12} md={6}>
								<Form.Group controlId="lastName">
									<Label>
										<span>
											Last Name
											<RequiredStar />
										</span>
										{errors.lastName && (
											<p className="form-error-inline">
												{errors.lastName.message}
											</p>
										)}
									</Label>
									<Form.Control
										name="lastName"
										type="text"
										defaultValue={userDetails.lastName}
										ref={register({
											required: "Required",
										})}
									/>
								</Form.Group>
							</Grid>
							<Grid item xs={12} md={6}>
								<Form.Group controlId="phCountryCode">
									<Label>
										<span>Phone Country Code</span>
										{errors.phCountryCode && (
											<p className="form-error-inline">
												{errors.phCountryCode.message}
											</p>
										)}
									</Label>
									<Form.Control
										name="phCountryCode"
										type="text"
										defaultValue={userDetails.phCountryCode}
										className={errors.phCountryCode && "field-error"}
										ref={register({
											pattern: {
												value: /^\d*$/,
												message: "Invalid",
											},
										})}
									/>
								</Form.Group>
							</Grid>
							<Grid item xs={12} md={6}>
								<Form.Group controlId="phNum">
									<Label>
										<span>Phone Number</span>
										{errors.phNum && (
											<p className="form-error-inline">
												{errors.phNum.message}
											</p>
										)}
									</Label>
									<Form.Control
										name="phNum"
										type="text"
										defaultValue={userDetails.phNum}
										className={errors.phNum && "field-error"}
										ref={register({
											pattern: {
												value: /^\d*$/,
												message: "Invalid",
											},
										})}
									/>
								</Form.Group>
							</Grid>
							<Grid item xs={12} md={6}>
								<Form.Group controlId="userRole">
									<Label>
										<span>
											Role(s)
											<RequiredStar />
										</span>
										{errors.userRole && (
											<p className="form-error-inline">
												{errors.userRole.message}
											</p>
										)}
									</Label>
									{userDetails.email !== email ? (
										<Form.Control
											as="select"
											name="userRole"
											ref={register()}
											multiple
											defaultValue={userDetails.userRole}
										>
											{userRoles.map((item) => (
												<option key={item.key} value={item.value}>
													{item.key}
												</option>
											))}
										</Form.Control>
									) : (
										<Form.Control
											as="select"
											name="userRole"
											ref={register()}
											multiple
											defaultValue={userDetails.userRole}
										>
											{userRoles.map((item) =>
												userDetails.userRole.includes(item.value) ? (
													<option key={item.key} value={item.value}>
														{item.key}
													</option>
												) : (
													""
												)
											)}
										</Form.Control>
									)}
								</Form.Group>
							</Grid>
							<Grid item xs={12} md={6}>
								<Form.Group controlId="active">
									<Label>
										<span>Status</span>
									</Label>
									<Form.Control
										name="active"
										type="text"
										value={userDetails.active === true ? "Active" : "Inactive"}
										readOnly
										plaintext
									/>
								</Form.Group>
							</Grid>

							<Grid item container justify="space-between">
								<Button
									variant="outlined"
									color="secondary"
									onClick={history.goBack}
									disableFocusRipple
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="primary"
									type="submit"
									disableFocusRipple
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Form>
				) : (
					<Loader
						type="ThreeDots"
						color="#00aeef"
						secondaryColor="#00aeef"
						height={80}
						width={80}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							minHeight: "100%",
							height: "fit-content",
						}}
					/>
				)}
			</PaperBase>
		</Container>
	);
};

export default UserEdit;
