import React, { useState, useEffect } from "react";
import { Box, Button, Container, Grid, LinearProgress, Typography } from "@material-ui/core";
import { Titler } from "../common/Titler";

import { PaperBase, PaperBox } from "../../styles/styles";
import { PasswordChangeModal } from "../Start/PasswordChange";
import {} from "styled-components/macro";
// import SocialLinks from "./socialLinks";
import { getOrgProfile } from "../../services/profileService";
import "../../styles/profile.scss";
import { useProfile } from "../../hooks/profile";

export const ProfileManagement = () => {
	const {
		username,
		userRole,
		firstName,
		lastName,
		email,
		countryCode,
		phoneNum,
		orgId,
	} = useProfile();

	// Retrieve Org Information
	const [org, setOrgDetails] = useState();
	const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
	const handleClose = () => setShowPasswordChangeModal(false);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await getOrgProfile(orgId);
				// console.log("Retrieved Org Profile:", data);
				setOrgDetails(data);
			} catch (e) {
				console.log(e);
			}
		})();
	}, [orgId]);

	return (
		<Container maxWidth="md">
			{showPasswordChangeModal && (
				<PasswordChangeModal
					isFirstTimeLogin={false}
					showModal={showPasswordChangeModal}
					username={username}
					hideModal={handleClose}
				/>
			)}
			<PaperBase>
				<Titler title="Profile" />
				<Typography variant="h4" gutterBottom>
					Profile
				</Typography>
				<PaperBox rounded>
					<Box component={Grid} mb={3} container justify="space-between">
						<Typography variant="h5">Personal Information</Typography>
						<Button
							variant="text"
							color="primary"
							onClick={() => {
								setShowPasswordChangeModal(true);
							}}
							size="small"
						>
							Change Password
						</Button>
					</Box>

					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<p className="data-display">
								<span>Name</span>
								<span>{`${firstName} 								 
								${lastName}`}</span>
							</p>
						</Grid>

						<Grid item xs={12} md={6}>
							<p className="data-display">
								<span>Username</span>
								<span>{email}</span>
							</p>
						</Grid>

						<Grid item xs={12} md={6}>
							<p className="data-display">
								<span>Email</span>
								<span>{email}</span>
							</p>
						</Grid>

						{phoneNum && (
							<Grid item xs={6}>
								<p className="data-display">
									<span>Phone Number</span>
									<span>
										+{countryCode} {phoneNum}
									</span>
								</p>
							</Grid>
						)}

						<Grid item xs={12} md={6}>
							<p className="data-display">
								<span>User Role</span>
								<span>{userRole}</span>
							</p>
						</Grid>
					</Grid>
				</PaperBox>

				{org && (
					<PaperBox rounded>
						<Typography variant="h5" gutterBottom>
							Organization
						</Typography>
						<OrgDetails org={org} />
					</PaperBox>
				)}
			</PaperBase>
		</Container>
	);
};

const OrgDetails = ({ org }) => (
	<>
		<Box component={Grid} container mb={5}>
			<Grid item container xs={6}>
				<h5
					css={`
						font-weight: 600;
					`}
				>
					Details
				</h5>
			</Grid>
			<Grid
				container
				item
				xs={6}
				direction="column"
				css={`
					font-size: 1.1rem;
				`}
			>
				<Grid item xs={12} sm={6} md>
					<p className="data-display">
						<span>Name</span>
						<span>{org.name}</span>
					</p>
				</Grid>
				<Grid item xs={12} sm={6} md>
					<p className="data-display">
						<span>Quota</span>
						<span>{org.quota.toLocaleString("en-IN")}</span>
					</p>
				</Grid>
				<Grid item xs={12} sm={6} md>
					<p className="data-display">
						<span>Subscription Validity</span>
						<span>{org.subscriptionValidity === true ? "Yes" : "No"}</span>
					</p>
				</Grid>
			</Grid>
		</Box>

		<Box component={Grid} container mb={5}>
			<Grid item container xs={6}>
				<h5
					css={`
						font-weight: 600;
					`}
				>
					Address
				</h5>
			</Grid>
			<Grid
				item
				container
				xs={6}
				direction="column"
				css={`
					font-size: 1.1rem;
					margin-bottom: 13px;
				`}
			>
				<Grid item>{org.address.addressLine1}</Grid>
				<Grid item>{org.address.addressLine2}</Grid>
				<Grid item>{org.address.city}</Grid>
				<Grid item>{org.address.state}</Grid>
				<Grid item>
					{org.address.country} - {org.address.postalCode}
				</Grid>
			</Grid>
		</Box>

		<Box component={Grid} container mb={5}>
			<Grid item container xs={6}>
				<h5
					css={`
						font-weight: 600;
					`}
				>
					Financials
				</h5>
			</Grid>
			<Grid
				item
				container
				xs={6}
				direction="column"
				css={`
					font-size: 1.1rem;
				`}
			>
				<Grid container className="data-display">
					<Grid item component="span">
						Last Year&apos;s Revenue
					</Grid>
					<Grid item component="span">
						{org.financials.lastyearRevenue ? org.financials.lastyearRevenue : "N/A"}
					</Grid>
				</Grid>

				<Grid container className="data-display">
					<Grid item component="span">
						Average Revenue for Last 3 years
					</Grid>
					<Grid item component="span">
						{org.financials.AvgRevenueforlast3yrs
							? org.financials.AvgRevenueforlast3yrs
							: "N/A"}
					</Grid>
				</Grid>

				<Grid container className="data-display">
					<Grid item component="span">
						Year of Incorporation
					</Grid>
					<Grid item component="span">
						{org.financials.yearofIncorporation}
					</Grid>
				</Grid>

				<Grid container className="data-display">
					<Grid item component="span">
						Number of Employees
					</Grid>
					<Grid item component="span">
						{org.financials.numberofEmployees}
					</Grid>
				</Grid>

				<Grid container className="data-display">
					<Grid item component="span">
						Number of Invoices per Month
					</Grid>
					<Grid item component="span">
						{org.financials.numberofInvoicespermonth}
					</Grid>
				</Grid>
			</Grid>
		</Box>

		<Box component={Grid} container mb={5}>
			<Grid item container xs={6}>
				<h5
					css={`
						font-weight: 600;
					`}
				>
					Contact
				</h5>
			</Grid>
			<Grid
				item
				container
				xs={6}
				direction="column"
				css={`
					font-size: 1.1rem;
				`}
			>
				<Grid container className="data-display">
					<Grid
						item
						component="span"
						css={`
							font-weight: 300;
						`}
					>
						Name
					</Grid>
					<Grid item component="span">
						{org.contactName}
					</Grid>
				</Grid>

				<Grid container className="data-display">
					<Grid item component="span">
						Number
					</Grid>
					<Grid item component="span">
						+{org.contactNumber}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	</>
);

export default ProfileManagement;
