import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Container, Button, Grid, Typography } from "@material-ui/core";
import {} from "styled-components/macro";
import moment from "moment";
import { useSnackbar } from "notistack";
import { PaperBase } from "../../styles/styles";
import { defaultErrorToast } from "../../styles/global";
import {
	findSubscriptionById,
	cancelSubscriptionById,
	usageReportSummary,
} from "../../services/stripeService";
import { useProfile } from "../../hooks/profile";

export const StripeSubscriptionDetail = () => {
	const { orgId } = useProfile();
	const params = useParams();
	const { subscriptionId } = params;
	const { enqueueSnackbar } = useSnackbar();

	const history = useHistory();
	// const [isLoading, setLoading] = useState(false);
	const [subscriptionDetail, setSubscriptionDetails] = useState();
	const [currentUsage, setCurrentUsage] = useState();

	useEffect(() => {
		(async () => {
			const { data } = await findSubscriptionById(orgId, subscriptionId);
			// console.log({ Invoice_Details: data });
			setSubscriptionDetails(data);

			if (data.plan.usage_type === "metered") {
				const currentusage = await usageReportSummary(
					orgId,
					subscriptionId,
					data.items.data[0].id
				);
				if (currentusage !== null && currentusage !== undefined)
					setCurrentUsage(currentusage.data.data[0]);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId, subscriptionId]);

	const cancelSubscrption = async () => {
		try {
			await cancelSubscriptionById(orgId, subscriptionId);
			enqueueSnackbar("Subscription cancelled successfully", {
				variant: "success",
			});
			history.goBack();
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	return (
		<Container>
			<PaperBase
				css={`
					height: fit-content;
				`}
			>
				{subscriptionDetail && (
					<>
						<div
							css={`
								margin-bottom: 28px;
							`}
						>
							<Typography
								variant="h5"
								component="h1"
								css={`
									margin-bottom: 5px;
								`}
							>
								My Subscription Details
							</Typography>

							<Box component={Grid} mb={5} container justify="space-between">
								<Button
									color="secondary"
									onClick={history.goBack}
									css={`
										padding: 0 2px;
										min-width: auto;
									`}
									disableElevation
								>
									Back
								</Button>

								<div style={{ padding: 5 }}>
									{subscriptionDetail !== null &&
										subscriptionDetail.status !== "canceled" && (
											<Button
												variant="contained"
												color="secondary"
												type="submit"
												style={{ marginRight: "1rem" }}
												disableFocusRipple
												onClick={cancelSubscrption}
											>
												Cancel SUbscription
											</Button>
										)}
								</div>
							</Box>
						</div>

						<Grid container spacing={2}>
							<Grid item className="data-display" xs={12} md={6}>
								<span>Subscription ID</span>
								<span>{subscriptionDetail.id || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Plan</span>
								<span>{subscriptionDetail.plan.nickname || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Usage Type</span>
								<span>{subscriptionDetail.plan.usage_type || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Billing </span>
								<span>{subscriptionDetail.billing || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Start Date</span>
								<span>
									{subscriptionDetail.start_date !== null
										? moment.unix(subscriptionDetail.start_date).format()
										: "N/A"}
								</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Status</span>
								<span>{subscriptionDetail.status || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Current Period Start</span>
								<span>
									{subscriptionDetail.current_period_start !== null
										? moment
												.unix(subscriptionDetail.current_period_start)
												.format()
										: "N/A"}
								</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Current Period End</span>
								<span>
									{subscriptionDetail.current_period_end !== null
										? moment
												.unix(subscriptionDetail.current_period_end)
												.format()
										: "N/A"}
								</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Cancelled At </span>
								<span>
									{subscriptionDetail.canceled_at !== null
										? moment.unix(subscriptionDetail.canceled_at).format()
										: "N/A"}
								</span>
							</Grid>

							{subscriptionDetail.plan.usage_type === "metered" ? (
								<Grid item className="data-display" xs={12} md={6}>
									<span>Current Usage </span>
									<span>
										{currentUsage !== null &&
										currentUsage !== undefined &&
										currentUsage.total_usage !== null
											? currentUsage.total_usage
											: "N/A"}
									</span>
								</Grid>
							) : (
								""
							)}
						</Grid>
					</>
				)}
			</PaperBase>
		</Container>
	);
};

export default StripeSubscriptionDetail;
