import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { defaultErrorToast } from "../../styles/global";
import { useProfile } from "../../hooks/profile";
import { Label, PaperBase, RequiredStar, ErrorBox, PaperBox } from "../../styles/styles";
import { getOrgDetails, updateOrgDetails } from "../../services/organizationService";
import {} from "styled-components/macro";
import { getOrgCredentials, updateOrgCredentials } from "../../services/credentialSevice";

export const OrganizationEdit = () => {
	const { orgId } = useProfile();
	const { enqueueSnackbar } = useSnackbar();
	const [orgDetails, setOrgDetails] = useState();
	const [orgCredentials, setOrgCredentials] = useState();
	const [errorDetails, setErrorDetails] = useState();
	const history = useHistory();

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {},
	});

	useEffect(() => {
		(async () => {
			try {
				const orgData = await getOrgDetails(orgId);
				if (orgData !== undefined && orgData.data !== undefined)
					setOrgDetails(orgData.data);
			} catch (e) {
				setErrorDetails("Organization validation is not yet completed.");
			}

			try {
				const credData = await getOrgCredentials(orgId);
				if (credData !== undefined && credData.data !== undefined) {
					setOrgCredentials(credData.data);
				}
			} catch (e) {
				// console.log(e);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId]);

	// Submit
	const submitRequest = async (data) => {
		try {
			const formattedData = {
				name: orgDetails.name,
				contactName: data.contactName,
				contactNumber: data.contactNumber,
				contactEmail: data.contactEmail,
				region: orgDetails.region,
				address: {
					addressLine1: data.addressLine1,
					addressLine2: data.addressLine2,
					city: data.city,
					state: data.state,
					country: data.country,
					postalCode: data.postalCode,
				},
				financials: {
					lastyearRevenue: orgDetails.financials.lastyearRevenue,
					AvgRevenueforlast3yrs: orgDetails.financials.AvgRevenueforlast3yrs,
					yearofIncorporation: orgDetails.financials.yearofIncorporation,
					numberofEmployees: orgDetails.financials.numberofEmployees,
					numberofInvoicespermonth: orgDetails.financials.numberofInvoicespermonth,
				},
				quota: Number(orgDetails.quota),
				stripePaymentToken: orgDetails.stripePaymentToken,
				subscriptionValidity: Boolean(orgDetails.subscriptionValidity),
				gstn: orgDetails.gstn,
				panNumber: orgDetails.panNumber,
				active: orgDetails.active,
			};

			// _.mapKeys(formattedData, function (value, key) {
			// if (_.isEmpty(value)) delete formattedData[key];
			// });

			console.log("data", formattedData);
			await updateOrgDetails(orgId, formattedData);

			if (
				data.apiusername !== null &&
				data.apipassword !== null &&
				data.apiusername !== undefined &&
				data.apipassword !== undefined
			) {
				await updateOrgCredentials(orgId, data.apiusername, data.apipassword);
			}
			enqueueSnackbar("Organization details updated successfully", {
				variant: "success",
			});

			history.push("/");
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const handleCancel = () => {
		history.push("/");
	};

	return (
		<Container>
			{errorDetails ? <ErrorBox>{errorDetails}</ErrorBox> : ""}
			<PaperBase>
				{orgDetails ? (
					<Form onSubmit={handleSubmit(submitRequest)}>
						<Typography
							variant="h5"
							component="h1"
							css={`
								margin-bottom: 42px;
							`}
						>
							Edit Organization
						</Typography>

						<PaperBox rounded>
							<Grid item container spacing={2}>
								<Grid item xs={12} md={3}>
									<h6
										css={`
											font-size: 0.95rem;
											font-weight: 700;
											color: #222222dd;
										`}
									>
										General Details
									</h6>
								</Grid>

								<Grid item xs={12} md={6}>
									<Form.Group controlId="name">
										<Label>
											<span>Name</span>
										</Label>
										<Form.Control
											type="text"
											defaultValue={orgDetails.name}
											readOnly
										/>
									</Form.Group>
								</Grid>
								<Grid item xs={12} md={3} />
								<Grid item xs={12} md={3} />
								<Grid item xs={12} md={3}>
									<Form.Group controlId="contactName">
										<Label>
											<span>
												Contact Name
												<RequiredStar />
											</span>
											{errors.contactName && (
												<p className="form-error-inline">
													{errors.contactName.message}
												</p>
											)}
										</Label>
										<Form.Control
											name="contactName"
											type="text"
											defaultValue={orgDetails.contactName}
											ref={register({
												required: "Required",
											})}
										/>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3}>
									<Form.Group controlId="contactNumber">
										<Label>
											<span>
												Contact Number
												<RequiredStar />
											</span>
											{errors.contactNumber && (
												<p className="form-error-inline">
													{errors.contactNumber.message}
												</p>
											)}
										</Label>
										<Form.Control
											name="contactNumber"
											type="text"
											defaultValue={orgDetails.contactNumber}
											ref={register({
												required: "Required",
											})}
										/>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3}>
									<Form.Group controlId="contactEmail">
										<Label>
											<span>
												Contact Email
												<RequiredStar />
											</span>
											{errors.contactEmail && (
												<p className="form-error-inline">
													{errors.contactEmail.message}
												</p>
											)}
										</Label>
										<Form.Control
											name="contactEmail"
											type="text"
											defaultValue={orgDetails.contactEmail}
											ref={register({
												required: "Required",
											})}
										/>
									</Form.Group>
								</Grid>
							</Grid>
						</PaperBox>

						<PaperBox rounded>
							<Grid item container spacing={2}>
								<Grid item xs={3}>
									<h6
										css={`
											font-size: 0.95rem;
											font-weight: 700;
											color: #222222dd;
										`}
									>
										Address
									</h6>
								</Grid>
								<Grid item container xs={9} direction="column" spacing={1}>
									<Grid item xs={12}>
										<Form.Group controlId="addressLine1">
											<Label>
												<span>
													Line 1
													<RequiredStar />
												</span>
												{errors.addressLine1 && (
													<p className="form-error-inline">
														{errors.addressLine1.message}
													</p>
												)}
											</Label>
											<Form.Control
												name="addressLine1"
												type="text"
												defaultValue={orgDetails.address.addressLine1}
												ref={register({
													required: "Required",
												})}
											/>
										</Form.Group>
									</Grid>

									<Grid item xs={12}>
										<Form.Group controlId="addressLine2">
											<Label>
												<span>Line 2</span>
											</Label>
											<Form.Control
												name="addressLine2"
												type="text"
												defaultValue={orgDetails.address.addressLine2}
												ref={register()}
											/>
										</Form.Group>
									</Grid>

									<Grid item container spacing={2}>
										<Grid item xs={12} md={6}>
											<Form.Group controlId="city">
												<Label>
													<span>
														City
														<RequiredStar />
													</span>
													{errors.city && (
														<p className="form-error-inline">
															{errors.city.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="city"
													type="text"
													defaultValue={orgDetails.address.city}
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="state">
												<Label>
													<span>
														State
														<RequiredStar />
													</span>
													{errors.state && (
														<p className="form-error-inline">
															{errors.state.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="state"
													type="text"
													defaultValue={orgDetails.address.state}
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>
									</Grid>

									<Grid item container spacing={2}>
										<Grid item xs={12} md={6}>
											<Form.Group controlId="country">
												<Label>
													<span>
														Country
														<RequiredStar />
													</span>
													{errors.country && (
														<p className="form-error-inline">
															{errors.country.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="country"
													type="text"
													defaultValue={orgDetails.address.country}
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="postalCode">
												<Label>
													<span>
														Postal Code
														<RequiredStar />
													</span>
													{errors.postalCode && (
														<p className="form-error-inline">
															{errors.postalCode.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="postalCode"
													type="text"
													defaultValue={orgDetails.address.postalCode}
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</PaperBox>

						<PaperBox rounded>
							<Grid item container spacing={2}>
								<Grid item xs={3}>
									<h6
										css={`
											font-size: 0.95rem;
											font-weight: 700;
											color: #222222dd;
										`}
									>
										GSTIN Details
									</h6>
								</Grid>

								<Grid item container xs={9} direction="column" spacing={1}>
									<Grid item container spacing={2}>
										<Grid item xs={12} md={6}>
											<Form.Group controlId="gstn">
												<Label>
													<span>GSTIN</span>
												</Label>
												<Form.Control
													name="gstn"
													type="text"
													value={orgDetails.gstn}
													readOnly
												/>
											</Form.Group>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</PaperBox>

						<PaperBox rounded>
							<Grid item container spacing={2}>
								<Grid item xs={3}>
									<h6
										css={`
											font-size: 0.95rem;
											font-weight: 700;
											color: #222222dd;
										`}
									>
										GSTIN API Credentials
									</h6>
								</Grid>
								<Grid item container xs={9} direction="row" spacing={2}>
									<Grid item xs={12} md={6}>
										<Form.Group controlId="apiusername">
											<Label>
												<span>
													User Name
													<RequiredStar />
												</span>
												{errors.apiusername && (
													<p className="form-error-inline">
														{errors.apiusername.message}
													</p>
												)}
											</Label>
											<Form.Control
												name="apiusername"
												type="text"
												autoComplete="off"
												defaultValue={
													orgCredentials !== undefined &&
													orgCredentials.userName !== undefined
														? orgCredentials.userName
														: ""
												}
												ref={register({
													required: "Required",
												})}
											/>
										</Form.Group>
									</Grid>

									<Grid item xs={12} md={6}>
										<Form.Group controlId="apipassword">
											<Label>
												<span>
													Password
													<RequiredStar />
												</span>
												{errors.apipassword && (
													<p className="form-error-inline">
														{errors.apipassword.message}
													</p>
												)}
											</Label>
											<Form.Control
												name="apipassword"
												type="password"
												autoComplete="off"
												ref={register({
													required: "Required",
												})}
											/>
										</Form.Group>
									</Grid>
									<Grid item xs={12} md={12}>
										<h6
											css={`
												font-size: 0.95rem;
												font-weight: 500;
												color: #222222dd;
												line-height: 1.75;
											`}
										>
											If you don&#39;t have the GSTIN credentials,{" "}
											<a
												href="https://newaccount1623384345113.freshdesk.com/support/solutions/articles/82000682105-how-to-generate-gstn-api-credentials"
												target="_blank"
												rel="noreferrer"
											>
												click here
											</a>{" "}
											and follow the steps to generate one from the government
											portal
										</h6>
									</Grid>
								</Grid>
							</Grid>
						</PaperBox>

						<Grid
							css={`
								margin-top: 28px;
							`}
							item
							container
							justify="space-between"
						>
							<Button
								variant="outlined"
								color="secondary"
								onClick={handleCancel}
								disableFocusRipple
							>
								Cancel
							</Button>

							<Button
								variant="contained"
								color="primary"
								type="submit"
								disableFocusRipple
							>
								Confirm
							</Button>
						</Grid>
					</Form>
				) : (
					""
				)}
			</PaperBase>
		</Container>
	);
};

export default OrganizationEdit;
