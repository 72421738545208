import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import {} from "styled-components/macro";
import { useForm } from "react-hook-form";
import { Label, PaperBox, RequiredStar } from "../../../../../styles/styles";

export default ({ orgId, documentId, invoiceData, setInvoiceData, handleBack, handleNext }) => {
	const history = useHistory();

	useEffect(() => {
		// console.log("Step 4 Entry: ", invoiceData);
	}, [invoiceData]);

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {
			...invoiceData,
		},
	});

	const NextStep = (data) => {
		// console.log(data);
		setInvoiceData({ ...invoiceData, eWayBillInfo: data });
		// console.log("Step 4 Exit: ", { ...invoiceData });
		handleNext();
	};

	const handleSkip = () => {
		handleNext();
	};

	const handleCancel = () => {
		history.push("/eInvoices");
	};

	return (
		<>
			<Form onSubmit={handleSubmit(NextStep)}>
				<Box component={Grid} mb={5} container justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							onClick={handleBack}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleSkip}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Skip
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>

				<Typography variant="h6" gutterBottom color="secondary">
					<Label>Note: Skip this section, If eWay Bill is not required.</Label>
				</Typography>

				<PaperBox rounded>
					<Typography variant="h6" gutterBottom>
						eWay Bill Details
					</Typography>

					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Form.Group controlId="name">
								<Label>
									<span>Name of the Transporter</span>
									{errors.name && (
										<p className="form-error-inline">{errors.name.message}</p>
									)}
								</Label>
								<Form.Control
									ref={register()}
									name="name"
									type="text"
									defaultValue={
										invoiceData.eWayBillInfo !== undefined
											? invoiceData.eWayBillInfo.name
											: ""
									}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="gstn">
								<Label>
									<span>
										Transporter GSTN <RequiredStar />
									</span>
									{errors.gstn && (
										<p className="form-error-inline">{errors.gstn.message}</p>
									)}
								</Label>
								<Form.Control
									ref={register({ required: true })}
									name="gstn"
									type="text"
									defaultValue={
										invoiceData.eWayBillInfo !== undefined
											? invoiceData.eWayBillInfo.gstn
											: ""
									}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="mode">
								<Label>
									<span>Mode</span>
								</Label>
								<Form.Control
									as="select"
									name="mode"
									ref={register()}
									defaultValue={
										invoiceData.eWayBillInfo !== undefined
											? invoiceData.eWayBillInfo.mode
											: ""
									}
								>
									<option key="" value="">
										{}
									</option>
									<option key="Road" value="Road">
										Road
									</option>
									<option key="Rail" value="Rail">
										Rail
									</option>
									<option key="Air" value="Air">
										Air
									</option>
									<option key="Ship" value="Ship">
										Ship
									</option>
								</Form.Control>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="distance">
								<Label>
									<span>
										Distance <RequiredStar />
									</span>
									{errors.distance && (
										<p className="form-error-inline">
											{errors.distance.message}
										</p>
									)}
								</Label>
								<Form.Control
									ref={register({ required: true })}
									name="distance"
									type="text"
									defaultValue={
										invoiceData.eWayBillInfo !== undefined
											? invoiceData.eWayBillInfo.distance
											: "0"
									}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="number">
								<Label>
									<span>Vehicle Number</span>
									{errors.number && (
										<p className="form-error-inline">{errors.number.message}</p>
									)}
								</Label>
								<Form.Control
									ref={register()}
									name="number"
									type="text"
									defaultValue={
										invoiceData.eWayBillInfo !== undefined
											? invoiceData.eWayBillInfo.number
											: ""
									}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="type">
								<Label>
									<span>Vehicle Type</span>
									{errors.type && (
										<p className="form-error-inline">{errors.type.message}</p>
									)}
								</Label>
								<Form.Control
									as="select"
									name="type"
									ref={register()}
									defaultValue={
										invoiceData.eWayBillInfo !== undefined
											? invoiceData.eWayBillInfo.type
											: ""
									}
								>
									<option key="" value="">
										{}
									</option>
									<option key="Regular" value="Regular">
										Regular
									</option>
									<option key="ODC" value="ODC">
										ODC
									</option>
								</Form.Control>
							</Form.Group>
						</Grid>
					</Grid>
				</PaperBox>

				<Box component={Grid} container mt={5} mb={3} justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							onClick={handleBack}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleSkip}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Skip
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>
			</Form>
		</>
	);
};
