import React, { useState } from "react";
import { Container, Grid, Typography, useMediaQuery, Button, Tabs, Tab } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useProfile } from "../../hooks/profile";
import { Titler } from "../common/Titler";
import { PaperBase } from "../../styles/styles";
import CancelEWayBill from "./cancel/CancelEWayBill";
import ViewEWayBill from "./view/ViewEWayBill";
import {} from "styled-components/macro";
import WhatsAppEWayBill from "./whatsApp/WhatsAppEWayBill";

const TabPanelFunction = ({ children, value, index, ...other }) => (
	<div
		role="tabpanel"
		css={`
			display: ${value !== index && "none"};
			margin-top: 1rem;
		`}
		id={`eWayBills-panel-${index}`}
		aria-labelledby={`eWayBills-tab-${index}`}
		{...other}
	>
		{children}
	</div>
);

const TabPanel = React.memo(TabPanelFunction);

export const EWayBills = () => {
	const { orgId, userId } = useProfile();

	const history = useHistory();

	// Tabs
	const [value, setValue] = useState(0);

	const a11yProps = (index) => ({
		id: `eWayBills-tab-${index}`,
		"aria-controls": `eWayBills-panel-${index}`,
	});

	// Table Responsiveness Checkers:
	const isBelowLg = useMediaQuery("(max-width:1200px)");

	return (
		<Container>
			<PaperBase>
				<Titler title="eWayBills" />
				<>
					<Grid container justify="space-between">
						<Grid item>
							<Typography variant="h5" component="h1" gutterBottom>
								eWayBills
							</Typography>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={() => history.push("/eWayBills/generate/list")}
								endIcon={<ChevronRightIcon />}
								disableElevation
							>
								Generate eWayBill
							</Button>
						</Grid>
					</Grid>

					<Tabs
						value={value}
						onChange={(event, newValue) => setValue(newValue)}
						indicatorColor="primary"
						textColor="primary"
						aria-label="eWayBills tabs"
						variant="scrollable"
						scrollButtons={isBelowLg ? "on" : "off"}
						css={`
							margin-bottom: 28px;
						`}
					>
						<Tab className="smaller-tab" label="View List" {...a11yProps(0)} />
						<Tab className="smaller-tab" label="Cancel eWayBill" {...a11yProps(1)} />
						<Tab className="smaller-tab" label="Send To WhatsApp" {...a11yProps(2)} />
					</Tabs>

					<TabPanel value={value} index={0}>
						<ViewEWayBill orgId={orgId} userId={userId} />
					</TabPanel>

					<TabPanel value={value} index={1}>
						<CancelEWayBill orgId={orgId} userId={userId} />
					</TabPanel>

					<TabPanel value={value} index={2}>
						<WhatsAppEWayBill orgId={orgId} userId={userId} />
					</TabPanel>
				</>
			</PaperBase>
		</Container>
	);
};
export default EWayBills;
