import React from "react";
import { Auth } from "aws-amplify";
import config from "../config.json";

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
	const [user, setUser] = React.useState(undefined);

	React.useEffect(() => {
		// Ampify Configuration
		Auth.configure({
			Auth: config,
		});

		// Fetch and set info of logged in user
		Auth.currentSession()
			.then((user) => {
				// console.log("Cognito Data: ", user);
				setUser(user);
			})
			.catch(() => setUser(null));
	}, []);

	const refreshToken = async () => {
		try {
			const cognitoUser = await Auth.currentAuthenticatedUser();
			const currentSession = await Auth.currentSession();
			cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {});
		} catch (e) {
			// console.log(e);
		}
	};

	const login = (usernameOrEmail, password) =>
		Auth.signIn(usernameOrEmail, password).then((cognitoUser) => {
			setUser(cognitoUser);

			// Keep 'Selected Org' data if the same user has logged in before
			if (
				cognitoUser.attributes &&
				cognitoUser.attributes.sub !== localStorage.getItem("sub")
			) {
				localStorage.setItem("sub", cognitoUser.attributes.sub);
				localStorage.removeItem("selectedOrgId");
				localStorage.removeItem("selectedOrgName");
			}

			return cognitoUser;
		});

	const logout = () =>
		Auth.signOut().then((data) => {
			setUser(null);
			return data;
		});

	// Make sure to not force a re-render on the components that are reading these values,
	// unless the `user` value has changed. This is an optimisation that is mostly needed in cases
	// where the parent of the current component re-renders and thus the current component is forced
	// to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the
	// same value as long as the user data is the same. If you have multiple other "controller"
	// components or Providers above this component, then this will be a performance booster.
	const values = React.useMemo(() => ({ user, login, logout, refreshToken }), [user]);

	// Finally, return the interface that we want to expose to our other components
	return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abtracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useUser = () => {
	const context = React.useContext(UserContext);

	if (context === undefined)
		throw new Error("`useUser` hook must be used within a `UserProvider` component");

	return context;
};
