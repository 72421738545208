import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Stepper, Step, StepLabel, Button, Container, Grid } from "@material-ui/core";
import moment from "moment";
import { Titler } from "../../../../common/Titler";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import smoothScroll from "../../../../../utils/SmoothScroll";
import { ErrorBox, PaperBase } from "../../../../../styles/styles";
import { useProfile } from "../../../../../hooks/profile";
import { getOrgProfile } from "../../../../../services/profileService";
import { getRossumData } from "../../../../../services/gisService";

export default () => {
	const { orgId } = useProfile();
	const history = useHistory();
	const location = useLocation();

	const [activeStep, setActiveStep] = useState(0);

	// const invoiceId = location.state?.invoiceId;
	const documentId = location.state?.documentId;

	// Step Titles
	const steps = ["Document Info", "Customer Info", "Invoice Items", "eWay Bill", "Review"];

	// Stepper Buttons
	const handleNext = () => {
		smoothScroll();
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		smoothScroll();
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// Step 1: Rossum/Invoice Data

	const [invoiceData, setInvoiceData] = useState();
	const [getOrgError, setOrgError] = useState();

	useEffect(() => {
		const fetchRossumData = async () => {
			try {
				const data1 = await getRossumData(orgId, documentId);
				const data2 = await getOrgProfile(orgId);

				if (
					data1 !== undefined &&
					data1.data !== undefined &&
					data2 !== undefined &&
					data2.data !== undefined
				) {
					const rossumData = data1.data;
					const invData = data2.data;
					// console.log("Received Rossum Data", data1);
					// console.log("Received org Data", invData);

					setInvoiceData({
						sourceDocId:
							rossumData.invoiceNumber !== null
								? rossumData.invoiceNumber
								: moment(new Date()),
						docDate:
							rossumData.invoiceDate !== null
								? moment(rossumData.invoiceDate).format("DD/MM/YYYY")
								: moment(new Date()).format("DD/MM/YYYY"),
						currency: rossumData.currency,
						items: rossumData.lineItems,
						supplierInfo: {
							gstn: invData.gstn,
							tradeName: invData.name,
							legalName: invData.name,
							addressLine1: invData.address.addressLine1,
							addressLine2: invData.address.addressLine2,
							city: invData.address.city,
							state: invData.address.state,
							pin: invData.address.postalCode,
							phone: invData.contactNumber,
							email: invData.contactEmail,
						},
					});
				} else {
					setOrgError(`Error: Unable to retrieve Document Data`);
				}
			} catch (e) {
				setOrgError(`Error: Unable to retrieve Document Data`);
			}
		};
		fetchRossumData();
	}, [orgId, documentId]);

	// TODO: Set Default Step to 4, change it once data acquired.
	// Step 4 is the Loader
	const getValidationWorkflowStep = (stepIndex) => {
		switch (stepIndex) {
			case 0:
				return (
					!getOrgError &&
					invoiceData && (
						<Step1
							orgId={orgId}
							documentId={documentId}
							invoiceData={invoiceData}
							setInvoiceData={setInvoiceData}
							handleNext={handleNext}
						/>
					)
				);
			case 1:
				return (
					<Step2
						orgId={orgId}
						documentId={documentId}
						invoiceData={invoiceData}
						setInvoiceData={setInvoiceData}
						handleBack={handleBack}
						handleNext={handleNext}
					/>
				);
			case 2:
				return (
					<Step3
						orgId={orgId}
						documentId={documentId}
						invoiceData={invoiceData}
						setInvoiceData={setInvoiceData}
						handleBack={handleBack}
						handleNext={handleNext}
					/>
				);
			case 3:
				return (
					<Step4
						orgId={orgId}
						documentId={documentId}
						invoiceData={invoiceData}
						setInvoiceData={setInvoiceData}
						handleBack={handleBack}
						handleNext={handleNext}
					/>
				);
			case 4:
				return (
					<Step5
						orgId={orgId}
						documentId={documentId}
						invoiceData={invoiceData}
						setInvoiceData={setInvoiceData}
						handleBack={handleBack}
					/>
				);
			default:
				return "Unknown Step!";
		}
	};

	return (
		<Container disableGutters>
			<Titler title="Create eInvoice" />
			<PaperBase>
				<Grid container>
					<Grid item xs={12}>
						<Stepper
							activeStep={activeStep}
							style={{ paddingLeft: 0, paddingRight: 0, marginBottom: "2rem" }}
						>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>

						{getValidationWorkflowStep(activeStep)}
						{getOrgError && (
							<Grid container justify="center" alignItems="center" direction="column">
								<ErrorBox type="error">{getOrgError}</ErrorBox>
								<Grid container justify="center">
									<Button
										id="ExitBtn"
										variant="outlined"
										color="secondary"
										size="small"
										onClick={history.goBack}
										style={{ marginRight: "0.5rem" }}
										disableElevation
										disableFocusRipple
									>
										Back
									</Button>
									<Button
										id="RefreshBtn"
										variant="contained"
										color="primary"
										size="small"
										onClick={() => {
											window.location.reload();
										}}
										disableElevation
										disableFocusRipple
									>
										Refresh
									</Button>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</PaperBase>
		</Container>
	);
};
