import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { PaperBox } from "../../../../styles/styles";

const UserDetails = (props) => {
	const { formValues } = props;
	const { firstName, lastName, email, phNum, phCountryCode } = formValues;

	return (
		<Grid item container direction="column" xs={12} sm={6}>
			<PaperBox rounded>
				<Typography variant="h6" gutterBottom>
					User Details
				</Typography>
				<Grid container spacing={2}>
					<>
						<Grid item xs={6} className="data-display">
							<span>Name</span>
							<span>{`${firstName} ${lastName}`}</span>
						</Grid>
						<Grid item xs={6} className="data-display">
							<span>Email</span>
							<span>{email || "N/A"}</span>
						</Grid>
					</>
					<>
						<Grid item xs={6} className="data-display">
							<span>Country Code</span>
							<span>{phCountryCode || "N/A"}</span>
						</Grid>
						<Grid item xs={6} className="data-display">
							<span>Phone #</span>
							<span>{phNum || "N/A"}</span>
						</Grid>
					</>
				</Grid>
			</PaperBox>
		</Grid>
	);
};

export default UserDetails;
