import { Auth } from "aws-amplify";
import _ from "lodash";
import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL_BASE;

export async function getGSPStatus(orgId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/gspStatus`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function getGSTNDetails(gstn) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/admin/gstn/${gstn}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function validateGSTNDetails(gstn) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/admin/gstn/validate/${gstn}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function geteInvoiceList(orgId, pageIndex, pageSize, sortBy, searchQuery) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/invoice?offset=${pageIndex}&size=${pageSize}`;

	if (searchQuery !== "W10=") url += `&search=${searchQuery}`;

	if (sortBy?.length)
		sortBy.forEach((item) => {
			url += `&sort=${item.id},${item.desc ? "desc" : "asc"}`;
		});

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function geteInvoiceResponse(orgId, irn) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/invoice/response/irn/${irn}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function geteInvoiceResponseByDocumentNumber(orgId, documentNumber) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/invoice/response/documentid/${documentNumber}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function downloadInvoice(orgId, irn) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/invoice/download/irn/${irn}`;

	const { data } = await http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
	const arr = new Uint8Array(data.data);
	const file = new Blob([arr], { type: "application/pdf" });
	const fileURL = URL.createObjectURL(file);
	return fileURL;
}

export async function canceleInvoice(orgId, irn, data) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/invoice/irn/${irn}`;

	return http.patch(url, data, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function createeInvoice(orgId, data) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/invoice`;

	// console.log("data:", data);

	const transactionInfo = {
		supplyType: data.supplyType,
		regRev: data.regRev,
		igstOnIntra: data.igstOnIntra,
	};

	const documentDetails = {
		docType: data.docType,
		sourceDocId: data.sourceDocId,
		docDate: data.docDate,
	};

	let formattedData = {
		transactionInfo,
		documentDetails,
		supplierInfo: {
			entity: {
				gstin: data.supplierInfo.gstn,
				legalName: data.supplierInfo.legalName,
				tradeName: data.supplierInfo.tradeName,
			},
			address: {
				addressLine1: data.supplierInfo.addressLine1,
				addressLine2: data.supplierInfo.addressLine2,
				city: data.supplierInfo.city,
				pin: data.supplierInfo.pin,
				state: data.supplierInfo.state,
			},
			contact: {
				phone: data.supplierInfo.phone,
				email: data.supplierInfo.email,
			},
		},
		buyerInfo: {
			entity: {
				gstin: data.customerInfo.gstn,
				legalName: data.customerInfo.legalName,
				tradeName: data.customerInfo.tradeName,
			},
			placeOfSupply: data.customerInfo.state,
			address: {
				addressLine1: data.customerInfo.addressLine1,
				addressLine2: data.customerInfo.addressLine2,
				city: data.customerInfo.city,
				pin: data.customerInfo.pin,
				state: data.customerInfo.state,
			},
			contact: {
				phone: data.customerInfo.phone,
				email: data.customerInfo.email,
			},
		},
		itemsList: data.items,
	};

	if (data.eWayBillInfo && data.eWayBillInfo.gstn) {
		const transportInfo = {
			name: data.eWayBillInfo.name,
			entityId: data.eWayBillInfo.gstn,
			distance: data.eWayBillInfo.distance,
			mode: data.eWayBillInfo.mode,
		};

		const vehicleInfo = {
			number: data.eWayBillInfo.number,
			type: data.eWayBillInfo.type,
		};

		const eWayBillInfo = {
			transportInfo,
			vehicleInfo,
		};

		formattedData = { ...formattedData, eWayBillInfo };
	}

	omitDeep(formattedData);

	// console.log("formattedData:", formattedData);

	return http.post(url, formattedData, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function geteWayBillList(orgId, pageIndex, pageSize, sortBy, searchQuery) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/ewaybill?offset=${pageIndex}&size=${pageSize}`;

	if (searchQuery !== "W10=") url += `&search=${searchQuery}`;

	if (sortBy?.length)
		sortBy.forEach((item) => {
			url += `&sort=${item.id},${item.desc ? "desc" : "asc"}`;
		});

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function geteWayBill(orgId, eWayBillNbr) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/ewaybill/${eWayBillNbr}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function canceleWayBill(orgId, ewayBillNBR, data) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/ewaybill/${ewayBillNBR}`;

	return http.patch(url, data, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function createWayBill(orgId, data) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/ewaybill`;
	console.log("data:", data);

	return http.post(url, data, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function uploadcXMLFile(files, orgId) {
	const formData = new FormData();

	files.map((file) => formData.append("files", file));

	const { idToken } = await Auth.currentSession();

	const url = `${apiUrl}/org/${orgId}/gis/invoice/xml`;

	return http.post(url, formData, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function getRossumData(orgId, documentId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/rossum/extract/document/${documentId}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

function omitDeep(data) {
	_.forIn(data, (value, key) => {
		if (_.isObject(value)) {
			omitDeep(value);
		} else if (_.isEmpty(value)) {
			delete data[key];
		}
	});
}

export async function whatsAppInvoice(orgId, irn, data) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/invoice/sms/irn/${irn}`;

	return http.post(url, data, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function whatsAppeWayBill(orgId, eWayBillNbr, data) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/gis/ewaybill/sms/ewaybillnumber/${eWayBillNbr}`;

	return http.post(url, data, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export default {
	getGSPStatus,
	geteInvoiceList,
	downloadInvoice,
	canceleInvoice,
	createeInvoice,
	geteInvoiceResponse,
	geteWayBillList,
	geteWayBill,
	canceleWayBill,
	createWayBill,
	uploadcXMLFile,
	getRossumData,
	validateGSTNDetails,
	whatsAppInvoice,
	whatsAppeWayBill,
	geteInvoiceResponseByDocumentNumber,
};
