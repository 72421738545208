import { createGlobalStyle } from "styled-components";
import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

// Base Colours
export const skyscendBlue = "#00AEEF";
export const skyscendPink = "#ED217C";
// export const skyscendDarkBlue = "#34393d";
export const skyscendDarkBlue = "#3E444D";
// export const skyscendDarkBlue = "#414750";

export const background = "hsl(200,33%,96%)";

export const borderColor = "rgba(0, 0, 0, 0.15)";

export const white = "#FFFFFF"; // TODO: change to rgba(255, 255, 255, 0.85);
export const lightgrey = "#D8D8D8";
export const black = "#222222";

// Font Colours
export const whiteText = "#FFFFFF";
export const greyText = "#5D5D5D";
export const blackText = "#222222";
export const labelBlackText = "#222222DD";
export const errorText = "#D2180F";

// Element Colours
export const redButton = "#D2180F";
export const greenButton = "#3D8623";
export const requiredRed = "#F00";

// Breakpoints

export const customBreakpoints = {
	values: {
		xs: 0,
		sm: 600,
		// md: 960,
		// lg: 1280,
		// xl: 1920,
		md: 900,
		lg: 1200,
		xl: 1536,
	},
};

// Default text for Error Toasts
export const defaultErrorToast = (e) =>
	e.response?.data.errorDescription ||
	e.response?.data.message ||
	"Something went wrong, please contact the administrator";

// styled-components theme variables
export const theme = {
	common: {
		required: requiredRed,
		confirmGreen: greenButton,
		exitRed: redButton,
		borderColor,

		font: {
			error: errorText,
		},

		breakpoints: { ...customBreakpoints },
	},

	light: {
		primary: skyscendBlue,
		secondary: skyscendDarkBlue,
		accent: skyscendPink,

		background,

		white,
		black,

		border: "#22222325",

		font: {
			white: whiteText,
			grey: greyText,
			black: blackText,
			lightBlack: labelBlackText,
			error: errorText,
		},
	},

	// TODO: implement a dark colour scheme eventually
	dark: {
		primary: skyscendBlue,
		secondary: skyscendDarkBlue,
		accent: skyscendPink,

		background,

		white,
		black,

		border: "#22222325",
	},
};

// Material UI Default Theme Override
// https://material-ui.com/customization/default-theme/#default-theme
// TODO: Change root font! Poppins???

const breakpoints = createBreakpoints({ ...customBreakpoints });

export const muiTheme = createMuiTheme({
	typography: {
		fontFamily: "Open Sans",
		fontSize: 14,
	},
	palette: {
		common: {
			white,
			black,
		},
		// Blue 'primary' buttons
		primary: {
			main: skyscendBlue,
			dark: skyscendPink,
			contrastText: whiteText,
		},
		// Red 'secondary' buttons
		secondary: {
			main: redButton,
			contrastText: whiteText,
		},

		text: {
			white: whiteText,
			black: blackText,
			error: errorText,
		},
	},

	breakpoints: { ...customBreakpoints },

	overrides: {
		MuiTypography: {
			gutterBottom: {
				marginBottom: 28,
			},
		},

		MuiIconButton: {
			colorPrimary: {
				"&:hover, &.Mui-focusVisible": { "& svg": { fill: skyscendBlue } },
			},
		},

		MuiButton: {
			root: {
				fontWeight: 700,
			},
			contained: {
				fontWeight: 600,
			},
			disabled: {
				// change disabled style
			},
		},

		MuiTooltip: {
			tooltip: {
				fontSize: "0.8rem",
				fontWeight: 600,
				backgroundColor: "black",

				[breakpoints.down("xs")]: {
					marginLeft: "12px !important",
					marginRight: "12px !important",
				},
			},
			arrow: {
				color: "black",
			},
		},

		MuiTextField: {
			root: {
				"& $label": {
					zIndex: "1", // was auto
				},
			},
		},

		MuiInputLabel: {
			outlined: {
				zIndex: "auto",
			},
		},

		MuiOutlinedInput: {
			root: {
				"& fieldset": {
					borderColor,
				},
				"&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
					borderColor: "hsl(0, 0%, 70%)",
				},
				"&$error:not($focused) $notchedOutline": {
					borderWidth: 1,
				},
			},
			input: {
				"&::placeholder": {
					fontSize: "0.9rem",
					color: "black",
				},
			},
			inputMarginDense: {
				paddingTop: "9.7px",
				paddingBottom: "9.7px",
			},
		},

		MuiTabs: {
			root: {
				minHeight: 36,
				height: 36,
				width: "100%",
				borderBottom: `1px solid ${lightgrey}`,
			},
		},

		MuiTab: {
			root: {
				minHeight: "auto",
				"&.smaller-tab": {
					minWidth: "auto",
					paddingLeft: "1rem",
					paddingRight: "1rem",
					fontSize: "0.9rem",
				},
				"& $wrapper p, & $wrapper": {
					fontWeight: 600,
					opacity: 0.8,
				},
				"& $wrapper": {
					opacity: 0.9,
				},
				"&$selected $wrapper p, &$selected $wrapper": {
					opacity: 1,
				},
			},
		},

		// MuiStepLabel: {
		// 	root: {
		// 		"&$active": {
		// 			fontWeight: "600 !important",
		// 			color: "red",
		// 		},
		// 	},
		// },
		// MuiStepConnector: {
		// 	lineHorizontal: {
		// 		color: "red",
		// 	},
		// },
		// MuiStepIcon: {
		// 	root: {
		// 		color: "#eaeaf0",
		// 		display: "flex",
		// 		height: 42,
		// 		alignItems: "center",
		// 		"&$active": {
		// 			color: skyscendBlue,
		// 			fontSize: 28,
		// 		},
		// 		"&$circle": {
		// 			width: 8,
		// 			height: 8,
		// 			borderRadius: "50%",
		// 		},
		// 		"&$completed": {
		// 			color: "red",
		// 			fontSize: 24,
		// 		},
		// 	},
		// },
	},
});

export const GlobalStyle = createGlobalStyle`

	* {
		caret-color: ${skyscendPink};
	}

	
	html,
	body,
	#root {
		background-color: ${background};
		color: ${blackText};

		display: flex;
		justify-content: center;
		align-items: center;
		
		height: 100%;
		width: 100%;

		& > .col {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.selector-modal {
	max-width: 40rem;
	padding-bottom: 30px;
	padding-top: 30px;
	}

	
	.data-display {
		width: fit-content;
		padding: 5px 3px;

		display: flex;
		flex-direction: column;

		margin-bottom: 0.5rem;

		span:first-child {
			font-size: 0.95rem;
			font-weight: 700;
			color: #222222dd;
			margin-bottom: 0.2rem;
		}
		span:last-child {
			font-size: 1.1rem;
		}
	}
	
	// Slightly animated focus outlines
	@media(prefers-reduced-motion:no-preference){
		:focus{
			transition:outline-offset .1s ease;
			outline-offset: 3px;
		}
	}

	.react-datepicker__input-container {
		width: inherit;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

`;

// Select Styles

export const multiSelectStyle = {
	control: (base) => ({
		...base,
		minHeight: 38,
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: "0 5px",
	}),
	input: (provided) => ({
		...provided,
		margin: "0px",
	}),
	indicatorSeparator: () => ({
		display: "none",
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: "35px",
	}),
	multiValue: (styles) => ({
		...styles,
		backgroundColor: skyscendBlue,
	}),
	multiValueLabel: (styles) => ({
		...styles,
		color: whiteText,
		fontSize: "100%",
		fontWeight: 600,
		paddingRight: 5,
	}),
	multiValueRemove: (styles) => ({
		...styles,
		"&:hover": { backgroundColor: skyscendPink, color: whiteText },
	}),
	menu: (provided) => ({ ...provided }),
	placeholder: () => ({
		color: "black",
		opacity: 0.42,
		fontSize: "0.9rem",
		letterSpacing: 0.3,
		marginLeft: "0.45rem",
		marginTop: "0.15rem",
	}),
};
