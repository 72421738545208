import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import {} from "styled-components/macro";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { Label, PaperBox, RequiredStar } from "../../../styles/styles";

export default ({ orgId, ewayBillData, setEwayBillData, handleBack, handleNext }) => {
	const history = useHistory();

	useEffect(() => {
		// console.log("Step 2 Entry: ", ewayBillData);
	}, [ewayBillData]);

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {
			...ewayBillData,
		},
	});

	const NextStep = (data) => {
		// console.log(data);

		const dispatchInfo = {
			name: data.dispatchName,
			addressLine1: data.dispatchAddressLine1,
			addressLine2: data.dispatchAddressLine2,
			city: data.dispatchCity,
			state: data.dispatchState,
			pin: data.dispatchPostalCode,
		};

		_.mapKeys(dispatchInfo, (value, key) => {
			if (_.isEmpty(value)) delete dispatchInfo[key];
		});
		setEwayBillData({ ...ewayBillData, dispatchInfo });
		// console.log("Step 2 Exit: ", { ...ewayBillData });
		handleNext();
	};

	const handleCancel = () => {
		history.push("/eWayBills");
	};

	const handleSkip = () => {
		handleNext();
	};

	return (
		<>
			<Form onSubmit={handleSubmit(NextStep)}>
				<Box component={Grid} mb={5} container justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							onClick={handleBack}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleSkip}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Skip
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>

				<PaperBox rounded>
					<Typography variant="h6" gutterBottom>
						Dispatch Info
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Form.Group controlId="dispatchName">
								<Label>
									<span>
										Name <RequiredStar />{" "}
									</span>
									{errors.dispatchName && (
										<p className="form-error-inline">
											{errors.dispatchName.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="dispatchName"
									type="text"
									defaultValue={
										ewayBillData.dispatchInfo !== undefined &&
										ewayBillData.dispatchInfo.name !== undefined
											? ewayBillData.dispatchInfo.name
											: ""
									}
									ref={register({ required: "Required" })}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="dispatchAddressLine1">
								<Label>
									<span>
										Address Line 1 <RequiredStar />
									</span>
									{errors.dispatchAddressLine1 && (
										<p className="form-error-inline">
											{errors.dispatchAddressLine1.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="dispatchAddressLine1"
									type="text"
									defaultValue={
										ewayBillData.dispatchInfo !== undefined &&
										ewayBillData.dispatchInfo.addressLine1 !== undefined
											? ewayBillData.dispatchInfo.addressLine1
											: ""
									}
									ref={register({ required: "Required" })}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="dispatchAddressLine2">
								<Label>
									<span>Address Line 2</span>
								</Label>
								<Form.Control
									name="dispatchAddressLine2"
									type="text"
									defaultValue={
										ewayBillData.dispatchInfo !== undefined &&
										ewayBillData.dispatchInfo.addressLine2 !== undefined
											? ewayBillData.dispatchInfo.addressLine2
											: ""
									}
									ref={register()}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="dispatchCity">
								<Label>
									<span>
										City <RequiredStar />
									</span>
									{errors.dispatchCity && (
										<p className="form-error-inline">
											{errors.dispatchCity.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="dispatchCity"
									type="text"
									defaultValue={
										ewayBillData.dispatchInfo !== undefined &&
										ewayBillData.dispatchInfo.city !== undefined
											? ewayBillData.dispatchInfo.city
											: ""
									}
									ref={register({ required: "Required" })}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="dispatchState">
								<Label>
									<span>
										State <RequiredStar />
									</span>
									{errors.dispatchState && (
										<p className="form-error-inline">
											{errors.dispatchState.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="dispatchState"
									type="text"
									defaultValue={
										ewayBillData.dispatchInfo !== undefined &&
										ewayBillData.dispatchInfo.state !== undefined
											? ewayBillData.dispatchInfo.state
											: ""
									}
									ref={register({ required: "Required" })}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="dispatchPostalCode">
								<Label>
									<span>
										Postal Code <RequiredStar />
									</span>
									{errors.dispatchPostalCode && (
										<p className="form-error-inline">
											{errors.dispatchPostalCode.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="dispatchPostalCode"
									type="text"
									defaultValue={
										ewayBillData.dispatchInfo !== undefined &&
										ewayBillData.dispatchInfo.pin !== undefined
											? ewayBillData.dispatchInfo.pin
											: ""
									}
									ref={register({
										required: "Required",
										pattern: {
											value: /^\d*$/,
											message: "Invalid",
										},
									})}
								/>
							</Form.Group>
						</Grid>
					</Grid>
				</PaperBox>

				<Typography variant="body1" gutterBottom color="secondary">
					Note: Skip this section, If there is no Dispatch Info.
				</Typography>

				<Box component={Grid} container mt={5} mb={3} justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							onClick={handleBack}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleSkip}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Skip
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>
			</Form>
		</>
	);
};
