import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { PaperBox } from "../../../../styles/styles";
import {} from "styled-components/macro";

const OrganisationDetails = (props) => {
	const { formValues } = props;
	const {
		orgName,
		contactName,
		contactNumber,
		contactEmail,
		region,
		addressLine1,
		addressLine2,
		city,
		state,
		country,
		postalCode,
		orgPanNumber,
		orgGstNumber,
		lastYearRevenue,
		avgRevenueForLast3yrs,
		yearOfIncorporation,
		numberOfEmployee,
		numberOfInvoicesPerMonth,
	} = formValues;

	return (
		<Grid item container direction="column" xs={12} sm={6}>
			<PaperBox rounded>
				<Typography variant="h6" gutterBottom>
					General Information
				</Typography>
				<Grid container spacing={2}>
					<>
						<Grid item xs={6} className="data-display">
							<span>Org. Name</span>
							<span>{orgName || "N/A"}</span>
						</Grid>
						<Grid item xs={6} className="data-display">
							<span>Contact Name</span>
							<span>{contactName || "N/A"}</span>
						</Grid>
					</>
					<>
						<Grid item xs={6} className="data-display">
							<span>Contact Number</span>
							<span>{contactNumber || "N/A"}</span>
						</Grid>
						<Grid item xs={6} className="data-display">
							<span>Region</span>
							<span>{region || "N/A"}</span>
						</Grid>
					</>
					<>
						<Grid item xs={6} className="data-display">
							<span>Contact Email</span>
							<span>{contactEmail || "N/A"}</span>
						</Grid>
						<Grid item xs={6} className="data-display">
							<span>Address Line 1</span>
							<span>
								<div>{addressLine1 || ""}</div>
								<div>{addressLine2 || ""}</div>
								<div>
									{city || "N/A"}, {state || "N/A"} - {postalCode || "N/A"}
								</div>
								<div>{country || "N/A"}</div>
							</span>
						</Grid>
					</>
				</Grid>
			</PaperBox>
			<PaperBox rounded>
				<Typography variant="h6" gutterBottom>
					Organisation Details
				</Typography>
				<Grid container spacing={2}>
					<>
						<Grid item xs={6} className="data-display">
							<span>PAN Number</span>
							<span>{orgPanNumber || "N/A"}</span>
						</Grid>
						<Grid item xs={6} className="data-display">
							<span>GST Number</span>
							<span>{orgGstNumber || "N/A"}</span>
						</Grid>
					</>
					<>
						<Grid item xs={6} className="data-display">
							<span>Last Year&apos;s Revenue</span>
							<span>{`₹ ${lastYearRevenue}` || "N/A"}</span>
						</Grid>
						<Grid item xs={6} className="data-display">
							<span>Avg. Revenue For Last 3 yrs</span>
							<span>{`₹ ${avgRevenueForLast3yrs}` || "N/A"}</span>
						</Grid>
					</>

					<>
						<Grid item xs={6} className="data-display">
							<span>Number Of Invoices Per Month</span>
							<span>{numberOfInvoicesPerMonth || "N/A"}</span>
						</Grid>
						<Grid item xs={6} className="data-display">
							<span>Number Of Employees</span>
							<span>{numberOfEmployee || "N/A"}</span>
						</Grid>
					</>
					<>
						<Grid item xs={6} className="data-display">
							<span>Year Of Incorporation</span>
							<span>{yearOfIncorporation || "N/A"}</span>
						</Grid>
					</>
				</Grid>
			</PaperBox>
		</Grid>
	);
};

export default OrganisationDetails;
