import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Stepper, Step, StepLabel, Button, CircularProgress, Container } from "@material-ui/core";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";

import { defaultErrorToast } from "../../../styles/global";
import formInitialValues from "./FormModel/FormInitialValues";
import FormValidationSchema from "./FormModel/FormValidationSchema";
import useStyles from "./styles";
import OrganisationForm from "./Forms/OrganisationForm";
import UserForm from "./Forms/UserForm";
import FormModel from "./FormModel/FormModel";
import ReviewDetails from "./Review/ReviewDetails";
import { registerOrgDetails } from "../../../services/organizationService";
import { Titler } from "../../common/Titler";

import {} from "styled-components/macro";

const { formId, formField } = FormModel;
const steps = ["Organization details", "User Details", "Review Details"];

const renderStepContent = (setRegStep, step) => {
	switch (step) {
		case 0:
			setRegStep(0);
			return <OrganisationForm formField={formField} />;
		case 1:
			setRegStep(1);
			return <UserForm formField={formField} />;
		case 2:
			setRegStep(2);
			return <ReviewDetails />;
		default:
			return <div>Not Found</div>;
	}
};

export default function RegistrationPage({ setRegStep }) {
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const currentValidationSchema = FormValidationSchema[activeStep];
	const isLastStep = activeStep === steps.length - 1;
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const submitForm = async (values, actions) => {
		const {
			firstName,
			lastName,
			email,
			phNum,
			phCountryCode,
			termsAccepted,
			userActive,
			userRole,
		} = values;
		const user = {
			firstName,
			lastName,
			email,
			phNum,
			phCountryCode,
			termsAccepted,
			userActive,
			userRole,
		};
		const {
			orgName,
			contactName,
			contactNumber,
			contactEmail,
			region,
			addressLine1,
			addressLine2,
			city,
			state,
			country,
			postalCode,
			orgPanNumber,
			orgGstNumber,
			lastYearRevenue,
			avgRevenueForLast3yrs,
			yearOfIncorporation,
			numberOfEmployee,
			numberOfInvoicesPerMonth,
		} = values;

		const org = {
			orgName,
			contactName,
			contactNumber,
			contactEmail,
			region,
			addressLine1,
			addressLine2,
			city,
			state,
			country,
			postalCode,
			orgPanNumber,
			orgGstNumber,
			lastYearRevenue,
			avgRevenueForLast3yrs,
			yearOfIncorporation,
			numberOfEmployee,
			numberOfInvoicesPerMonth,
		};
		await registerOrgDetails(org, user);
		actions.setSubmitting(false);
		setActiveStep(activeStep + 1);
	};

	const handleSubmit = async (values, actions) => {
		try {
			if (isLastStep) {
				await submitForm(values, actions);
				enqueueSnackbar("Your Organization successfully created", {
					variant: "success",
				});
				history.push("/");
			} else {
				setActiveStep(activeStep + 1);
				actions.setTouched({});
				actions.setSubmitting(false);
			}
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	return (
		<Container
			css={`
				padding-top: 2rem;
				padding-bottom: 2rem;
			`}
		>
			<Titler title="Signup" />
			<Stepper
				activeStep={activeStep}
				css={`
					margin-bottom: 3rem;
					padding: 0;
					
				`}
			>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<>
				{activeStep === steps.length ? (
					<div>review details before submit</div>
				) : (
					<Formik
						initialValues={formInitialValues}
						validationSchema={currentValidationSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form id={formId}>
								{renderStepContent(setRegStep, activeStep)}
								<div
									className={classes.buttons}
									css={`
										/* margin-top: ${activeStep === steps.length - 1 &&
										"-50em"}; */
									`}
								>
									{activeStep !== 0 && (
										<Button
											onClick={handleBack}
											disabled={activeStep === 0}
											className={classes.button}
										>
											Back
										</Button>
									)}
									<div className={classes.wrapper}>
										<Button
											disabled={isSubmitting}
											type="submit"
											variant="contained"
											color="primary"
											className={classes.button}
											disableElevation
										>
											{activeStep === steps.length - 1 ? "Finish" : "Next"}
										</Button>
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</div>
								</div>
							</Form>
						)}
					</Formik>
				)}
			</>
		</Container>
	);
}
