import React from "react";
import { useFormikContext } from "formik";
import { Grid } from "@material-ui/core";
import OrganisationDetails from "./OrganisationDetails";
import UserDetails from "./UserDetails";

const ReviewDetails = () => {
	const { values: formValues } = useFormikContext();

	return (
		<>
			<Grid container spacing={2}>
				<OrganisationDetails formValues={formValues} />
				<UserDetails formValues={formValues} />
			</Grid>
		</>
	);
};

export default ReviewDetails;
