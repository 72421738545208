import _ from "lodash";

function paginate(items, pageNumber, pageSize) {
	const startIndex = pageNumber * pageSize;
	return _(items).slice(startIndex).take(pageSize).value();
}

function sort(items, sortBy) {
	const id = [];
	const direction = [];

	sortBy.forEach((element) => {
		id.push(element.id);
		direction.push(element.desc ? "desc" : "asc");
	});

	return _.orderBy(items, id, direction);
}

function reject(items, deleteObj) {
	return _.reject(items, deleteObj);
}

export { paginate, sort, reject };
