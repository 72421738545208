import React, { useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "../../styles/userList.scss";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Box, Button, Container, Grid, IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { useSnackbar } from "notistack";
import { ErrorBox, PaperBase, PaperBox } from "../../styles/styles";
import {
	getActiveOrgCustomerList,
	getInactiveOrgCustomerList,
	deactivateOrgCustomer,
	activateOrgCustomer,
} from "../../services/customerManagementService";
import ServerTable from "../common/ServerTable";
import { defaultErrorToast } from "../../styles/global";
import { useProfile } from "../../hooks/profile";
import {} from "styled-components/macro";

export const CustomerList = () => {
	const { orgId } = useProfile();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	const [customerListData, setCustomerListData] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);
	const [customerListError, SetCustomerListError] = useState();

	const [inactiveCustomerListData, setInactiveCustomerListData] = useState([]);
	const [inactivePageCount, setInactivePageCount] = useState(0);
	const [inactiveTotalRecords, setInactiveTotalRecords] = useState(0);
	const [inactiveCustomerListError, SetInactiveCustomerListError] = useState();

	const history = useHistory();
	const fetchActiveCustomerListData = useCallback(
		async ({ pageIndex, pageSize, sortBy }) => {
			setLoading(true);
			const { data } = await getActiveOrgCustomerList(
				orgId,
				pageIndex * pageSize,
				pageSize,
				sortBy
			);
			// console.log("Active Customer List Data: ", data);
			if (data !== undefined) {
				if (data.total === 0) SetCustomerListError("No Customers Found");

				setCustomerListData(data.customers);
				let pageCnt = parseInt(data.total / pageSize, 10);
				if (data.total % pageSize !== 0) {
					pageCnt += 1;
				}
				setPageCount(pageCnt);
				setTotalRecords(data.total);
			} else SetCustomerListError("No Customers Found");
			setLoading(false);
		},
		[orgId]
	);

	const fetchInactiveCustomerListData = useCallback(
		async ({ pageIndex, pageSize, sortBy }) => {
			setLoading(true);
			const { data } = await getInactiveOrgCustomerList(
				orgId,
				pageIndex * pageSize,
				pageSize,
				sortBy
			);
			// console.log("Inactive Customer List Data: ", data);
			if (data !== undefined) {
				if (data.total === 0) SetInactiveCustomerListError("No Inactive Customers Found");

				setInactiveCustomerListData(data.customers);
				let pageCnt = parseInt(data.total / pageSize, 10);
				if (data.total % pageSize !== 0) {
					pageCnt += 1;
				}
				setInactivePageCount(pageCnt);
				setInactiveTotalRecords(data.total);
			} else SetInactiveCustomerListError("No Inactive Customers Found");
			setLoading(false);
		},
		[orgId]
	);

	const renderExpanded = useCallback(({ data }) => <CustomerExtraData data={data} />, []);

	const activateCustomer = async (data) => {
		try {
			// console.log("data", data);
			await activateOrgCustomer(orgId, data.original.id);
			enqueueSnackbar("Customer Activated Successfully", {
				variant: "success",
			});

			history.push("/customermgmt");
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const deactivateCustomer = async (data) => {
		try {
			// console.log("data", data);
			await deactivateOrgCustomer(orgId, data.data.id);
			enqueueSnackbar("Customer De-activated Successfully", {
				variant: "success",
			});
			history.push("/customermgmt");
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const columns = useMemo(
		() => [
			{
				id: "userExpander",
				Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
					<IconButton
						aria-label="expand row"
						size="small"
						{...getToggleAllRowsExpandedProps({
							title: "Expand All",
						})}
					>
						{isAllRowsExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				),
				Cell: ({ row }) =>
					row.isExpanded ? (
						<KeyboardArrowUpIcon
							aria-label="expand row"
							title="Expand"
							css={`
								opacity: 0.7;
								margin-left: 0.25rem;
							`}
						/>
					) : (
						<KeyboardArrowDownIcon
							aria-label="collapse row"
							title="Collapse"
							css={`
								opacity: 0.7;
								margin-left: 0.25rem;
							`}
						/>
					),
				isExpander: true,
			},
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "GSTN",
				accessor: "gstn",
			},
			{
				Header: "Email",
				accessor: "email",
			},

			{
				id: "editButtonColumn",
				Cell: ({ row }) => (
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={<EditIcon />}
						disableFocusRipple
						onClick={() =>
							history.push(`/customermgmt/customer/modify/${row.original.id}`)
						}
					>
						Edit
					</Button>
				),
				disableSorter: true,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history]
	);

	const inactiveColumns = useMemo(
		() => [
			{
				Header: "Name",
				accessor: "name",
			},

			{
				Header: "GSTN",
				accessor: "gstn",
			},
			{
				Header: "Email",
				accessor: "email",
			},

			{
				id: "actions",
				Cell: ({ row }) => (
					<>
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={() => {
								activateCustomer(row);
							}}
							startIcon={<CheckIcon />}
							disableFocusRipple
						>
							Activate
						</Button>
					</>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const CustomerExtraData = (data) => {
		// console.log("Expanded Data:", data);
		const {
			data: {
				name,
				gstn,
				addressLine1,
				addressLine2,
				city,
				state,
				country,
				postalCode,
				phoneNumber,
				email,
				active,
			},
		} = data;

		return (
			<PaperBox>
				<Grid
					container
					justify="space-between"
					css={`
						margin-bottom: 28px;
					`}
				>
					<Grid item>
						<Typography
							variant="h5"
							css={`
								text-transform: capitalize;
								font-weight: 600;
							`}
						>
							{name}
						</Typography>
					</Grid>
					<Grid item>
						<Grid container spacing={2}>
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									size="small"
									onClick={() => {
										deactivateCustomer(data);
									}}
									startIcon={<CloseIcon />}
									disableElevation
								>
									Deactivate
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid item xs={12} md={6} className="data-display">
						<span>GSTN</span>
						<span>{gstn || "—"}</span>
					</Grid>

					<Grid item xs={12} md={6} className="data-display">
						<span>Status</span>
						<span>{active === true ? "Active" : "Inactive"}</span>
					</Grid>

					<Grid item xs={12} md={6} className="data-display">
						<span>Phone Number</span>
						<span>{phoneNumber || "—"}</span>
					</Grid>

					<Grid item xs={12} md={6} className="data-display">
						<span>Email</span>
						<span>{email || "—"}</span>
					</Grid>

					<Grid item xs={6}>
						<h5
							css={`
								font-size: 0.95rem;
								font-weight: 700;
								color: #222222dd;
							`}
						>
							Address
						</h5>
					</Grid>

					<Grid
						item
						container
						xs={6}
						direction="column"
						css={`
							font-size: 1.1rem;
							margin-bottom: 13px;
						`}
					>
						{addressLine1 && <Grid item>{addressLine1}</Grid>}
						{addressLine2 && <Grid item>{addressLine2}</Grid>}
						{city && <Grid item>{city}</Grid>}
						{state && <Grid item>{state}</Grid>}
						{country && postalCode && (
							<Grid item>
								{country || ""} {country && postalCode && " - "} {postalCode || ""}
							</Grid>
						)}
					</Grid>
				</Grid>
			</PaperBox>
		);
	};

	return (
		<Container>
			<PaperBase>
				<Box component={Grid} mb={3} container justify="space-between">
					<Typography variant="h5" component="h1">
						Active Customer List
					</Typography>
					<Button
						variant="contained"
						onClick={() => {
							history.push("/customermgmt/customer/add");
						}}
						color="primary"
						size="small"
						startIcon={<PersonAddIcon />}
						disableFocusRipple
					>
						Add New Customer
					</Button>
				</Box>
				{customerListError ? (
					<ErrorBox>{customerListError}</ErrorBox>
				) : (
					<ServerTable
						columns={columns}
						data={customerListData}
						fetchData={fetchActiveCustomerListData}
						loading={loading}
						pageCount={pageCount}
						totalRecords={totalRecords}
						renderExpanded={renderExpanded}
					/>
				)}
			</PaperBase>

			<PaperBase gap>
				<Typography variant="h5" component="h1" gutterBottom>
					Inactive Customer List
				</Typography>
				{inactiveCustomerListError ? (
					<ErrorBox>{inactiveCustomerListError}</ErrorBox>
				) : (
					<ServerTable
						columns={inactiveColumns}
						data={inactiveCustomerListData}
						fetchData={fetchInactiveCustomerListData}
						loading={loading}
						pageCount={inactivePageCount}
						totalRecords={inactiveTotalRecords}
					/>
				)}
			</PaperBase>
		</Container>
	);
};

export default CustomerList;
