import React, { useState } from "react";
import { Container, useMediaQuery, Tabs, Tab } from "@material-ui/core";
import { Titler } from "../common/Titler";
import { PaperBase } from "../../styles/styles";
import {} from "styled-components/macro";
import AdminOrganizations from "./Organizations";
import UserResendInvitation from "./UserResendInvitation";

const TabPanelFunction = ({ children, value, index, ...other }) => (
	<div
		role="tabpanel"
		css={`
			display: ${value !== index && "none"};
			margin-top: 1rem;
		`}
		id={`eInvoices-panel-${index}`}
		aria-labelledby={`eInvoices-tab-${index}`}
		{...other}
	>
		{children}
	</div>
);

const TabPanel = React.memo(TabPanelFunction);

export const AdminMainMenu = () => {
	// Tabs
	const [value, setValue] = useState(0);

	const a11yProps = (index) => ({
		id: `admin-tab-${index}`,
		"aria-controls": `admin-panel-${index}`,
	});

	// Table Responsiveness Checkers:
	const isBelowLg = useMediaQuery("(max-width:1200px)");

	return (
		<Container disableGutters>
			<PaperBase>
				<Titler title="Admin" />
				<>
					<Tabs
						value={value}
						onChange={(event, newValue) => setValue(newValue)}
						indicatorColor="primary"
						textColor="primary"
						aria-label="admin tabs"
						variant="scrollable"
						scrollButtons={isBelowLg ? "on" : "off"}
						css={`
							margin-bottom: 28px;
						`}
					>
						<Tab
							className="smaller-tab"
							label="Active Organizations"
							{...a11yProps(0)}
						/>
						<Tab
							className="smaller-tab"
							label="Pending Organizations"
							{...a11yProps(1)}
						/>
						<Tab className="smaller-tab" label="Resend Invitation" {...a11yProps(2)} />
					</Tabs>

					<TabPanel value={value} index={0}>
						<AdminOrganizations status="true" />
					</TabPanel>

					<TabPanel value={value} index={1}>
						<AdminOrganizations status="false" />
					</TabPanel>

					<TabPanel value={value} index={2}>
						<UserResendInvitation />
					</TabPanel>
				</>
			</PaperBase>
		</Container>
	);
};
export default AdminMainMenu;
