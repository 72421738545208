import React, { useCallback, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Navbar, Nav, Form, FormControl, Button, OverlayTrigger, Popover } from "react-bootstrap";

import { Menu, MenuItem, Divider, Button as MuiButton } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { FaSearch, FaBell } from "react-icons/fa";

import skyscendPayLogo from "../../../images/skyscend-pay.png";

import "../../../styles/navbar-top.scss";
import { useUser } from "../../../hooks/user";
import SelectOrgIdModal from "./SelectOrgIdModal";
import { useProfile } from "../../../hooks/profile";
import {} from "styled-components/macro";
import { getOrgProfile } from "../../../services/profileService";

export const TopBar = () => {
	const { logout } = useUser();
	const { firstName, orgId, selectedOrgId, selectedOrgName, setProfile, userId } = useProfile();

	const selectedOrgPopover = (
		<Popover id="selectedOrgNamePopover" className="mt-2">
			<Popover.Title as="h3">{selectedOrgName}</Popover.Title>
		</Popover>
	);

	// Profile Menu
	const [anchorElProfile, setAnchorElProfile] = React.useState(null);

	const handleProfileClick = (event) => {
		setAnchorElProfile(event.currentTarget);
	};

	const handleProfileClose = () => {
		setAnchorElProfile(null);
	};

	// Support Menu
	const [anchorElSupport, setAnchorElSupport] = React.useState(null);

	const handleSupportClick = (event) => {
		setAnchorElSupport(event.currentTarget);
	};

	const handleSupportClose = () => {
		setAnchorElSupport(null);
	};

	const history = useHistory();

	// OrgId + OrgName Selector
	const orgSelector = useCallback(
		async (selectedOrgId, selectedOrgName, selectedUserRole) => {
			let orgactive = false;
			let orgsuperadmin = false;

			try {
				const orgData = await getOrgProfile(selectedOrgId);
				if (
					orgData !== null &&
					orgData !== undefined &&
					orgData.data !== undefined &&
					orgData.data.active === true
				) {
					orgactive = true;

					if (orgData.data.isSuperAdmin === true) orgsuperadmin = true;
				}
			} catch (e) {
				// console.log("error getting org details.");
			}

			setProfile((p) => ({
				...p,
				selectedOrgId,
				selectedOrgName,
				orgId: selectedOrgId,
				orgName: selectedOrgName,
				userRole: selectedUserRole.join(","),
				orgActive: orgactive,
				orgSuperAdmin: orgsuperadmin,
			}));

			if (orgsuperadmin === true) history.push("/geis/admin");
			else if (orgactive === false) history.push("/stripe/payment");
			else history.push("/");
		},
		[setProfile, history]
	);

	// // Org Selector Modal Parameters
	const [showOrgSearchModal, setShowOrgModal] = useState(false);
	const handleOrgModalClose = useCallback(() => setShowOrgModal(false), []);
	const handleOrgModalShow = useCallback(() => setShowOrgModal(true), []);

	const globalSearch = () => {
		const searchValue = document.getElementById(`topbarSearchField`).value;
		if (searchValue !== null && searchValue !== undefined) {
			const newSearchValue = searchValue.replaceAll("/", "");
			history.push(`/global/search/${newSearchValue}`);
		}
	};

	return (
		<>
			{userId !== undefined && showOrgSearchModal && (
				<SelectOrgIdModal
					userId={userId}
					orgId={orgId}
					selectedOrgId={selectedOrgId}
					orgSelector={orgSelector}
					showOrgSearchModal={showOrgSearchModal}
					handleClose={handleOrgModalClose}
				/>
			)}

			{
				<Navbar expand="lg" fixed="top" className="topbar">
					<Navbar.Brand className="ml-sm-2 mr-sm-4">
						<NavLink id="topbarLogoLink" to="/">
							<img
								src={skyscendPayLogo}
								height="48"
								className="d-inline-block align-middle mr-3"
								alt="Skyscend Pay Logo"
								style={{ marginTop: "-.25rem" }}
							/>
							<span style={{ fontSize: "1.15rem" }}>Skyscend e-invoice</span>
						</NavLink>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" id="topbarCollapseToggle" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Form inline className="ml-auto mr-sm-4">
							<FormControl
								type="text"
								id="topbarSearchField"
								placeholder="Enter Document ID"
							/>
							<Button variant="primary" id="topbarSearchBtn" onClick={globalSearch}>
								<FaSearch />
							</Button>
						</Form>
						<Nav>
							<Nav.Link className="mr-sm-2">
								<FaBell />
							</Nav.Link>
							<OverlayTrigger placement="bottom" overlay={selectedOrgPopover}>
								<Nav.Link onClick={handleOrgModalShow}>
									{selectedOrgName && selectedOrgName.length > 15
										? `${selectedOrgName.substring(0, 15)}...`
										: selectedOrgName || "Select Org"}
								</Nav.Link>
							</OverlayTrigger>

							<MuiButton
								style={{ color: "white", paddingRight: 2, textTransform: "none" }}
								aria-controls="support-menu"
								aria-haspopup="true"
								onClick={handleSupportClick}
							>
								Support
								<ArrowDropDownIcon />
							</MuiButton>

							<Menu
								id="support-menu"
								anchorEl={anchorElSupport}
								open={Boolean(anchorElSupport)}
								onClose={handleSupportClose}
								css={`
									top: 1.5rem !important;
								`}
								disableScrollLock
								keepMounted
							>
								<MenuItem
									onClick={() => {
										window.open(
											"https://newaccount1623384345113.freshdesk.com/support/solutions",
											"_blank"
										);
										handleSupportClose();
									}}
								>
									Help Center
								</MenuItem>
								<Divider light />
								<MenuItem
									onClick={() => {
										window.open(
											"https://newaccount1623384345113.freshdesk.com/support/tickets/new",
											"_blank"
										);
										handleSupportClose();
									}}
								>
									Contact Us
								</MenuItem>
							</Menu>

							<MuiButton
								style={{ color: "white", paddingRight: 2, textTransform: "none" }}
								aria-controls="profile-menu"
								aria-haspopup="true"
								onClick={handleProfileClick}
							>
								Profile <ArrowDropDownIcon />
							</MuiButton>
							<Menu
								id="profile-menu"
								anchorEl={anchorElProfile}
								open={Boolean(anchorElProfile)}
								onClose={handleProfileClose}
								css={`
									top: 1.5rem !important;
								`}
								disableScrollLock
								keepMounted
							>
								<MenuItem readOnly>Hello, {firstName}!</MenuItem>
								<Divider light />
								<MenuItem
									onClick={() => {
										history.push("/profile");
										handleProfileClose();
									}}
								>
									Account Details
								</MenuItem>
								<Divider light />
								<MenuItem onClick={handleProfileClose}>Other</MenuItem>
								<Divider light />
								<MenuItem onClick={logout}>
									Logout <ExitToAppIcon className="ml-2" fontSize="small" />
								</MenuItem>
							</Menu>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			}
		</>
	);
};

export const TopBarSignUp = () => (
	<Navbar expand="lg" className="topbar-signup">
		<Navbar.Brand className="ml-sm-2 mr-sm-4 navbrand-signup">
			<NavLink id="topbarLogoLink" to="/">
				<img
					src={skyscendPayLogo}
					height="40"
					className="d-inline-block align-middle mr-sm-2"
					alt="Skyscend Pay Logo"
				/>{" "}
				Skyscend e-invoice
			</NavLink>
		</Navbar.Brand>
		<Navbar.Toggle aria-controls="basic-navbar-nav" id="topbarCollapseToggle" />
		<Navbar.Collapse id="basic-navbar-nav">
			<Nav className="ml-auto">
				<NavLink to="/login" id="topbarLoginLink" className="mr-sm-2">
					Login
				</NavLink>
			</Nav>
		</Navbar.Collapse>
	</Navbar>
);
