import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { ReloadDismissButtons } from "../styles/styles";

const OnlineChecker = () => {
	const [isOnline, setNetwork] = useState(window.navigator.onLine);
	const updateNetwork = () => {
		setNetwork(window.navigator.onLine);
	};
	useEffect(() => {
		window.addEventListener("offline", updateNetwork);
		window.addEventListener("online", updateNetwork);
		return () => {
			window.removeEventListener("offline", updateNetwork);
			window.removeEventListener("online", updateNetwork);
		};
	}, []);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [wentOffline, setWentOffline] = useState(false);
	useEffect(() => {
		let key;
		if (!isOnline) {
			setWentOffline(true);
			key = enqueueSnackbar("There is no Internet connection!", {
				variant: "error",
				persist: true,
				action: <></>,
			});
		}
		return () => {
			closeSnackbar(key);
		};
	}, [isOnline, enqueueSnackbar, closeSnackbar]);

	useEffect(() => {
		if (wentOffline && isOnline) {
			enqueueSnackbar("Back Online!", {
				variant: "success",
				autoHideDuration: "10000",
				action: (key) => <ReloadDismissButtons key={key} />,
			});
			setWentOffline(false);
		}
	}, [wentOffline, isOnline, enqueueSnackbar, closeSnackbar]);

	return null;
};

export default OnlineChecker;
