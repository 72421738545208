import Amplify, { Auth } from "aws-amplify";
import http from "./httpService";
import awsconfig from "../aws-exports";

const apiUrl = process.env.REACT_APP_API_URL_BASE;

export async function registerUser(userObj, authDetails) {
	try {
		Amplify.configure({
			Auth: {
				// REQUIRED - Amazon Cognito Region
				region: awsconfig.aws_cognito_region,

				// OPTIONAL - Amazon Cognito User Pool ID
				userPoolId: awsconfig.aws_user_pools_id,

				// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
				userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,

				clientMetadata: {
					firstName: userObj.firstName,
					lastName: userObj.lastName,
					mobile: userObj.mobile,

					orgName: userObj.orgName,

					taxId: userObj.taxId,
					asnNumber: userObj.asnNumber || undefined,

					commodityValue: userObj.commodityValue
						? userObj.commodityValue.map((e) => e.value).join(",")
						: undefined,

					region: userObj.regionValue
						? userObj.regionValue.map((e) => e.value).join(",")
						: undefined,

					orgAddress1: userObj.orgAddress.line1,
					orgAddress2: userObj.orgAddress.line2 || undefined,

					city: userObj.city,
					state: userObj.state,
					country: userObj.country,
					postalCode: userObj.postalCode,

					paymentMethod: userObj.paymentMethod || undefined,
				},
			},
		});

		const { user } = await Auth.signUp({
			username: userObj.username,
			password: userObj.password,
			attributes: {
				// orgType: userObj.orgType.value, // optional
				email: userObj.email,
			},
		});

		// console.log(user);
	} catch (error) {
		// console.log("error signing up:", error);
	}
}

export async function checkUser(username) {
	const { data } = await http.get(`${apiUrl}/checkUser?userName=${username}`);
	return data === "Success" ? true : "Username already exists!";
}

export async function checkOrgName(orgName) {
	const { data } = await http.get(`${apiUrl}/checkOrg?orgName=${orgName}`);
	return data === "Success" ? true : "Org. Name already exists!";
}

export async function checkTaxId(taxId) {
	const { data } = await http.get(`${apiUrl}/checkTaxId?taxId=${taxId}`);
	return data === "Success" ? true : "Tax ID already exists!";
}

export async function checkAsnNumber(asnNumber) {
	const { data } = await http.get(`${apiUrl}/checkAsnNumber?asnNumber=${asnNumber}`);
	return data === "Success" ? true : "A.N. ID already exists!";
}

// fbID / googleID Duplicate Check
export async function checkSocialId(socialId, type) {
	const data =
		type === "facebook"
			? await http.get(`${apiUrl}/checkFbID?fbId=${socialId}`)
			: await http.get(`${apiUrl}/checkGoogleID?googleId=${socialId}`);

	return data.data === "Success";
}

// Get Linked Organisations List for an user
export async function getSelectedOrgList(userId) {
	const { idToken } = await Auth.currentSession();

	const url = `${apiUrl}/user/${userId}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

// Accept Terms & Conditions
export async function acceptTermsAndConditions(userId, content, version) {
	const { idToken } = await Auth.currentSession();

	const url = `${apiUrl}/user/${userId}/userTerms`;

	return http.post(
		url,
		{ content, version },
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export default {
	registerUser,
	checkUser,
	checkOrgName,
	checkTaxId,
	checkSocialId,
	checkAsnNumber,
	getSelectedOrgList,
	acceptTermsAndConditions,
};
