/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Grid, Button } from "@material-ui/core";
import { Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Titler } from "../common/Titler";

import { useUser } from "../../hooks/user";
import {} from "styled-components/macro";

// Social features disabled for now
// import FacebookLogin from "react-facebook-login";
// import { GoogleLogin } from "react-google-login";

const Login = ({ switchToPassword, prevLoc }) => {
	const { register, errors, handleSubmit } = useForm();

	const [loginSpinner, setLoginSpinner] = useState(false);
	const [loginError, checkLoginStatus] = useState({
		type: "",
		failed: false,
		message: "",
	});

	const history = useHistory();
	const { login } = useUser();

	const loginSubmit = async (data) => {
		try {
			setLoginSpinner(true);
			checkLoginStatus({ type: "manual", failed: false, message: "" });

			const response = await login(data.loginUsername, data.loginPassword);

			// Check if password is temporary and needs to be changed
			let isFirstTimeLogin;
			if (response.challengeName === "NEW_PASSWORD_REQUIRED")
				isFirstTimeLogin = {
					boolean: true,
					username: data.loginUsername,
					currentPassword: data.loginPassword,
				};

			// Redirect depending on user's previously entered URL
			if (prevLoc) history.push(prevLoc);
			else
				history.push({
					pathname: "/",
					state: { FirstTimeLogin: isFirstTimeLogin },
				});
		} catch (e) {
			// console.log(e);
			// Error Handling
			if (e.code === "NotAuthorizedException")
				checkLoginStatus({
					type: "manual",
					failed: true,
					message: "Incorrect password. Try again.",
				});
			else if (e.code === "UserNotConfirmedException")
				checkLoginStatus({
					type: "manual",
					failed: true,
					message: "User not Confirmed.",
				});
			else if (e.code === "PasswordResetRequiredException")
				checkLoginStatus({
					type: "manual",
					failed: true,
					message: "Password reset required.",
				});
			else if (e.code === "UserNotFoundException")
				checkLoginStatus({
					type: "manual",
					failed: true,
					message: "Email ID not found. Try again.",
				});
			else
				checkLoginStatus({
					type: "manual",
					failed: true,
					message: "Error while logging in. Try again.",
				});
		} finally {
			setLoginSpinner(false);
		}
	};

	// Facebook Login
	// const responseFacebook = async (response) => {
	// 	checkLoginStatus({ type: "facebook", failed: false });

	// 	const resp = await auth.login({ fbID: response.userID });

	// 	if (resp) checkLoginStatus({ type: "facebook", failed: true });
	// 	else history.push("/");
	// };

	// // Google Login
	// const responseGoogle = async (response) => {
	// 	checkLoginStatus({ type: "google", failed: false });

	// 	const resp = await auth.login({ googleID: response.googleId });

	// 	if (resp) checkLoginStatus({ type: "google", failed: true });
	// 	else history.push("/");
	// };

	return (
		<>
			<Grid
				container
				alignItems="baseline"
				css={`
					margin-bottom: 2rem;
				`}
			>
				<span
					css={`
						cursor: default;

						color: ${(props) => props.theme.light.primary};

						font-weight: 700;
						font-size: 2rem;

						margin-right: 15px;
					`}
				>
					Log in
				</span>

				<span
					css={`
						cursor: pointer;

						color: ${(props) => props.theme.light.font.grey};
						font-weight: 500;
						font-size: 1.1rem;

						&:hover {
							text-decoration: underline;
						}
					`}
					onClick={() => history.push("/signup")}
				>
					Sign up
				</span>
			</Grid>
			<form onSubmit={handleSubmit(loginSubmit)}>
				<Titler title="Login" />

				<Form.Group controlId="loginEmail">
					<Form.Control
						name="loginUsername"
						type="email"
						placeholder="example@email.com"
						ref={register({
							required: "Required",
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: "Invalid email address",
							},
						})}
					/>
					{errors.loginUsername && (
						<p className="form-error">{errors.loginUsername.message}</p>
					)}
				</Form.Group>

				<Form.Group controlId="loginPassword">
					<Form.Control
						name="loginPassword"
						type="password"
						placeholder="password"
						ref={register({ required: "Required" })}
					/>
					{errors.loginPassword && (
						<p className="form-error">{errors.loginPassword.message}</p>
					)}
				</Form.Group>

				<span className="span-link" id="passwordResetLink" onClick={switchToPassword}>
					Forgot password?
				</span>
				{loginError.type === "manual" && loginError.failed && (
					<Form.Group>
						<p className="form-error">{loginError.message}</p>
					</Form.Group>
				)}

				<Grid
					container
					css={`
						margin-top: 1.5rem;
					`}
					justify="space-between"
					alignItems="baseline"
				>
					<a href="https://skyscendpay.com/" rel="noopener noreferrer">
						← Back
					</a>
					<Button
						color="primary"
						variant="contained"
						type="submit"
						disabled={loginSpinner}
						disableElevation
					>
						{loginSpinner ? "LOGGING IN" : "LOGIN"}
						{loginSpinner && (
							<Spinner
								animation="border"
								size="sm"
								role="status"
								css={`
									margin-left: 5px;
								`}
							>
								<span className="sr-only">Uploading...</span>
							</Spinner>
						)}
					</Button>
				</Grid>

				{/* Removed Social Signups
				
				<Form.Group className="fb-login-group">
					<FacebookLogin
						id="facebookLoginBtn"
						appId="3270169106411484"
						textButton="LOGIN"
						autoLoad={false}
						size="small"
						callback={responseFacebook}
						onFailure={() => {
							checkLoginStatus({ type: "facebook", failed: true });
						}}
						icon="fa-facebook"
					/>
				</Form.Group>
				{loginError.type === "facebook" && loginError.failed && (
					<Form.Group>
						<p className="form-error">Facebook login failed/does not exist. Try again.</p>
					</Form.Group>
				)}

				<Form.Group className="google-login-group">
					<GoogleLogin
						id="googleLoginBtn"
						clientId="874196241270-h5v6u4mlvnmdpi20e24uda97i7eenlmf.apps.googleusercontent.com"
						buttonText="LOGIN"
						onSuccess={responseGoogle}
						onFailure={() => {
							checkLoginStatus({ type: "google", failed: true });
						}}
						cookiePolicy={"single_host_origin"}
					/>
				</Form.Group>
				{loginError.type === "google" && loginError.failed && (
					<Form.Group>
						<p className="form-error">Google login failed/does not exist. Try again.</p>
					</Form.Group>
				)} */}
			</form>
		</>
	);
};

export default Login;
