import React, { useState, useMemo, useCallback } from "react";
import { Button, Grid } from "@material-ui/core";
import ServerTable from "../common/ServerTable";
import { ErrorBox, SelectorModal } from "../../styles/styles";
import { geteInvoiceList } from "../../services/gisService";

const SelectIRNModal = ({ orgId, irnSelector, showIRNSearchModal, handleClose }) => {
	const [loading, setLoading] = useState(false);
	const [irnData, setIRNData] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);
	const [irnDataError, SetIRNDataError] = useState();

	const columns = useMemo(
		() => [
			{
				Header: "Document ID",
				accessor: "documentDetails.sourceDocId",
			},

			{
				id: "irnActions",
				Cell: ({ row }) => (
					<Button
						style={{ padding: 0 }}
						id="irnSelect"
						color="primary"
						variant="contained"
						onClick={() => {
							irnSelector(row.original.invoiceRefNBR);
							handleClose();
						}}
						disableFocusRipple
						disableElevation
						fullWidth
						size="large"
					>
						Select
					</Button>
				),
				disableSorter: true,
			},
		],
		[irnSelector, handleClose]
	);

	const fetchData = useCallback(
		async ({ pageIndex, pageSize, sortBy }) => {
			try {
				setLoading(true);

				const { data } = await geteInvoiceList(
					orgId,
					pageIndex * pageSize,
					pageSize,
					sortBy
				);

				if (data !== undefined) {
					setIRNData(data.requests);

					let pageCnt = parseInt(data.total / pageSize, 10);

					if (data.total % pageSize !== 0) pageCnt += 1;

					setPageCount(pageCnt);
					setTotalRecords(data.total);
				} else {
					SetIRNDataError(`No IRN  Found`);
				}
			} catch (e) {
				// console.log("Error", e?.response.data);
			}
			setLoading(false);
		},
		[orgId]
	);

	return (
		<SelectorModal
			id="selectIRNModal"
			open={showIRNSearchModal}
			onClose={handleClose}
			title="Select Document ID"
		>
			<Grid container>
				<Grid item xs={12} />
				<Grid item xs={12}>
					{irnDataError ? (
						<ErrorBox>{irnDataError}</ErrorBox>
					) : (
						<ServerTable
							columns={columns}
							data={irnData}
							fetchData={fetchData}
							loading={loading}
							pageCount={pageCount}
							totalRecords={totalRecords}
							defaultRowsPerPage={5}
							defaultSortBy={[{ id: "_id", desc: true }]}
						/>
					)}
				</Grid>
			</Grid>
		</SelectorModal>
	);
};

export default SelectIRNModal;
