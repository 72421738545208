import { Auth } from "aws-amplify";
import moment from "moment";
import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL_BASE;

export async function geteInvoiceStatsByStatus(orgId, fromDate, toDate) {
	const { idToken } = await Auth.currentSession();
	const fromdateStr = moment.unix(fromDate).format("YYYY/MM/DD");
	const todateStr = moment.unix(toDate).format("YYYY/MM/DD");

	let url = apiUrl;
	url += `/org/${orgId}/stats/invoice/status?fromDate=${fromdateStr}&toDate=${todateStr}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function geteInvoiceStatsByBuyer(orgId, fromDate, toDate) {
	const { idToken } = await Auth.currentSession();
	const fromdateStr = moment.unix(fromDate).format("YYYY/MM/DD");
	const todateStr = moment.unix(toDate).format("YYYY/MM/DD");

	let url = apiUrl;
	url += `/org/${orgId}/stats/invoice/buyer?fromDate=${fromdateStr}&toDate=${todateStr}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function geteInvoiceStatsByDate(orgId, fromDate, toDate) {
	const { idToken } = await Auth.currentSession();
	const fromdateStr = moment.unix(fromDate).format("YYYY/MM/DD");
	const todateStr = moment.unix(toDate).format("YYYY/MM/DD");

	let url = apiUrl;
	url += `/org/${orgId}/stats/invoice/date?fromDate=${fromdateStr}&toDate=${todateStr}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function geteeWayBillStatsByStatus(orgId, fromDate, toDate) {
	const { idToken } = await Auth.currentSession();
	const fromdateStr = moment.unix(fromDate).format("YYYY/MM/DD");
	const todateStr = moment.unix(toDate).format("YYYY/MM/DD");

	let url = apiUrl;
	url += `/org/${orgId}/stats/ewaybill/status?fromDate=${fromdateStr}&toDate=${todateStr}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function geteeWayBillStatsByDate(orgId, fromDate, toDate) {
	const { idToken } = await Auth.currentSession();
	const fromdateStr = moment.unix(fromDate).format("YYYY/MM/DD");
	const todateStr = moment.unix(toDate).format("YYYY/MM/DD");

	let url = apiUrl;
	url += `/org/${orgId}/stats/ewaybill/date?fromDate=${fromdateStr}&toDate=${todateStr}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export default {
	geteInvoiceStatsByStatus,
	geteInvoiceStatsByDate,
	geteeWayBillStatsByStatus,
	geteeWayBillStatsByDate,
	geteInvoiceStatsByBuyer,
};
