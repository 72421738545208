import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import moment from "moment";
import {} from "styled-components/macro";
import LaunchIcon from "@material-ui/icons/Launch";
import SearchBox from "./SearchBox";
import ServerTable from "../../../common/ServerTable";
import { downloadDocument, getDocumentList } from "../../../../services/invoiceService";
import { ErrorBox, PaperBox, RefreshButton, Spinner } from "../../../../styles/styles";

export default ({ orgId, justUploaded, refresherRef }) => {
	const [loading, setLoading] = useState(false);

	// Fetched Data
	const [uploadedDocsData, setUploadedDocsData] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);

	// Error Handling
	const [uploadedDocsError, SetUploadedDocsError] = useState();

	// Refresh Table data if selectedOrgId is changed
	useEffect(() => {
		SetUploadedDocsError();
		setUploadedDocsData([]);
	}, [orgId]);

	const columns = useMemo(
		() => [
			{
				Header: "Document #",
				accessor: "rossumDocumentId",
			},
			{
				Header: "Document Name",
				accessor: "documentFileName",
				Cell: ({ row, value }) => {
					// Download Document / Open in New Tab
					const viewDocument = useCallback(async () => {
						const fileURL = await downloadDocument(
							orgId,
							row.original.rossumDocumentId,
							"original"
						);

						window.open(fileURL, "_blank");
					}, [row.original.rossumDocumentId]);
					return (
						<Grid container alignItems="center" wrap="nowrap">
							<span
								css={`
									font-weight: 600;
									margin-right: 0.3rem;
									max-width: 200px;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								`}
								title={value}
							>
								{value}
							</span>
							<IconButton
								aria-label="view document"
								css={`
									padding: 2px;
								`}
								onClick={viewDocument}
								title="view document"
							>
								<LaunchIcon
									css={`
										font-size: 1.1rem;
									`}
								/>
							</IconButton>
						</Grid>
					);
				},
			},
			{
				Header: "Upload Date",
				accessor: "uploadedDate",
				Cell: ({ value }) => {
					if (!value) return "N/A";
					return moment(value).format("YYYY/MM/DD");
				},
			},
			{
				Header: "Uploaded By",
				accessor: "createdName",
				Cell: ({ value }) => (
					<Grid container>
						<span
							css={`
								max-width: 18ch;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							`}
							title={value}
						>
							{value || "N/A"}
						</span>
					</Grid>
				),
			},

			{
				Header: "Status",
				accessor: "status",
				Cell: ({ row }) => {
					switch (row.original.status) {
						case "importing":
							return "Importing";
						case "to_review":
						case "reviewing":
							return "To Validate";
						case "rejected":
							return "Rejected";
						case "extractionFailed":
							return "Exraction Failed";
						case "confirmed":
							return "Validated";
						default:
							return row.original.status;
					}
				},
			},
			{
				Header: "Action",
				Cell: ({ row }) => {
					switch (row.original.status) {
						case "to_review":
						case "reviewing":
							return (
								<Link
									to={{
										pathname: "/eInvoices/invoice/validate",
										state: {
											invoiceId: row.original.id,
											documentId: row.original.rossumDocumentId,
										},
									}}
								>
									Validate Invoice
								</Link>
							);
						default:
							return null;
					}
				},
				disableSorter: true,
			},
		],
		[orgId]
	);

	const fetchuploadedDocsData = useCallback(
		async ({ pageIndex, pageSize, sortBy, isRefreshed }) => {
			setLoading(true);

			if (isRefreshed) setUploadedDocsData([]);

			const { data } = await getDocumentList(orgId, pageIndex * pageSize, pageSize, sortBy);

			console.log("Uploaded Documents List Data: ", data);

			if (data.total !== 0) {
				setUploadedDocsData(data.documents);
				let pageCnt = parseInt(data.total / pageSize, 10);
				if (data.total % pageSize !== 0) {
					pageCnt += 1;
				}
				setPageCount(pageCnt);
				setTotalRecords(data.total);
			} else SetUploadedDocsError("No Uploaded Documents Found");

			setLoading(false);
		},
		[orgId]
	);

	// Force Refresh List Data
	const [refreshingDocList, setDocListRefresh] = useState(false);
	const refresher = useCallback(async () => {
		setDocListRefresh(true);
		await fetchuploadedDocsData({
			pageIndex: 0,
			pageSize: 10,
			sortBy: [],
			isRefreshed: true,
		});
		setDocListRefresh(false);
		refresherRef.current.focus();
	}, [fetchuploadedDocsData, refresherRef]);

	useEffect(() => {
		if (justUploaded) refresher();
		return () => {};
	}, [justUploaded, refresher]);

	return (
		<PaperBox rounded>
			<Box
				component={Grid}
				alignItems="center"
				justifyContent="space-between"
				container
				css={`
					margin-bottom: 28px;
				`}
			>
				<Grid item>
					<Typography variant="h5">Uploaded Documents</Typography>
				</Grid>
				<Grid item>
					<RefreshButton
						variant="outlined"
						onClick={refresher}
						id="refresh"
						disabled={refreshingDocList}
						ref={refresherRef}
						disableFocusRipple
					>
						{!refreshingDocList ? "Refresh List" : "Refreshing "}
						{refreshingDocList && <Spinner size={18} />}
					</RefreshButton>
				</Grid>
			</Box>

			<Box component={Grid} container mb="15px" spacing={2}>
				<Grid item xs={12} md={4}>
					<Select
						id="docListStatusSelect"
						placeholder="Filter Status"
						options={[
							{ value: "", label: "Default: All Status" },
							{ value: "importing", label: "Importing" },
							{ value: "to_review", label: "To Validate" },
							{ value: "confirmed", label: "Validated" },
						]}
						// onChange={handleStatusSelect}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary50: "#a3e6ff",
								primary25: "#d1f3ff",
								primary: "#00aeef",
							},
						})}
					/>
				</Grid>
				<Grid item xs={12} md={8}>
					<SearchBox
					// value={searchQuery}
					// onChange={handleSearch}
					/>
				</Grid>
			</Box>

			{uploadedDocsError ? (
				<ErrorBox>{uploadedDocsError}</ErrorBox>
			) : (
				<ServerTable
					columns={columns}
					data={uploadedDocsData}
					fetchData={fetchuploadedDocsData}
					loading={loading}
					pageCount={pageCount}
					totalRecords={totalRecords}
				/>
			)}
		</PaperBox>
	);
};
