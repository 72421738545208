import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./styles/fonts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.min.css";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { CssBaseline, Grow, IconButton } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import ScrollToTop from "./utils/ScrollToTop";
import { theme, muiTheme, GlobalStyle } from "./styles/global";
import App from "./App";

import OnlineChecker from "./utils/OnlineChecker";
import { UserProvider } from "./hooks/user";

// Add 'close' action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
	notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
	<BrowserRouter>
		<ThemeProvider theme={theme}>
			<MuiThemeProvider theme={muiTheme}>
				<CssBaseline />
				<SnackbarProvider
					maxSnack={4}
					autoHideDuration={6000}
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					TransitionComponent={Grow}
					preventDuplicate
					ref={notistackRef}
					action={(key) => (
						<IconButton
							aria-label="dismiss"
							size="small"
							style={{ color: "white" }}
							onClick={onClickDismiss(key)}
						>
							<CloseIcon />
						</IconButton>
					)}
				>
					<GlobalStyle />
					<ScrollToTop />
					<OnlineChecker />
					<UserProvider>
						<App />
					</UserProvider>
				</SnackbarProvider>
			</MuiThemeProvider>
		</ThemeProvider>
	</BrowserRouter>,
	document.getElementById("root")
);
