import React, { useEffect, useState } from "react";
import { Container, Grid, Button } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { defaultErrorToast } from "../../styles/global";
import { Label, PaperBase, RequiredStar } from "../../styles/styles";
import { getStripeSession } from "../../services/stripeService";
import { createOrgCredentialsForRegistration } from "../../services/credentialSevice";

export const StripeSuccess = () => {
	// const { orgId, orgName } = useProfile();
	const { search } = useLocation();
	const session_id = new URLSearchParams(search).get("session_id");
	const orgId = new URLSearchParams(search).get("orgId");

	const { enqueueSnackbar } = useSnackbar();

	const history = useHistory();
	const [stripeSessionDetails, setStripeSessionDetails] = useState();

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {},
	});

	useEffect(() => {
		(async () => {
			// console.log({ sessionId: session_id });

			try {
				const { data } = await getStripeSession(orgId, session_id);
				// console.log({ stripe_session_details: data });
				setStripeSessionDetails(data);
			} catch (e) {
				setStripeSessionDetails();
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId, session_id]);

	const submitRequest = async (data) => {
		try {
			await createOrgCredentialsForRegistration(orgId, data.apiusername, data.apipassword);
			enqueueSnackbar(
				"Thank you for making the payment and providing API credentials. Skyscend support team will process your account within next 24 hrs",
				{
					variant: "success",
				}
			);

			history.push("/");
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	return (
		<Container>
			{stripeSessionDetails && (
				<>
					<PaperBase>
						<Grid container spacing={20}>
							<Grid item className="data-display" xs={12} md={12}>
								<span>
									<p>
										Your payment was successful. Now please enter GSTIN API
										credentails
									</p>
								</span>
							</Grid>
						</Grid>

						<Form onSubmit={handleSubmit(submitRequest)}>
							<div>
								<Grid container spacing={20}>
									<Grid item container xs={9} direction="row" spacing={2}>
										<Grid item xs={12} md={6}>
											<Form.Group controlId="apiusername">
												<Label>
													<span>
														User Name
														<RequiredStar />
													</span>
													{errors.apiusername && (
														<p className="form-error-inline">
															{errors.apiusername.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="apiusername"
													type="text"
													autoComplete="off"
													defaultValue="User Name"
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="apipassword">
												<Label>
													<span>
														Password
														<RequiredStar />
													</span>
													{errors.apipassword && (
														<p className="form-error-inline">
															{errors.apipassword.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="apipassword"
													type="password"
													autoComplete="off"
													defaultValue="Password"
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>
									</Grid>

									<Grid item xs={12} md={12}>
										<h6
											css={`
												font-size: 0.95rem;
												font-weight: 500;
												color: #222222dd;
											`}
										>
											If you dont have the GSTIN credentials, Click on this{" "}
											<a
												href="https://newaccount1623384345113.freshdesk.com/support/solutions/articles/82000682105-how-to-generate-gstn-api-credentials"
												target="_blank"
												rel="noreferrer"
											>
												link
											</a>{" "}
											and follow the steps to generate one from Govt. Portal
										</h6>
									</Grid>

									<Grid item container justify="space-between">
										<Button
											variant="contained"
											color="primary"
											type="submit"
											disableFocusRipple
										>
											Submit
										</Button>
									</Grid>
								</Grid>
							</div>
						</Form>
					</PaperBase>
				</>
			)}
		</Container>
	);
};

export default StripeSuccess;
