import { Auth } from "aws-amplify";

export async function ResendConfirmation(username) {
	try {
		await Auth.resendSignUp(username);
		return "success";
	} catch (e) {
		return e.message;
	}
}

// Currently Unused
export async function confirmSignUp(username, code) {
	try {
		await Auth.confirmSignUp(username, code);
		// console.log(username, code);
		return "success";
	} catch (error) {
		// console.log("error confirming sign up", error);
		return error.message;
	}
}

export default {
	ResendConfirmation,
	confirmSignUp,
};
