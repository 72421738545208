import { Auth } from "aws-amplify";
import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL_BASE;

// Upload Document to Rossum
export async function uploadDocument(files, orgId) {
	const formData = new FormData();

	files.map((file) => formData.append("files", file));

	const { idToken } = await Auth.currentSession();

	const url = `${apiUrl}/org/${orgId}/document`;

	return http.post(url, formData, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

// Get All (Uploaded) Invoice Documents List
export async function getDocumentList(orgId, pageIndex, pageSize, sortBy) {
	const { idToken } = await Auth.currentSession();

	let url = `${apiUrl}/org/${orgId}/document`;

	url += `?offset=${pageIndex}&size=${pageSize}`;

	if (sortBy?.length)
		sortBy.forEach((item) => {
			url += `&sort=${item.id},${item.desc ? "desc" : "asc"}`;
		});

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

// Download Document - TODO: API Not Ready Yet
export async function downloadDocument(orgId, documentId, state = "original") {
	const { idToken } = await Auth.currentSession();

	const url = `${apiUrl}/org/${orgId}/document/${documentId}/state/${state}/download`;

	const { data } = await http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
		// responseType: "Buffer", // Force type as 'blob'
	});
	const arr = new Uint8Array(data.data);
	const file = new Blob([arr], { type: "application/pdf" });
	// console.log(file);
	const fileURL = URL.createObjectURL(file);
	// console.log(fileURL);
	return fileURL;
}

// Download Document - TODO: API Not Ready Yet
export async function updateDocumentStatus(orgId, documentId, status) {
	const { idToken } = await Auth.currentSession();
	const url = `${apiUrl}/org/${orgId}/document/${documentId}/status/${status}`;
	await http.patch(
		url,
		{},
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export default {
	uploadDocument,
	getDocumentList,
	downloadDocument,
	updateDocumentStatus,
};
