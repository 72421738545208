import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Container, Grid, Typography, LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { loadStripe } from "@stripe/stripe-js";
import { upperCase } from "lodash";
import { defaultErrorToast } from "../../styles/global";
import { useProfile } from "../../hooks/profile";
import { ErrorBox, PaperBase } from "../../styles/styles";
import {
	createStripeSession,
	getActiveSubscription,
	getStripePlans,
} from "../../services/stripeService";
import {} from "styled-components/macro";
import { getOrgProfile } from "../../services/profileService";

export const StripeForm = () => {
	const { orgId } = useProfile();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const [planList, setPlanList] = useState();
	const [loading, setLoading] = useState(false);
	const [errorDetails, setErrorDetails] = useState();
	const [orgActive, setOrgActive] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				const resp = await getActiveSubscription(orgId);
				// console.log(resp);
				if (
					resp !== undefined &&
					resp !== null &&
					resp.data !== undefined &&
					resp.data !== null &&
					resp.data.data !== undefined &&
					resp.data.data !== null
				) {
					if (resp.data.data[0].status === "active") {
						try {
							const orgData = await getOrgProfile(orgId);
							if (
								orgData !== null &&
								orgData !== undefined &&
								orgData.data !== undefined &&
								orgData.data.active === true
							) {
								setOrgActive(true);
								if (orgData.data.isSuperAdmin === true) history.push("/profile");
							} else {
								setErrorDetails(
									"You already have an active subscription. Skyscend support team is processing your account. It may take up to 24 hrs. If you see this msg even after 24 hrs, please contact Support@Skyscend.com"
								);
							}
						} catch (e) {
							setErrorDetails(
								"You already have an active subscription. Skyscend support team is processing your account. It may take up to 24 hrs. If you see this msg even after 24 hrs, please contact Support@Skyscend.com"
							);
						}
					}
				}
			} catch (e) {
				// console.log(e);
				//	setErrorDetails(
				//		"Thank you for registering with Skyscend e-Invoice.  Please Select a Payment Plan."
				//	);
			}

			try {
				setLoading(true);
				const planData = await getStripePlans();
				if (
					planData !== undefined &&
					planData.data !== undefined &&
					planData.data.data !== undefined
				) {
					const result = planData.data.data.filter((item) => item.recurring !== null);
					// console.log(result);
					setPlanList(result);
				}
			} catch (e) {
				setErrorDetails("Error Loading Payment Plans");
			} finally {
				setLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId]);

	const setSelectedValue = async (selectedValue, metered, planName) => {
		try {
			const res = await createStripeSession(
				orgId,
				selectedValue,
				metered === "metered" ? "true" : "false",
				planName
			);

			const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
			const stripe = await stripePromise;
			await stripe.redirectToCheckout({ sessionId: res.data.id });
			// console.log(results);
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	return (
		<Container maxWidth="xl" disableGutters>
			{errorDetails ? (
				<ErrorBox>{errorDetails}</ErrorBox>
			) : (
				<>
					{planList && orgActive === false && (
						<ErrorBox>
							Thank you for registering with Skyscend e-Invoice. Please Select a
							Payment Plan.
						</ErrorBox>
					)}

					<Grid
						container
						justify="space-between"
						alignItems="flex-start"
						css={`
							margin-bottom: 28px;
						`}
					>
						<Grid item>
							<Typography variant="h5" component="h1">
								Payment Plans
							</Typography>
							<Typography variant="body2" component="h1">
								(click card to select)
							</Typography>
						</Grid>

						<Grid item>
							<Button
								variant="outlined"
								color="secondary"
								onClick={() => {
									history.push("/");
								}}
								disableElevation
							>
								Cancel
							</Button>
						</Grid>
					</Grid>

					{loading || !planList ? (
						<LinearProgress />
					) : (
						<div
							css={`
								display: grid;
								gap: 1.25rem;
								grid-template-columns: repeat(3, 1fr);
							`}
						>
							{planList?.map((item, index) => (
								<PaperBase
									css={`
										cursor: pointer;
									`}
									onClick={() => {
										setSelectedValue(
											item.id,
											item.recurring !== null && item.recurring !== undefined
												? item.recurring.usage_type
												: "false",
											item.nickname
										);
									}}
									role="button"
									title="click to select"
									hoverShadow
									p="30px"
								>
									<Box component={Grid} container justify="space-between">
										<Grid
											item
											container
											direction="column"
											css={`
												margin-bottom: 28px;
											`}
										>
											<Typography
												variant="h5"
												css={`
													margin-bottom: 5px;
												`}
											>
												{item.nickname === null
													? `Plan #${index + 1}`
													: item.nickname}
											</Typography>
										</Grid>
										<Grid
											item
											container
											wrap="nowrap"
											alignItems="baseline"
											spacing={1}
											css={`
												margin-bottom: 30px;
											`}
										>
											<Grid item>
												<Typography
													variant="h2"
													css={`
														font-weight: 400;
														font-size: 3.4rem;
														color: #333;
													`}
												>
													{item.currency === "inr" && "₹"}
													{Number(item.unit_amount / 100).toFixed(2)}
												</Typography>
											</Grid>
											<Grid item>
												<Typography
													variant="h6"
													css={`
														font-weight: 600;
														font-size: 1rem;
													`}
												>
													{item.currency !== "inr" &&
														upperCase(item.currency)}
													{item.recurring !== null &&
														` / ${item.recurring.interval}`}
												</Typography>
											</Grid>
										</Grid>

										<Grid
											item
											container
											justify="flex-end"
											css={`
												margin-bottom: 15px;
											`}
										>
											{item.recurring !== null && (
												<Typography
													variant="body2"
													css={`
														text-transform: capitalize;
														/* font-weight: 600; */
														margin-top: -2px;
														margin-bottom: 1px;
														font-weight: 600;
													`}
												>
													{item.recurring.usage_type} Usage
												</Typography>
											)}
										</Grid>
									</Box>
								</PaperBase>
							))}
						</div>
					)}
				</>
			)}
		</Container>
	);
};

export default StripeForm;
