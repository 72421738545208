import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Container, Grid, Typography, Box } from "@material-ui/core";
import { getGSTNDetails } from "../../services/gisService";

export const GSTNDetails = () => {
	const [gstnDetails, setGstnDetails] = useState();
	const history = useHistory();
	const params = useParams();
	const { gstn } = params;

	useEffect(() => {
		(async () => {
			const res = await getGSTNDetails(gstn);
			if (res !== null) {
				setGstnDetails(res.data);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gstn]);

	return (
		<Container>
			<>
				<Typography variant="h5" component="h1" gutterBottom>
					GSTN Details
				</Typography>

				<Grid container spacing={2}>
					<Grid item xs={6} md={3}>
						<Button
							color="secondary"
							onClick={() => {
								history.goBack();
							}}
							css={`
								padding: 0;
								font-weight: 600;
							`}
						>
							Back
						</Button>
					</Grid>
				</Grid>

				{gstnDetails && (
					<Box pt={10}>
						<Grid container spacing={5}>
							<Grid item className="data-display" xs={12} md={6}>
								<span>GSTN</span>
								<span>{gstnDetails.gstin || "N/A"}</span>
							</Grid>
							<Grid item className="data-display" xs={12} md={6}>
								<span>Legal Name</span>
								<span>{gstnDetails.legalName || "N/A"}</span>
							</Grid>
							<Grid item className="data-display" xs={12} md={6}>
								<span>Block Status</span>
								<span>{gstnDetails.blockStatus || "N/A"}</span>
							</Grid>
							<Grid item className="data-display" xs={12} md={6}>
								<span>Status</span>
								<span>{gstnDetails.status || "N/A"}</span>
							</Grid>
							<Grid item className="data-display" xs={12} md={6}>
								<span>Registration Date</span>
								<span>{gstnDetails.registationDt || "N/A"}</span>
							</Grid>
						</Grid>
					</Box>
				)}
			</>
		</Container>
	);
};

export default GSTNDetails;
