import React, { useState } from "react";
import { Container, useMediaQuery, Tabs, Tab } from "@material-ui/core";
import { Titler } from "../../common/Titler";
import { PaperBase } from "../../../styles/styles";
import EInvoiceForm from "./EInvoiceForm";
import PDFUpload from "../upload/pdf/PDFUpload";
import XMLUpload from "../upload/xml/XMLUpload";
import {} from "styled-components/macro";

const TabPanelFunction = ({ children, value, index, ...other }) => (
	<div
		role="tabpanel"
		css={`
			display: ${value !== index && "none"};
			margin-top: 1rem;
		`}
		id={`eInvoices-panel-${index}`}
		aria-labelledby={`eInvoices-tab-${index}`}
		{...other}
	>
		{children}
	</div>
);

const TabPanel = React.memo(TabPanelFunction);

export const GenerateEInvoice = () => {
	// Tabs
	const [value, setValue] = useState(0);

	const a11yProps = (index) => ({
		id: `eInvoices-tab-${index}`,
		"aria-controls": `eInvoices-panel-${index}`,
	});

	// Table Responsiveness Checkers:
	const isBelowLg = useMediaQuery("(max-width:1200px)");

	return (
		<Container disableGutters>
			<PaperBase>
				<Titler title="eInvoices" />
				<>
					<Tabs
						value={value}
						onChange={(event, newValue) => setValue(newValue)}
						indicatorColor="primary"
						textColor="primary"
						aria-label="eInvoices tabs"
						variant="scrollable"
						scrollButtons={isBelowLg ? "on" : "off"}
						css={`
							margin-bottom: 28px;
						`}
					>
						<Tab className="smaller-tab" label="Create eInvoice" {...a11yProps(0)} />
						<Tab className="smaller-tab" label="PDF Upload" {...a11yProps(1)} />
						<Tab className="smaller-tab" label="XML Upload" {...a11yProps(2)} />
					</Tabs>

					<TabPanel value={value} index={0}>
						<EInvoiceForm />
					</TabPanel>

					<TabPanel value={value} index={1}>
						<PDFUpload />
					</TabPanel>

					<TabPanel value={value} index={2}>
						<XMLUpload />
					</TabPanel>
				</>
			</PaperBase>
		</Container>
	);
};
export default GenerateEInvoice;
