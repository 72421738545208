import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Label, PaperBox } from "../../../styles/styles";
import { getActiveOrgCustomerList } from "../../../services/customerManagementService";

export default ({ orgId, invoiceData, setInvoiceData, handleBack, handleNext }) => {
	const history = useHistory();
	const [customerError, setCustomerError] = useState();
	const [customerList, setCustomerList] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		// console.log("Step 2 Entry: ", invoiceData);
		getActiveOrgCustomerList(orgId, 0, 100, null)
			.then((data) => {
				const customerCollection = data.data.customers;
				// console.log("Received Customer List", customerCollection);
				setCustomerList(customerCollection);
			})
			.catch((error) => {
				setCustomerError(`Error: ${error.response?.data.errorDescription}`);
			});
	}, [orgId, invoiceData]);

	const { register, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {
			...invoiceData,
		},
	});

	const NextStep = (data) => {
		// console.log(data);
		setInvoiceData({ ...invoiceData });
		if (invoiceData.customerInfo) {
			// console.log("Step 2 Exit: ", invoiceData);
			handleNext();
		} else {
			enqueueSnackbar(`Please select customer.`, {
				variant: "error",
			});
		}
	};

	const refreshCustomerDetails = (event) => {
		let customerData = {};
		if (event.target.value !== "$$$") {
			customerList.forEach((item) => {
				if (item.name === event.target.value) customerData = item;
			});

			const formattedData = {
				gstn: customerData.gstn,
				legalName: customerData.name,
				tradeName: customerData.name,
				addressLine1: customerData.addressLine1,
				addressLine12: customerData.addressLine2,
				city: customerData.city,
				pin: customerData.postalCode,
				state: customerData.state,
				phone: customerData.phoneNumber,
				email: customerData.email,
			};
			setInvoiceData({ ...invoiceData, customerInfo: formattedData });
		}
	};

	const handleCancel = () => {
		history.push("/eInvoices");
	};

	return (
		<>
			<Form onSubmit={handleSubmit(NextStep)}>
				<Box component={Grid} mb={5} container justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							onClick={handleBack}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>

				<PaperBox rounded>
					<Typography variant="h6" gutterBottom>
						Choose Customer
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Form.Group controlId="selectedCustomer">
								<Label>Customer</Label>
								<Form.Control
									as="select"
									name="selectedCustomer"
									ref={register({ required: "Required" })}
									onChange={refreshCustomerDetails}
									value={
										invoiceData.customerInfo !== undefined
											? invoiceData.customerInfo.legalName
											: ""
									}
								>
									{
										<option key="test" value="$$$">
											Please Select Customer
										</option>
									}
									{customerList.map((item, index) => (
										<option key={item.name} value={item.name}>
											{item.name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
						</Grid>
					</Grid>
				</PaperBox>

				{invoiceData.customerInfo && (
					<PaperBox rounded>
						<Typography variant="h6" gutterBottom>
							Selected Customer Details
						</Typography>
						<Grid container spacing={2}>
							<Grid item className="data-display" xs={12} md={4}>
								<span>Customer Name</span>
								<span>{invoiceData.customerInfo.legalName || "N/A"}</span>
							</Grid>
							<Grid item className="data-display" xs={12} md={4}>
								<span>Customer GSTN</span>
								<span>{invoiceData.customerInfo.gstn || "N/A"}</span>
							</Grid>
							<Grid item className="data-display" xs={12} md={4}>
								<span>Phone</span>
								<span>{invoiceData.customerInfo.phone || "N/A"}</span>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item className="data-display" xs={12} md={4}>
								<span>Address Line 1</span>
								<span>{invoiceData.customerInfo.addressLine1 || "N/A"}</span>
							</Grid>
							<Grid item className="data-display" xs={12} md={4}>
								<span>Address Line 2</span>
								<span>{invoiceData.customerInfo.addressLine2 || "N/A"}</span>
							</Grid>
							<Grid item className="data-display" xs={12} md={4}>
								<span>City</span>
								<span>{invoiceData.customerInfo.city || "N/A"}</span>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item className="data-display" xs={12} md={4}>
								<span>State</span>
								<span>{invoiceData.customerInfo.state || "N/A"}</span>
							</Grid>
							<Grid item className="data-display" xs={12} md={4}>
								<span>Pin</span>
								<span>{invoiceData.customerInfo.pin || "N/A"}</span>
							</Grid>
							<Grid item className="data-display" xs={12} md={4}>
								<span>Email</span>
								<span>{invoiceData.customerInfo.email || "N/A"}</span>
							</Grid>
						</Grid>
					</PaperBox>
				)}

				<Box component={Grid} container mt={5} mb={3} justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							onClick={handleBack}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>
			</Form>
		</>
	);
};
