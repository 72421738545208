import { Auth } from "aws-amplify";
import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL_BASE;

export async function getOrgCredentials(orgId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/credentials`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function updateOrgCredentials(orgId, userName, password) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/credentials`;

	return http.patch(
		url,
		{ userName, password },
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export async function createOrgCredentials(orgId, userName, password) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/credentials`;

	return http.post(
		url,
		{ userName, password },
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export async function createOrgCredentialsForRegistration(orgId, userName, password) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/registration/org/${orgId}/credentials`;

	return http.post(
		url,
		{ userName, password },
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export async function getOrgCredentialsByAdmin(gstn) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/admin/org/gstn/${gstn}/credentials`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export default {
	getOrgCredentials,
	updateOrgCredentials,
	createOrgCredentials,
	createOrgCredentialsForRegistration,
	getOrgCredentialsByAdmin,
};
