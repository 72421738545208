/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Grid, Button } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import OtpInput from "react-otp-input";

import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import { defaultErrorToast } from "../../styles/global";
import { Titler } from "../common/Titler";
import {} from "styled-components/macro";

const ForgotPassword = ({ switchToPassword }) => {
	const { enqueueSnackbar } = useSnackbar();

	const { register, errors, control, handleSubmit } = useForm();

	const history = useHistory();

	const [latestEmail, setLatestEmail] = useState(null);
	const PWSubmit = async (data) => {
		// Send confirmation code to user's email
		Auth.forgotPassword(data.forgotPWemail)
			.then((response) => {
				// console.log(response);
				setLatestEmail(data.forgotPWemail);
				enqueueSnackbar("OTP code has been sent to your email.", {
					variant: "success",
				});
			})
			.catch((err) => console.log(err));
	};

	const OTPSubmit = (data) => {
		// console.log(data);

		// Collect confirmation code and new password, then
		Auth.forgotPasswordSubmit(data.forgotPWemail, data.OTPCode, data.newPassword)
			.then((data) => {
				// console.log(data);
				enqueueSnackbar("Password Changed Successfully", {
					variant: "success",
				});
				history.push("/");
			})
			.catch((err) => {
				// console.log(err);
				enqueueSnackbar(defaultErrorToast, {
					variant: "error",
				});
			});
	};

	return (
		<>
			<Grid
				css={`
					margin-bottom: 2rem;
				`}
			>
				<span
					css={`
						cursor: default;

						color: ${(props) => props.theme.light.primary};

						font-weight: 700;
						font-size: 2rem;

						margin-right: 15px;
					`}
				>
					Reset Password
				</span>
			</Grid>
			<Form className="forgot-password-form" onSubmit={handleSubmit(PWSubmit)}>
				<Titler title="Reset Password" />
				<Form.Group controlId="forgotPasswordEmail">
					<Form.Control
						type="email"
						name="forgotPWemail"
						placeholder="example@email.com"
						ref={register({
							required: "Required",
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: "Invalid email address",
							},
						})}
						className={errors.forgotPWemail && "field-error"}
					/>
					{errors.forgotPWemail && (
						<p className="form-error">{errors.forgotPWemail.message}</p>
					)}
				</Form.Group>
				<Grid container justify="space-between" alignItems="baseline">
					<span
						className="password-reset-nvm span-link"
						id="passwordCancelLink"
						onClick={switchToPassword}
					>
						← Nevermind
					</span>
					<Button color="primary" variant="contained" type="submit" disableElevation>
						SEND
					</Button>
				</Grid>
			</Form>

			{latestEmail && (
				<Form
					className="forgot-password-form"
					style={{ marginTop: 25 }}
					onSubmit={handleSubmit(OTPSubmit)}
				>
					<Titler title="Reset Password" />
					<Form.Group controlId="forgotPasswordOTP">
						<Controller
							name="OTPCode"
							control={control}
							rules={{ required: "Required" }}
							defaultValue=""
							render={(props) => (
								<OtpInput
									numInputs={6}
									onChange={props.onChange}
									value={props.value}
									isInputNum
									separator="-"
									containerStyle="otpContainerStyle"
									inputStyle="otpStyle"
									errorStyle="otpErrorStyle"
									shouldAutoFocus
									hasErrored={errors.OTPCode}
								/>
							)}
						/>
						{errors.OTPCode && <p className="form-error">{errors.OTPCode.message}</p>}
					</Form.Group>
					<Form.Group
						controlId="forgotPasswordNewPassword"
						style={{ marginBottom: "0.5rem" }}
					>
						<Form.Control
							type="password"
							name="newPassword"
							autoComplete="new-password"
							placeholder="New Password"
							ref={register({
								required: "Required",
								pattern: {
									value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
									message: "Invalid Password",
								},
							})}
							className={errors.newPassword && "field-error"}
						/>
						{errors.newPassword && (
							<p className="form-error">{errors.newPassword.message}</p>
						)}
					</Form.Group>
					<Button
						color="primary"
						variant="contained"
						type="submit"
						css={`
							margin-top: 0.5rem;
						`}
						disableElevation
					>
						SET PASSWORD
					</Button>
				</Form>
			)}
		</>
	);
};

export default ForgotPassword;
