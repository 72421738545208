import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Label, PaperBox, RequiredStar } from "../../../../../styles/styles";

export default ({ orgId, documentId, invoiceData, setInvoiceData, handleNext }) => {
	const history = useHistory();

	useEffect(() => {
		// console.log("Step 1 Entry: ", invoiceData);
	}, [invoiceData]);

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {
			...invoiceData,
		},
	});

	const NextStep = (data) => {
		console.log(data);
		const formattedData = {
			...invoiceData,
			...data,
		};

		// console.log("Step 1 Exit: ", formattedData);

		setInvoiceData(formattedData);
		handleNext();
	};

	const handleCancel = () => {
		history.push("/eInvoices");
	};

	return (
		<>
			<Form onSubmit={handleSubmit(NextStep)}>
				<Box component={Grid} mb={5} container justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>

				<PaperBox rounded>
					<Typography variant="h6" gutterBottom>
						Document Details
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Form.Group controlId="supplyType">
								<Label>
									<span>
										Supply Type
										<RequiredStar />
									</span>
									{errors.supplyType && (
										<p className="form-error-inline">
											{errors.supplyType.message}
										</p>
									)}
								</Label>
								<Form.Control
									as="select"
									name="supplyType"
									ref={register({
										required: "Required",
									})}
								>
									<option key="B2B" value="B2B">
										B2B - Business to Business
									</option>
									<option key="SEZWP" value="SEZWP">
										SEZWP - SEZ with payment
									</option>
									<option key="SEZWOP" value="SEZWOP">
										SEZWOP - SEZ without payment
									</option>
									<option key="EXPWP" value="EXPWP">
										EXPWP - Export with Payment
									</option>
									<option key="EXPWOP" value="EXPWOP">
										EXPWOP - Export without payment
									</option>
									<option key="DEXP" value="DEXP">
										EXPWOP - Export without payment
									</option>
								</Form.Control>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="regRev">
								<Label>
									<span>
										Reverse Charge
										<RequiredStar />
									</span>
									{errors.regRev && (
										<p className="form-error-inline">{errors.regRev.message}</p>
									)}
								</Label>
								<Form.Control
									as="select"
									name="regRev"
									ref={register({
										required: "Required",
									})}
								>
									<option key="N" value="N">
										No
									</option>
									<option key="Y" value="Y">
										Yes
									</option>
								</Form.Control>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="igstOnIntra">
								<Label>
									<span>
										IGST on Intra Transfer
										<RequiredStar />
									</span>
									{errors.igstOnIntra && (
										<p className="form-error-inline">
											{errors.igstOnIntra.message}
										</p>
									)}
								</Label>
								<Form.Control
									as="select"
									name="igstOnIntra"
									ref={register({
										required: "Required",
									})}
								>
									<option key="N" value="N">
										No
									</option>
									<option key="Y" value="Y">
										Yes
									</option>
								</Form.Control>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="docType">
								<Label>
									<span>
										Document Type
										<RequiredStar />
									</span>
									{errors.docType && (
										<p className="form-error-inline">
											{errors.docType.message}
										</p>
									)}
								</Label>
								<Form.Control
									as="select"
									name="docType"
									ref={register({
										required: "Required",
									})}
								>
									<option key="Invoice" value="Invoice">
										Invoice
									</option>
									<option key="CreditNote" value="CreditNote">
										CreditNote
									</option>
									<option key="DebitNote " value="DebitNote ">
										DebitNote
									</option>
								</Form.Control>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="sourceDocId">
								<Label>
									<span>
										Source Document Id
										<RequiredStar />
									</span>
									{errors.sourceDocId && (
										<p className="form-error-inline">
											{errors.sourceDocId.message}
										</p>
									)}
								</Label>
								<Form.Control
									ref={register({
										required: "Required",
									})}
									placeholder="N/A"
									name="sourceDocId"
									type="text"
									defaultValue={
										invoiceData !== undefined ? invoiceData.sourceDocId : ""
									}
								/>
							</Form.Group>
						</Grid>
						<Grid item xs={12} md={6}>
							<Form.Group controlId="docDate">
								<Label>
									<span>
										Document Date (DD/MM/YYYY)
										<RequiredStar />
									</span>
									{errors.docDate && (
										<p className="form-error-inline">
											{errors.docDate.message}
										</p>
									)}
								</Label>
								<Form.Control
									ref={register({
										required: "Required",
									})}
									name="docDate"
									type="text"
									defaultValue={
										invoiceData !== undefined ? invoiceData.docDate : ""
									}
								/>
							</Form.Group>
						</Grid>
					</Grid>
				</PaperBox>

				<Box component={Grid} container mt={5} mb={3} justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>
			</Form>
		</>
	);
};
