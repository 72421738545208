import React, { useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "../../styles/userList.scss";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Box, Button, Container, Grid, IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { useSnackbar } from "notistack";
import { ErrorBox, PaperBase, PaperBox } from "../../styles/styles";
import {
	getActiveOrgUserList,
	getInactiveOrgUserList,
	reInviteOrgUser,
	deactivateOrgUser,
	activateOrgUser,
	disassociateOrgUser,
} from "../../services/userManagementService";
import ServerTable from "../common/ServerTable";
import { defaultErrorToast } from "../../styles/global";
import { useProfile } from "../../hooks/profile";
import {} from "styled-components/macro";

export const UserList = () => {
	const { orgId } = useProfile();

	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);

	const [userListData, setUserListData] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);
	const [userListError, SetUserListError] = useState();

	const [inactiveUserListData, setInactiveUserListData] = useState([]);
	const [inactivePageCount, setInactivePageCount] = useState(0);
	const [inactiveTotalRecords, setInactiveTotalRecords] = useState(0);
	const [inactiveUserListError, SetInactiveUserListError] = useState();

	const history = useHistory();

	const fetchActiveUserListData = useCallback(
		async ({ pageIndex, pageSize, sortBy }) => {
			setLoading(true);
			const { data } = await getActiveOrgUserList(
				orgId,
				pageIndex * pageSize,
				pageSize,
				sortBy
			);

			// console.log("Active User List Data: ", data);

			if (data !== undefined) {
				if (data.total === 0) SetUserListError("No Users Found");

				setUserListData(data.users);
				let pageCnt = parseInt(data.total / pageSize, 10);

				if (data.total % pageSize !== 0) pageCnt += 1;

				setPageCount(pageCnt);
				setTotalRecords(data.total);
			} else SetUserListError("No Users Found");
			setLoading(false);
		},
		[orgId]
	);

	const fetchInactiveUserListData = useCallback(
		async ({ pageIndex, pageSize, sortBy }) => {
			setLoading(true);
			const { data } = await getInactiveOrgUserList(
				orgId,
				pageIndex * pageSize,
				pageSize,
				sortBy
			);

			// console.log("Inactive User List Data: ", data);

			if (data !== undefined) {
				if (data.total === 0) SetInactiveUserListError("No Inactive Users Found");

				setInactiveUserListData(data.users);
				let pageCnt = parseInt(data.total / pageSize, 10);

				if (data.total % pageSize !== 0) pageCnt += 1;

				setInactivePageCount(pageCnt);
				setInactiveTotalRecords(data.total);
			} else SetInactiveUserListError("No Inactive Users Found");
			setLoading(false);
		},
		[orgId]
	);

	const renderExpanded = useCallback(({ data }) => <UserExtraData data={data} />, []);

	const activateUser = async (data) => {
		try {
			// console.log("data", data);
			await activateOrgUser(orgId, data.original.id);
			enqueueSnackbar("User Activated Successfully", {
				variant: "success",
			});

			history.push("/usermgmt");
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const disAssociateInactiveUser = async (data) => {
		try {
			// console.log("data", data);
			await disassociateOrgUser(orgId, data.original.id);
			enqueueSnackbar("User disassociated successfully", {
				variant: "success",
			});

			history.push("/usermgmt");
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const deactivateUser = async (data) => {
		try {
			// console.log("data", data);
			await deactivateOrgUser(orgId, data.data.id);
			enqueueSnackbar("User De-activated Successfully", {
				variant: "success",
			});
			history.push("/usermgmt");
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const disassociateUser = async (data) => {
		try {
			// console.log("data", data);
			await disassociateOrgUser(orgId, data.data.id);
			enqueueSnackbar("User disassocaited successfully", {
				variant: "success",
			});
			history.push("/usermgmt");
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const reInviteUser = async (data) => {
		try {
			// console.log("data", data);
			await reInviteOrgUser(orgId, data.data.id);
			enqueueSnackbar("Invitation Sent to User", {
				variant: "success",
			});

			history.push("/usermgmt");
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const columns = useMemo(
		() => [
			{
				id: "userExpander",
				Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
					<IconButton
						aria-label="expand row"
						size="small"
						{...getToggleAllRowsExpandedProps({
							title: "Expand All",
						})}
					>
						{isAllRowsExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				),
				Cell: ({ row }) =>
					row.isExpanded ? (
						<KeyboardArrowUpIcon
							aria-label="expand row"
							title="Expand"
							css={`
								opacity: 0.7;
								margin-left: 0.25rem;
							`}
						/>
					) : (
						<KeyboardArrowDownIcon
							aria-label="collapse row"
							title="Collapse"
							css={`
								opacity: 0.7;
								margin-left: 0.25rem;
							`}
						/>
					),
				isExpander: true,
			},
			{
				Header: "First Name",
				accessor: "firstName",
			},
			{
				Header: "Last Name",
				accessor: "lastName",
			},
			{
				Header: "E-mail",
				accessor: "email",
			},
			{
				Header: "Role(s)",
				accessor: "userRole",
				Cell: ({ value }) => value.join(", "),
				disableSorter: true,
			},
			{
				id: "editButtonColumn",
				Cell: ({ row }) => (
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={<EditIcon />}
						disableFocusRipple
						onClick={() => history.push(`/usermgmt/user/modify/${row.original.id}`)}
					>
						Edit
					</Button>
				),
				disableSorter: true,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history]
	);

	const inactiveColumns = useMemo(
		() => [
			{
				Header: "First Name",
				accessor: "firstName",
			},

			{
				Header: "Last Name",
				accessor: "lastName",
			},
			{
				Header: "E-mail",
				accessor: "email",
			},
			{
				id: "actions",
				Cell: ({ row }) => (
					<Grid container spacing={2} allignItems="center" justify="flex-end">
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								size="small"
								onClick={() => {
									activateUser(row);
								}}
								startIcon={<CheckIcon />}
								disableFocusRipple
							>
								Activate
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								color="secondary"
								size="small"
								onClick={() => {
									disAssociateInactiveUser(row);
								}}
								startIcon={<RemoveCircleIcon />}
								disableFocusRipple
							>
								Disassociate
							</Button>
						</Grid>
					</Grid>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const UserExtraData = (data) => {
		console.log("Expanded Data:", data);
		const {
			data: {
				active,
				firstName,
				lastName,
				email,
				phNum,
				phCountryCode,
				termsAccepted,
				userRole,
			},
		} = data;

		return (
			<PaperBox>
				<Grid
					container
					justify="space-between"
					css={`
						margin-bottom: 28px;
					`}
				>
					<Grid item>
						<Typography
							variant="h5"
							css={`
								text-transform: capitalize;
								font-weight: 600;
							`}
						>
							{firstName} {lastName}
						</Typography>
					</Grid>
					<Grid item>
						<Grid container spacing={1}>
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									size="small"
									onClick={() => {
										deactivateUser(data);
									}}
									startIcon={<CloseIcon />}
									disableElevation
								>
									Deactivate
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										reInviteUser(data);
									}}
									size="small"
									startIcon={<ContactMailIcon />}
									disableElevation
								>
									ReInvite
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="default"
									size="small"
									onClick={() => {
										disassociateUser(data);
									}}
									startIcon={<RemoveCircleIcon />}
									disableElevation
								>
									Disassociate
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid item xs={12} md={6} className="data-display">
						<span>E-mail</span>
						<span>{email || "N/A"}</span>
					</Grid>

					<Grid item xs={12} md={6} className="data-display">
						<span>Status</span>
						<span>{active === true ? "Active" : "Inactive"}</span>
					</Grid>

					<Grid item xs={12} md={6} className="data-display">
						<span>Phone Number</span>
						<span>
							{phCountryCode || phNum
								? `${phCountryCode ? `+${phCountryCode}` : ""} ${phNum}`
								: "N/A"}
						</span>
					</Grid>

					<Grid item xs={12} md={6} className="data-display">
						<span>Role(s)</span>
						<span>{userRole?.join(", ")}</span>
					</Grid>

					<Grid item xs={12} md={6} className="data-display">
						<span>Terms Accepted</span>
						<span>{termsAccepted === false ? "No" : "Yes"}</span>
					</Grid>
				</Grid>
			</PaperBox>
		);
	};

	return (
		<Container>
			<PaperBase>
				<Box component={Grid} mb={3} container justify="space-between">
					<Typography variant="h5" component="h1">
						Active User List
					</Typography>
					<Button
						variant="contained"
						onClick={() => {
							history.push("/usermgmt/user/add");
						}}
						color="primary"
						size="small"
						startIcon={<PersonAddIcon />}
						disableFocusRipple
					>
						Add New User
					</Button>
				</Box>
				{userListError ? (
					<ErrorBox>{userListError}</ErrorBox>
				) : (
					<ServerTable
						columns={columns}
						data={userListData}
						fetchData={fetchActiveUserListData}
						loading={loading}
						pageCount={pageCount}
						totalRecords={totalRecords}
						renderExpanded={renderExpanded}
					/>
				)}
			</PaperBase>

			<PaperBase gap>
				<Typography variant="h5" component="h1" gutterBottom>
					Inactive User List
				</Typography>
				{inactiveUserListError ? (
					<ErrorBox>{inactiveUserListError}</ErrorBox>
				) : (
					<ServerTable
						columns={inactiveColumns}
						data={inactiveUserListData}
						fetchData={fetchInactiveUserListData}
						loading={loading}
						pageCount={inactivePageCount}
						totalRecords={inactiveTotalRecords}
					/>
				)}
			</PaperBase>
		</Container>
	);
};

export default UserList;
