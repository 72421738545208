import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Col, Button } from "react-bootstrap";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
	FaTimes,
	FaStream,
	FaRegCompass,
	FaUserCircle,
	FaMoneyBillAlt,
	FaMoneyCheckAlt,
	FaUserEdit,
	FaLandmark,
	FaUserFriends,
	FaLock,
	FaCreditCard,
	FaReceipt,
} from "react-icons/fa";
import "../../../styles/navbar-side.scss";
import { useProfile } from "../../../hooks/profile";
import { getOrgProfile } from "../../../services/profileService";

const NavBar = () => {
	const [sidebarCheck, sidebarHider] = useState(true);
	const [orgDetails, setOrgDetails] = useState();
	const { orgId, userRole } = useProfile();

	// Auto hide Sidebar depending on width
	const widthCheck = useMediaQuery("(max-width:960px)");

	useEffect(() => {
		(async () => {
			if (widthCheck) sidebarHider(false);
			else sidebarHider(true);
			try {
				const { data } = await getOrgProfile(orgId);
				if (data !== undefined && data.active) {
					setOrgDetails(data);
				}
			} catch (e) {
				setOrgDetails();
			}
		})();
	}, [widthCheck, orgId]);

	// Common List of Links
	const commonList = [
		{ icon: FaRegCompass, title: "Dashboard", link: "/dashboard" },
		{ icon: FaUserCircle, title: "Profile", link: "/profile" },
		{ icon: FaMoneyCheckAlt, title: "eInvoices", link: "/eInvoices" },
		{ icon: FaMoneyBillAlt, title: "eWayBills", link: "/eWayBills" },
	];

	// Common List of Links
	const commonAdminList = [
		{ icon: FaRegCompass, title: "Dashboard", link: "/dashboard" },
		{ icon: FaUserCircle, title: "Profile", link: "/profile" },
		{ icon: FaMoneyCheckAlt, title: "eInvoices", link: "/eInvoices" },
		{ icon: FaMoneyBillAlt, title: "eWayBills", link: "/eWayBills" },
		{ icon: FaUserFriends, title: "Customers", link: "/customermgmt" },
		{ icon: FaLandmark, title: "Org Management", link: "/orgmgmt" },
		{ icon: FaUserEdit, title: "User Management", link: "/usermgmt" },
		{ icon: FaCreditCard, title: "Payment", link: "/stripe/payment" },
		{ icon: FaReceipt, title: "Subscriptions", link: "/stripe/subscriptions" },
	];

	const skyScendList = [
		{ icon: FaUserCircle, title: "Profile", link: "/profile" },
		{ icon: FaUserEdit, title: "User Management", link: "/usermgmt" },
		{ icon: FaLock, title: "Admin ", link: "/geis/admin" },
	];

	const baseList = [
		{ icon: FaUserCircle, title: "Profile", link: "/profile" },
		{ icon: FaCreditCard, title: "Payment", link: "/stripe/payment" },
	];

	const sideBarList = orgDetails
		? orgDetails.isSuperAdmin === true
			? skyScendList
			: userRole.includes("Admin")
			? commonAdminList
			: commonList
		: baseList;

	return (
		<>
			<Button
				variant="outline-light"
				className={`sidebarHider ${!sidebarCheck && "hidden"}`}
				id="sidebarHider"
				onClick={() => sidebarHider(!sidebarCheck)}
			>
				{sidebarCheck ? (
					<FaTimes className="sidebar-close" />
				) : (
					<FaStream className="sidebar-menu" />
				)}
			</Button>

			<div className={`sidebar-container col-set ${!sidebarCheck && "hidden"}`} />

			<Col xs={3} className={`sidebar-wrapper col-set ${!sidebarCheck && "hidden"}`}>
				<Nav className={`sidebar ${!sidebarCheck && "hidden"}`} activeKey="/">
					{sideBarList.map((item, index) => (
						<Nav.Item key={`sideBarItem${index}`}>
							<NavLink
								exact={item.link !== "/po"}
								activeClassName="nav-link nav-clicked"
								className="nav-link"
								id={`sidebar${item.title.replace(/\s+/g, "")}Link`}
								to={item.link}
							>
								<span style={{ fontSize: "90%" }}>
									<item.icon className="sidebar-icon" />
									{item.title}
								</span>
							</NavLink>
						</Nav.Item>
					))}
				</Nav>
			</Col>
		</>
	);
};

export default NavBar;
