import React from "react";
import { Container } from "@material-ui/core";
import { Titler } from "./Titler";

import { PaperBase } from "../../styles/styles";

const Error404 = () => (
	<Container>
		<Titler title="404 Page Not Found" />
		<PaperBase>
			<h1>404 Page Not Found</h1>
			<p>:(</p>
		</PaperBase>
	</Container>
);

export default Error404;
