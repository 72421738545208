/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Form, Button, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { useSnackbar } from "notistack";
import { ResendConfirmation, confirmSignUp } from "../../services/authService";
import { Titler } from "../common/Titler";

// TODO: Not yet implemented
const OTP = () => {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const { register, errors, handleSubmit } = useForm();
	const [otpEmail, setOTPEmail] = useState();

	const ResendOTP = async () => {
		const response = await ResendConfirmation(otpEmail);
		// console.log({ response });
		if (response === "success")
			enqueueSnackbar("OTP code has been re-sent to email", {
				variant: "success",
			});
		else
			enqueueSnackbar(response, {
				variant: "error",
			});
	};

	const OTPSubmit = async (data) => {
		const response = await confirmSignUp(data.otpEmail, data.otpCode);
		// console.log({ response });

		if (response === "success") {
			history.push("/login");
			enqueueSnackbar("Account confirmed! Please login to continue.", {
				variant: "success",
			});
		} else
			enqueueSnackbar(response, {
				variant: "error",
			});
	};

	return (
		<>
			<Titler title="OTP" />
			<span>
				<span className="login-tab tabbed">OTP</span>
			</span>
			<Form className="forgot-password-form" onSubmit={handleSubmit(OTPSubmit)}>
				<Form.Group controlId="otpEmail">
					<Form.Control
						type="email"
						name="otpEmail"
						placeholder="username@email.com"
						onChange={(e) => setOTPEmail(e.currentTarget.value)}
						ref={register({
							required: "Required",
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: "Invalid email address",
							},
						})}
					/>
					{errors.otpEmail && <p className="form-error">{errors.otpEmail.message}</p>}
				</Form.Group>
				<Form.Group controlId="otpCode">
					<Form.Control
						name="otpCode"
						placeholder="Enter code here"
						ref={register({
							required: "Required",
						})}
					/>
					{errors.otpCode && <p className="form-error">{errors.otpCode.message}</p>}
				</Form.Group>
				<Form.Group>
					<Form.Row>
						<Col>
							<span
								className="otp-reset-nvm span-link"
								id="otpResendLink"
								onClick={ResendOTP}
								role="button"
							>
								Resend Code?
							</span>
							<span
								className="otp-reset-nvm span-link"
								id="otpCancelLink"
								onClick={() => {
									history.push("/login");
								}}
								role="button"
							>
								← Nevermind
							</span>
						</Col>
						<Col>
							<Button className="otp-button" id="otpConfirm" type="submit">
								CONFIRM
							</Button>
						</Col>
					</Form.Row>
				</Form.Group>
			</Form>
		</>
	);
};

export default OTP;
