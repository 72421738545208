import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import moment from "moment";
import { defaultErrorToast } from "../../styles/global";
import { Label, PaperBase, RequiredStar, ErrorBox, Spinner, PaperBox } from "../../styles/styles";
import { getOrgDetailsByOrgId, updateOrgDetailsByAdmin } from "../../services/organizationService";
import {} from "styled-components/macro";
import { getGSTNDetails, validateGSTNDetails } from "../../services/gisService";
import { useProfile } from "../../hooks/profile";
import { getActiveSubscription } from "../../services/stripeService";
import { getOrgCredentialsByAdmin } from "../../services/credentialSevice";

export const AdminOrganizationEdit = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const [orgDetails, setOrgDetails] = useState();
	const [gstnDetails, setGstnDetails] = useState();
	const [stripeSessionDetails, setStripeSessionDetails] = useState();
	const [orgCredentials, setOrgCredentials] = useState();
	const [errorDetails, setErrorDetails] = useState();
	const history = useHistory();
	const params = useParams();
	const { orgId } = params;
	const { orgName } = useProfile();

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {},
	});

	useEffect(() => {
		(async () => {
			try {
				const orgData = await getOrgDetailsByOrgId(orgId);
				if (orgData !== undefined && orgData.data !== undefined) {
					setOrgDetails(orgData.data);
					try {
						const res = await getGSTNDetails(orgData.data.gstn);
						if (res !== null && res !== undefined) {
							setGstnDetails(res.data);
						}
					} catch (e) {
						// console.log(e);
					}
					try {
						const stripedata = await getActiveSubscription(orgId);
						if (stripedata !== null && stripedata !== undefined) {
							setStripeSessionDetails(stripedata.data.data[0]);
						}
					} catch (e) {
						// console.log(e);
					}

					try {
						const credData = await getOrgCredentialsByAdmin(orgData.data.gstn);
						if (credData !== undefined && credData.data !== undefined) {
							setOrgCredentials(credData.data);
						}
					} catch (e) {
						// console.log(e);
					}
				}
			} catch (e) {
				setErrorDetails("Unable to get Organization Details.");
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId, orgName]);

	// Submit
	const submitRequest = async (data) => {
		try {
			const formattedData = {
				name: orgDetails.name,
				contactName: data.contactName,
				contactNumber: data.contactNumber,
				contactEmail: data.contactEmail,
				region: orgDetails.region,
				address: {
					addressLine1: data.addressLine1,
					addressLine2: data.addressLine2,
					city: data.city,
					state: data.state,
					country: data.country,
					postalCode: data.postalCode,
				},
				financials: {
					lastyearRevenue: orgDetails.financials.lastyearRevenue,
					AvgRevenueforlast3yrs: orgDetails.financials.AvgRevenueforlast3yrs,
					yearofIncorporation: orgDetails.financials.yearofIncorporation,
					numberofEmployees: orgDetails.financials.numberofEmployees,
					numberofInvoicespermonth: orgDetails.financials.numberofInvoicespermonth,
				},
				quota: Number(orgDetails.quota),
				stripePaymentToken: orgDetails.stripePaymentToken,
				subscriptionValidity: Boolean(orgDetails.subscriptionValidity),
				gstn: data.gstn,
				panNumber: orgDetails.panNumber,
				active: data.active === "Active",
			};

			console.log("data", formattedData);
			await updateOrgDetailsByAdmin(orgId, formattedData);

			history.goBack();
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const handleCancel = () => {
		history.goBack();
	};

	const validateGstn = async () => {
		setLoading(true);
		const gstnVal = document.getElementsByName(`gstn`)[0].value;
		console.log(gstnVal);
		try {
			await validateGSTNDetails(gstnVal);
		} catch (e) {
			console.log(e);
		}

		try {
			const res = await getGSTNDetails(gstnVal);
			if (
				res !== null &&
				res !== undefined &&
				res.data !== null &&
				res.data.gstin !== null &&
				res.data.gstin !== undefined
			) {
				// console.log(res);
				setGstnDetails(res.data);
			} else {
				setGstnDetails();
				enqueueSnackbar(`${gstnVal} not a valid GSTN`, {
					variant: "error",
				});
			}
		} catch (e) {
			// console.log(e);
			enqueueSnackbar(`${gstnVal} not a valid GSTN`, {
				variant: "error",
			});
			setGstnDetails();
		}
		setLoading(false);
	};

	return (
		<Container>
			{errorDetails && <ErrorBox>{errorDetails}</ErrorBox>}
			<PaperBase>
				{orgDetails && (
					<Form onSubmit={handleSubmit(submitRequest)}>
						<Typography
							variant="h5"
							component="h1"
							css={`
								margin-bottom: 42px;
							`}
						>
							Edit Organization
						</Typography>

						<PaperBox rounded>
							<Grid item container spacing={2}>
								<Grid item xs={12} md={3}>
									<h6
										css={`
											font-size: 0.95rem;
											font-weight: 700;
											color: #222222dd;
										`}
									>
										General Details
									</h6>
								</Grid>

								<Grid item xs={12} md={6}>
									<Form.Group controlId="name">
										<Label>
											<span>Name</span>
										</Label>
										<Form.Control
											type="text"
											defaultValue={orgDetails.name}
											readOnly
										/>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3} />
								<Grid item xs={12} md={3} />

								<Grid item xs={12} md={3}>
									<Form.Group controlId="contactName">
										<Label>
											<span>
												Contact Name
												<RequiredStar />
											</span>
											{errors.contactName && (
												<p className="form-error-inline">
													{errors.contactName.message}
												</p>
											)}
										</Label>
										<Form.Control
											name="contactName"
											type="text"
											defaultValue={orgDetails.contactName}
											ref={register({
												required: "Required",
											})}
										/>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3}>
									<Form.Group controlId="contactNumber">
										<Label>
											<span>
												Contact Number
												<RequiredStar />
											</span>
											{errors.contactNumber && (
												<p className="form-error-inline">
													{errors.contactNumber.message}
												</p>
											)}
										</Label>
										<Form.Control
											name="contactNumber"
											type="text"
											defaultValue={orgDetails.contactNumber}
											ref={register({
												required: "Required",
											})}
										/>
									</Form.Group>
								</Grid>

								<Grid item xs={12} md={3}>
									<Form.Group controlId="contactEmail">
										<Label>
											<span>
												Contact Email
												<RequiredStar />
											</span>
											{errors.contactEmail && (
												<p className="form-error-inline">
													{errors.contactEmail.message}
												</p>
											)}
										</Label>
										<Form.Control
											name="contactEmail"
											type="text"
											defaultValue={orgDetails.contactEmail}
											ref={register({
												required: "Required",
											})}
										/>
									</Form.Group>
								</Grid>
							</Grid>
						</PaperBox>

						<PaperBox rounded>
							<Grid item container spacing={2}>
								<Grid item xs={3}>
									<h6
										css={`
											font-size: 0.95rem;
											font-weight: 700;
											color: #222222dd;
										`}
									>
										Address
									</h6>
								</Grid>

								<Grid item container xs={9} direction="column" spacing={1}>
									<Grid item container spacing={2}>
										<Grid item xs={12} md={6}>
											<Form.Group controlId="addressLine1">
												<Label>
													<span>
														Line 1
														<RequiredStar />
													</span>
													{errors.addressLine1 && (
														<p className="form-error-inline">
															{errors.addressLine1.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="addressLine1"
													type="text"
													defaultValue={orgDetails.address.addressLine1}
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="addressLine2">
												<Label>
													<span>Line 2</span>
												</Label>
												<Form.Control
													name="addressLine2"
													type="text"
													defaultValue={orgDetails.address.addressLine2}
													ref={register()}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="city">
												<Label>
													<span>
														City
														<RequiredStar />
													</span>
													{errors.city && (
														<p className="form-error-inline">
															{errors.city.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="city"
													type="text"
													defaultValue={orgDetails.address.city}
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="state">
												<Label>
													<span>
														State
														<RequiredStar />
													</span>
													{errors.state && (
														<p className="form-error-inline">
															{errors.state.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="state"
													type="text"
													defaultValue={orgDetails.address.state}
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="country">
												<Label>
													<span>
														Country
														<RequiredStar />
													</span>
													{errors.country && (
														<p className="form-error-inline">
															{errors.country.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="country"
													type="text"
													defaultValue={orgDetails.address.country}
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="postalCode">
												<Label>
													<span>
														Postal Code
														<RequiredStar />
													</span>
													{errors.postalCode && (
														<p className="form-error-inline">
															{errors.postalCode.message}
														</p>
													)}
												</Label>
												<Form.Control
													name="postalCode"
													type="text"
													defaultValue={orgDetails.address.postalCode}
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</PaperBox>

						<PaperBox rounded>
							<Grid item container spacing={2}>
								<Grid item xs={3}>
									<h6
										css={`
											font-size: 0.95rem;
											font-weight: 700;
											color: #222222dd;
										`}
									>
										GSTIN Details
									</h6>
								</Grid>

								<Grid item container xs={9} direction="column" spacing={1}>
									<Grid item container spacing={2}>
										<Grid item xs={12} md={12}>
											<Form.Group controlId="gstn">
												<Label
													css={`
														display: flex;
														justify-content: space-between;
														align-items: center;
													`}
												>
													<span>
														GSTIN
														<RequiredStar />{" "}
														{errors.gstn && (
															<span className="form-error-inline">
																{errors.gstn.message}
															</span>
														)}
													</span>
													{orgCredentials !== null &&
														orgCredentials !== undefined &&
														orgCredentials.userName !== null &&
														orgCredentials.userName !== undefined && (
															<span>
																<Button
																	color="primary"
																	aria-label="Validate GSTIN"
																	size="small"
																	onClick={validateGstn}
																	css={`
																		font-size: 0.9rem;
																		font-weight: 600;
																		text-transform: none;
																	`}
																>
																	{loading
																		? "Validating GSTIN"
																		: "Validate GSTIN"}
																	{loading && (
																		<Spinner
																			size={16}
																			css={`
																				margin-left: 0.3rem;
																			`}
																		/>
																	)}
																	{!loading && (
																		<CheckCircleIcon
																			fontSize="small"
																			css={`
																				margin-left: 0.2rem;
																			`}
																		/>
																	)}
																</Button>
															</span>
														)}
												</Label>
												<Form.Control
													name="gstn"
													type="text"
													defaultValue={orgDetails.gstn}
													ref={register({
														required: "Required",
													})}
												/>
											</Form.Group>
										</Grid>
										<Grid item xs={12} md={6}>
											<Form.Group controlId="gstnLegalName">
												<Label>
													<span>Legal Name</span>
												</Label>
												<Form.Control
													name="gstnLegalName"
													type="text"
													readonly
													value={
														gstnDetails !== undefined &&
														gstnDetails !== null
															? gstnDetails.legalName
															: ""
													}
												/>
											</Form.Group>
										</Grid>
										<Grid item xs={12} md={6}>
											<Form.Group controlId="gstnBlockStatus">
												<Label>
													<span>Block Status</span>
												</Label>
												<Form.Control
													name="gstnBlockStatus"
													type="text"
													readonly
													value={
														gstnDetails !== undefined &&
														gstnDetails !== null
															? gstnDetails.blockStatus
															: ""
													}
												/>
											</Form.Group>
										</Grid>
										<Grid item xs={12} md={6}>
											<Form.Group controlId="gstnStatus">
												<Label>
													<span>Status</span>
												</Label>
												<Form.Control
													name="gstnStatus"
													type="text"
													readonly
													value={
														gstnDetails !== undefined &&
														gstnDetails !== null
															? gstnDetails.status
															: ""
													}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="validatedDate">
												<Label>
													<span>Last Validated Date</span>
												</Label>
												<Form.Control
													name="validatedDate"
													type="text"
													readonly
													value={
														gstnDetails !== undefined &&
														gstnDetails !== null
															? gstnDetails.updateDate
															: ""
													}
												/>
											</Form.Group>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</PaperBox>

						<PaperBox rounded>
							<Grid item container spacing={2}>
								<Grid item xs={3}>
									<h6
										css={`
											font-size: 0.95rem;
											font-weight: 700;
											color: #222222dd;
										`}
									>
										Subscription Details
									</h6>
								</Grid>

								<Grid item container xs={9} direction="column" spacing={1}>
									<Grid item container spacing={2}>
										<Grid item xs={12} md={12}>
											<Form.Group controlId="stripeSubscriptionId">
												<Label>
													<span>Subscription ID</span>
												</Label>
												<Form.Control
													name="stripeSubscriptionId"
													type="text"
													readonly
													value={
														stripeSessionDetails !== null &&
														stripeSessionDetails !== undefined
															? stripeSessionDetails.id
															: ""
													}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="stripePlanName">
												<Label>
													<span>Plan</span>
												</Label>
												<Form.Control
													name="stripePlanName"
													type="text"
													radonly
													value={
														stripeSessionDetails !== null &&
														stripeSessionDetails !== undefined &&
														stripeSessionDetails.plan !== undefined
															? stripeSessionDetails.plan.nickname
															: ""
													}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="stripePlanStartDate">
												<Label>
													<span>Plan Start Date</span>
												</Label>
												<Form.Control
													name="stripePlanStartDate"
													type="text"
													readonly
													value={
														stripeSessionDetails !== null &&
														stripeSessionDetails !== undefined
															? moment
																	.unix(
																		stripeSessionDetails.start_date
																	)
																	.format()
															: ""
													}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="stripeAmountPaid">
												<Label>
													<span>Amount Paid</span>
												</Label>
												<Form.Control
													name="stripeAmountPaid"
													type="text"
													readonly
													value={
														stripeSessionDetails !== null &&
														stripeSessionDetails !== undefined &&
														stripeSessionDetails.plan !== undefined
															? Number(
																	stripeSessionDetails.plan.amount
															  ) / 100
															: ""
													}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="stripePaymentStatus">
												<Label>
													<span>Status</span>
												</Label>
												<Form.Control
													name="stripePaymentStatus"
													type="text"
													readonly
													value={
														stripeSessionDetails !== null &&
														stripeSessionDetails !== undefined
															? stripeSessionDetails.status
															: ""
													}
												/>
											</Form.Group>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</PaperBox>

						<PaperBox rounded>
							<Grid item container spacing={2}>
								<Grid item xs={3}>
									<h6
										css={`
											font-size: 0.95rem;
											font-weight: 700;
											color: #222222dd;
										`}
									>
										Org API Credentials
									</h6>
								</Grid>

								<Grid item container xs={9} direction="column" spacing={1}>
									<Grid item container spacing={2}>
										<Grid item xs={12} md={6}>
											<Form.Group controlId="apiUserName">
												<Label>
													<span>Status</span>
												</Label>
												<Form.Control
													name="apiUserName"
													type="text"
													readonly
													value={
														orgCredentials !== null &&
														orgCredentials !== undefined &&
														orgCredentials.userName !== null &&
														orgCredentials.userName !== undefined
															? "Provided"
															: "Not Provided"
													}
												/>
											</Form.Group>
										</Grid>

										<Grid item xs={12} md={6}>
											<Form.Group controlId="apiUserName">
												<Label>
													<span>Last Update Date</span>
												</Label>
												<Form.Control
													name="apiUserName"
													type="text"
													readonly
													value={
														orgCredentials !== null &&
														orgCredentials !== undefined &&
														orgCredentials.updateDate !== null &&
														orgCredentials.updateDate !== undefined
															? orgCredentials.updateDate
															: ""
													}
												/>
											</Form.Group>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</PaperBox>

						<PaperBox rounded>
							<Grid item container spacing={2}>
								<Grid item xs={12} md={3}>
									<h6
										css={`
											font-size: 0.95rem;
											font-weight: 700;
											color: #222222dd;
										`}
									>
										Status
									</h6>
								</Grid>

								<Grid item xs={12} md={4}>
									<Form.Group controlId="active">
										<Label>
											<span>Activate Organization</span>
										</Label>
										<Form.Control
											name="active"
											as="select"
											defaultValue={
												orgDetails.active === true ? "Active" : "Inactive"
											}
											ref={register()}
										>
											<option key="Inactive" value="Inactive">
												Deactivate
											</option>
											<option key="Active" value="Active">
												Activate
											</option>
										</Form.Control>
									</Form.Group>
								</Grid>
							</Grid>
						</PaperBox>

						<Grid
							css={`
								margin-top: 28px;
							`}
							item
							container
							justify="space-between"
						>
							<Button
								variant="outlined"
								color="secondary"
								onClick={handleCancel}
								disableFocusRipple
							>
								Cancel
							</Button>

							<Button
								variant="contained"
								color="primary"
								type="submit"
								disableFocusRipple
							>
								Confirm
							</Button>
						</Grid>
					</Form>
				)}
			</PaperBase>
		</Container>
	);
};

export default AdminOrganizationEdit;
