import React from "react";

import { InputGroup, Button, FormControl } from "react-bootstrap";

import { FaSearch } from "react-icons/fa";

const SearchBox = ({ value, onChange }) => (
	<InputGroup className="mb-3">
		<FormControl
			id="docListSearchField"
			type="text"
			name="query"
			placeholder="Search Documents List"
			value={value}
			onChange={(e) => onChange(e.currentTarget.value)}
		/>
		<InputGroup.Append>
			<Button
				disabled
				variant="outline-primary"
				style={{ borderColor: "#ced4da", zIndex: 1 }}
			>
				<FaSearch />
			</Button>
		</InputGroup.Append>
	</InputGroup>
);

export default SearchBox;
