import React from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { createOrgUserDetails } from "../../services/userManagementService";
import { defaultErrorToast } from "../../styles/global";
import { useProfile } from "../../hooks/profile";
import { Label, PaperBase, RequiredStar } from "../../styles/styles";

export const UserCreate = () => {
	const { orgId } = useProfile();

	const { enqueueSnackbar } = useSnackbar();

	const history = useHistory();

	const userRoles = [
		{ key: "Admin", value: "Admin" },
		{ key: "User", value: "User" },
	];

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {},
	});

	// Submit
	const submitRequest = async (data) => {
		try {
			const formattedData = {
				...data,
			};
			_.mapKeys(formattedData, (value, key) => {
				if (_.isEmpty(value)) delete formattedData[key];
			});
			// console.log("data", formattedData);
			await createOrgUserDetails(orgId, formattedData);

			enqueueSnackbar("User successfully created", {
				variant: "success",
			});

			history.goBack();
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	return (
		<Container>
			<PaperBase>
				{userRoles ? (
					<Form onSubmit={handleSubmit(submitRequest)}>
						<Typography variant="h5" component="h1" gutterBottom>
							Add New User
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Form.Group controlId="email">
									<Label>
										<span>
											E-mail
											<RequiredStar />
										</span>
										{errors.email && (
											<p className="form-error-inline">
												{errors.email.message}
											</p>
										)}
									</Label>
									<Form.Control
										name="email"
										type="text"
										ref={register({
											required: "Required",
										})}
									/>
								</Form.Group>
							</Grid>
							<Grid item xs={12} md={6}>
								<Form.Group controlId="firstName">
									<Label>
										<span>
											First Name
											<RequiredStar />
										</span>
										{errors.firstName && (
											<p className="form-error-inline">
												{errors.firstName.message}
											</p>
										)}
									</Label>
									<Form.Control
										name="firstName"
										type="text"
										ref={register({
											required: "Required",
										})}
									/>
								</Form.Group>
							</Grid>

							<Grid item xs={12} md={6}>
								<Form.Group controlId="lastName">
									<Label>
										<span>
											Last Name
											<RequiredStar />
										</span>
										{errors.lastName && (
											<p className="form-error-inline">
												{errors.lastName.message}
											</p>
										)}
									</Label>
									<Form.Control
										name="lastName"
										type="text"
										ref={register({
											required: "Required",
										})}
									/>
								</Form.Group>
							</Grid>
							<Grid item xs={12} md={6}>
								<Form.Group controlId="phCountryCode">
									<Label>
										<span>
											Phone Country Code
											<RequiredStar />
										</span>
										{errors.phCountryCode && (
											<p className="form-error-inline">
												{errors.phCountryCode.message}
											</p>
										)}
									</Label>
									<Form.Control
										name="phCountryCode"
										type="text"
										className={errors.phCountryCode && "field-error"}
										ref={register({
											required: "Required",
											pattern: {
												value: /^\d*$/,
												message: "Invalid",
											},
										})}
									/>
								</Form.Group>
							</Grid>
							<Grid item xs={12} md={6}>
								<Form.Group controlId="phNum">
									<Label>
										<span>
											Phone Number
											<RequiredStar />
										</span>
										{errors.phNum && (
											<p className="form-error-inline">
												{errors.phNum.message}
											</p>
										)}
									</Label>
									<Form.Control
										name="phNum"
										type="text"
										className={errors.phNum && "field-error"}
										ref={register({
											required: "Required",
											pattern: {
												value: /^\d*$/,
												message: "Invalid",
											},
										})}
									/>
								</Form.Group>
							</Grid>
							<Grid item xs={12} md={6}>
								<Form.Group controlId="userRole">
									<Label>
										<span>
											Role(s)
											<RequiredStar />
										</span>
										{errors.userRole && (
											<p className="form-error-inline">
												{errors.userRole.message}
											</p>
										)}
									</Label>
									<Form.Control
										as="select"
										name="userRole"
										multiple
										ref={register({ required: "Required" })}
										defaultValue={["User"]}
									>
										{userRoles.map((item) => (
											<option key={item.key} value={item.value}>
												{item.key}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Grid>
							<Grid item xs={12} md={6}>
								<Form.Group controlId="country">
									<Label>
										<span>Country</span>
										{errors.country && (
											<p className="form-error-inline">
												{errors.country.message}
											</p>
										)}
									</Label>
									<Form.Control
										name="country"
										type="text"
										className={errors.country && "field-error"}
										ref={register()}
									/>
								</Form.Group>
							</Grid>

							<Grid item container justify="space-between">
								<Button
									variant="outlined"
									color="secondary"
									onClick={history.goBack}
									disableFocusRipple
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="primary"
									type="submit"
									disableFocusRipple
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Form>
				) : (
					<Loader
						type="ThreeDots"
						color="#00aeef"
						secondaryColor="#00aeef"
						height={80}
						width={80}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							minHeight: "100%",
							height: "fit-content",
						}}
					/>
				)}
			</PaperBase>
		</Container>
	);
};

export default UserCreate;
