import React, { useState, useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import {
	Box,
	Container,
	Button,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Controller, useForm } from "react-hook-form";
import { ErrorBox, FilterChip, ErrorHelperText, SearchForm } from "../../../styles/styles";
import { geteInvoiceList } from "../../../services/gisService";
import ServerTable from "../../common/ServerTable";
import { useProfile } from "../../../hooks/profile";
import {} from "styled-components/macro";

export const ViewEInvoice = () => {
	const { orgId } = useProfile();

	const [loading, setLoading] = useState(false);

	const [invoiceListData, setInvoiceListData] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);

	const [invoiceListError, SetInvoiceListError] = useState();
	const history = useHistory();

	const [pageSize, setPageSize] = useState(10);
	const [pageIndex, setPageIndex] = useState(0);
	const [sortBy, setSortBy] = useState([]);
	const [searchQuery, setSearchQuery] = useState([]);

	const fetchInvoiceListData = useCallback(
		async ({ pageIndex, pageSize, sortBy, searchQuery }) => {
			setLoading(true);
			setPageSize(pageSize);
			setPageIndex(pageIndex);
			setSortBy(sortBy);

			const { data } = await geteInvoiceList(
				orgId,
				pageIndex * pageSize,
				pageSize,
				sortBy,
				searchQuery
			);

			// console.log("Invoice List Data: ", data);

			if (data !== undefined) {
				setInvoiceListData(data.requests);

				let pageCnt = parseInt(data.total / pageSize, 10);

				if (data.total % pageSize !== 0) pageCnt += 1;

				setPageCount(pageCnt);
				setTotalRecords(data.total);
			} else SetInvoiceListError("No Invoie(s) Found");
			setLoading(false);
		},
		[orgId]
	);

	const columns = useMemo(
		() => [
			{
				Header: "Document ID",
				accessor: "documentDetails.sourceDocId",
				Cell: ({ row, value }) => (
					<Link id="invoiceIDLink" to={`eInvoices/${row.original.invoiceRefNBR}`}>
						{value}
					</Link>
				),
			},
			{
				Header: "Customer",
				accessor: "buyerInfo.entity.legalName",
				Cell: ({ value }) => (
					<span
						css={`
							font-weight: 600;
						`}
					>
						{value}
					</span>
				),
			},

			{
				Header: "Created Date",
				accessor: "createDate",
				Cell: ({ value }) => (value ? moment(value).format("DD/MM/YYYY") : "N/A"),
			},
			{
				Header: "Created By",
				accessor: "createdName",
			},
			{
				Header: "Total Amount",
				accessor: "totalValueDetails.totalInvoiceValue",
				Cell: ({ value }) => (
					<span
						css={`
							font-weight: 700;
						`}
					>
						{Number(value).toLocaleString("en-IN", {
							style: "currency",
							currency: "INR",
							minimumFractionDigits: 2,
							maximumFractionDigits: 4,
						})}
					</span>
				),
				flipPosition: true,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history]
	);

	return (
		<Container>
			<Box pt={3} pb="28px">
				<Filters
					searchQuery={searchQuery}
					setSearchQuery={setSearchQuery}
					SetError={SetInvoiceListError}
				/>

				{invoiceListError ? (
					<ErrorBox>{invoiceListError}</ErrorBox>
				) : (
					<ServerTable
						columns={columns}
						data={invoiceListData}
						fetchData={fetchInvoiceListData}
						loading={loading}
						pageCount={pageCount}
						totalRecords={totalRecords}
						defaultRowsPerPage={pageSize}
						currentPage={pageIndex}
						defaultSortBy={sortBy}
						searchQuery={searchQuery}
					/>
				)}
			</Box>
		</Container>
	);
};

export default ViewEInvoice;

const Filters = ({ searchQuery, setSearchQuery, SetError }) => {
	const { control, errors, handleSubmit, watch } = useForm({
		mode: "onTouched",
	});

	const [selectedFilter, setSelectedFilter] = useState("documentId");

	const [shouldAutoFocus, setAutoFocusCheck] = useState(false);
	const getOptionValue = (option) => {
		if (!shouldAutoFocus) setAutoFocusCheck(true);
		setSelectedFilter(option?.value || null);
	};

	const documentIdSearch = useCallback(
		(data) => {
			const { documentId: val } = data;
			const label = "Document ID";
			const field = "documentDetails.sourceDocId";

			if (!val) return;

			const afterRejection = _.reject(searchQuery, { field });
			setSearchQuery([
				...afterRejection,
				{
					id: afterRejection.length,
					label,
					field,
					op: "CONTAINS",
					val,
				},
			]);

			SetError();
		},
		[SetError, searchQuery, setSearchQuery]
	);

	const customerNameSearch = useCallback(
		(data) => {
			const { customerName: val } = data;
			const label = "Customer";
			const field = "buyerInfo.entity.legalName";

			if (!val) return;

			const afterRejection = _.reject(searchQuery, { field });
			setSearchQuery([
				...afterRejection,
				{
					id: afterRejection.length,
					label,
					field,
					op: "CONTAINS",
					val,
				},
			]);

			SetError();
		},
		[SetError, searchQuery, setSearchQuery]
	);

	const { createDateLower, createDateHigher } = watch(["createDateLower", "createDateHigher"]);
	const createDateSearch = useCallback(
		(data) => {
			// Refactorable Variables
			let { createDateLower, createDateHigher } = data;
			createDateLower /= 1000;
			createDateHigher = createDateHigher
				? createDateHigher / 1000 + 24 * 60 * 60
				: createDateHigher;

			const label = "Created Date";
			const field = "createDate";
			const gtVal = createDateLower;
			const ltVal = createDateHigher;

			if (!createDateLower && !createDateHigher) return;

			const afterRejection = _.reject(searchQuery, { field });

			if (createDateLower && !createDateHigher)
				setSearchQuery([
					...afterRejection,
					{
						id: afterRejection.length,
						label,
						field,
						op: "GREATER_THAN_EQUAL",
						gtVal,
						date: true,
					},
				]);

			if (!createDateLower && createDateHigher)
				setSearchQuery([
					...afterRejection,
					{
						id: afterRejection.length,
						label,
						field,
						op: "LESS_THAN_EQUAL",
						ltVal,
						date: true,
					},
				]);

			if (createDateLower && createDateHigher)
				setSearchQuery([
					...afterRejection,
					{
						id: afterRejection.length,
						label,
						field,
						op: "BETWEEN",
						ltVal,
						gtVal,
						date: true,
					},
				]);
			SetError();
		},
		[searchQuery, setSearchQuery, SetError]
	);

	const getFilterComponent = useCallback(
		(filter) => {
			switch (filter) {
				case "documentId":
					return (
						<Grid
							container
							component={SearchForm}
							onSubmit={handleSubmit(documentIdSearch)}
						>
							<Controller
								as={
									<TextField
										id="documentIdSearch"
										placeholder="Enter Document ID, eg: 123000456"
										variant="outlined"
										size="small"
										error={errors.documentId}
										InputProps={{
											endAdornment: (
												<InputAdornment>
													<IconButton size="small" type="submit">
														<SearchIcon fontSize="small" />
													</IconButton>
												</InputAdornment>
											),
										}}
										css={`
											& .MuiInputBase-adornedEnd {
												padding-right: 0.5rem;
											}
										`}
										fullWidth
										autoFocus={shouldAutoFocus}
									/>
								}
								name="documentId"
								defaultValue=""
								control={control}
							/>
							<ErrorHelperText>{errors.documentId?.message}</ErrorHelperText>
						</Grid>
					);
				case "customerName":
					return (
						<Grid
							container
							component={SearchForm}
							onSubmit={handleSubmit(customerNameSearch)}
						>
							<Controller
								as={
									<TextField
										id="customerNameSearch"
										placeholder="Enter Customer Name, eg: John"
										variant="outlined"
										size="small"
										error={errors.customerName}
										InputProps={{
											endAdornment: (
												<InputAdornment>
													<IconButton size="small" type="submit">
														<SearchIcon fontSize="small" />
													</IconButton>
												</InputAdornment>
											),
										}}
										css={`
											& .MuiInputBase-adornedEnd {
												padding-right: 0.5rem;
											}
										`}
										fullWidth
										autoFocus={shouldAutoFocus}
									/>
								}
								name="customerName"
								defaultValue=""
								control={control}
							/>
							<ErrorHelperText>{errors.customerName?.message}</ErrorHelperText>
						</Grid>
					);

				case "createDate":
					return (
						<Grid
							container
							component={SearchForm}
							onSubmit={handleSubmit(createDateSearch)}
							spacing={1}
							css={`
								margin-bottom: -8px;
							`}
							wrap="nowrap"
							alignItems="center"
							autoComplete="off"
						>
							<Grid item xs={6}>
								<Controller
									render={({ value, onChange }) => (
										<DatePicker
											id="createDateLowerPicker"
											placeholderText="DD/MM/YYYY"
											dateFormat="dd/MM/yyyy"
											selected={value}
											onChange={(date) => onChange(date)}
											startDate={createDateLower}
											endDate={createDateHigher}
											customInput={
												<TextField
													variant="outlined"
													size="small"
													error={errors.createDateLower}
													fullWidth
												/>
											}
											todayButton={
												(!createDateHigher ||
													moment(createDateHigher).isSameOrAfter(
														moment().format("DD/MM/YYYY")
													)) &&
												"Today"
											}
											showMonthDropdown
											showYearDropdown
											selectsStart
											isClearable
											autoFocus
										/>
									)}
									name="createDateLower"
									valueName="selected"
									control={control}
									rules={{
										valueAsNumber: true,
									}}
									defaultValue={null}
								/>
								<ErrorHelperText>{errors.createDateLower?.message}</ErrorHelperText>
							</Grid>
							to
							<Grid item xs={6}>
								<Controller
									render={({ value, onChange }) => (
										<DatePicker
											id="createDateHigherPicker"
											placeholderText="DD/MM/YYYY"
											dateFormat="dd/MM/yyyy"
											selected={value}
											onChange={(date) => onChange(date)}
											minDate={createDateLower}
											startDate={createDateLower}
											endDate={createDateHigher}
											customInput={
												<TextField
													variant="outlined"
													size="small"
													error={errors.createDateHigher}
													fullWidth
												/>
											}
											todayButton={
												(!createDateLower ||
													moment(createDateLower).isSameOrBefore(
														moment().format("DD/MM/YYYY")
													)) &&
												"Today"
											}
											showMonthDropdown
											showYearDropdown
											selectsEnd
											isClearable
										/>
									)}
									name="createDateHigher"
									valueName="selected"
									control={control}
									rules={{
										valueAsNumber: true,
									}}
									defaultValue={null}
								/>
								<ErrorHelperText>
									{errors.createDateHigher?.message}
								</ErrorHelperText>
							</Grid>
							<IconButton
								type="submit"
								css={`
									height: fit-content;
									padding: 7px;
									margin-bottom: 3px;
								`}
							>
								<SearchIcon />
							</IconButton>
						</Grid>
					);
				default:
					return null;
			}
		},
		[
			handleSubmit,
			documentIdSearch,
			customerNameSearch,
			errors.documentId,
			errors.customerName,
			shouldAutoFocus,
			control,
			errors.createDateLower,
			errors.createDateHigher,
			createDateSearch,
			createDateLower,
			createDateHigher,
		]
	);

	// Delete using Chip
	const handleDelete = useCallback(
		(id) => {
			const afterRemoval = _.reject(searchQuery, { id });
			setSearchQuery(afterRemoval);
		},
		[searchQuery, setSearchQuery]
	);

	const chipLabelSelector = ({ label, val, vals, ltVal, gtVal }) => {
		let chipLabel;

		if (val) chipLabel = `${label}: ${val}`;

		if (vals) chipLabel = `${vals.join(" / ")}`;

		if (label === "Created Date")
			if (ltVal && gtVal)
				chipLabel = `${moment.unix(gtVal).format("DD/MM/YYYY")} ≤ ${label} ≤ ${moment
					.unix(ltVal - 24 * 60 * 60)
					.format("DD/MM/YYYY")}`;
			else if (ltVal)
				chipLabel = `${label} ≤ ${moment.unix(ltVal - 24 * 60 * 60).format("DD/MM/YYYY")}`;
			else if (gtVal) chipLabel = `${label} ≥ ${moment.unix(gtVal).format("DD/MM/YYYY")}`;

		return chipLabel;
	};

	return (
		<>
			<Box mb={searchQuery.length ? 3 : 4}>
				<Grid container spacing={1} alignItems="flex-start">
					<Grid item xs={12} md={3}>
						<Select
							id="filterSelector"
							placeholder="Select Filter"
							options={[
								{ value: "documentId", label: "Document ID" },
								{ value: "customerName", label: "Customer Name" },
								{ value: "createDate", label: "Created Date" },
							]}
							onChange={getOptionValue}
							theme={(theme) => ({
								...theme,
								colors: {
									...theme.colors,
									primary50: "#a3e6ff",
									primary25: "#d1f3ff",
									primary: "#00aeef",
								},
							})}
							isClearable
						/>
					</Grid>
					<Grid item xs={12} md={9} container wrap="nowrap">
						{getFilterComponent(selectedFilter)}
					</Grid>
				</Grid>
			</Box>
			<Box mb={searchQuery.length === 0 ? 0 : 4}>
				{searchQuery.length !== 0 && (
					<Grid
						container
						justify="space-between"
						alignItems="baseline"
						css={`
							margin-bottom: 0.75rem;
						`}
					>
						<Typography variant="h6">Filters</Typography>
						<Button
							size="small"
							color="secondary"
							onClick={() => {
								setSearchQuery([]);
								SetError();
							}}
							css={`
								font-weight: 600;
							`}
						>
							clear all
						</Button>
					</Grid>
				)}
				<Grid container spacing={1}>
					{searchQuery.map((filter) => (
						<Grid item key={filter.id}>
							<FilterChip
								label={chipLabelSelector({
									label: filter.label,
									val: filter.val,
									vals: filter.vals,
									ltVal: filter.ltVal,
									gtVal: filter.gtVal,
								})}
								color="primary"
								deleteIcon={<CloseIcon size="small" />}
								onDelete={() => {
									handleDelete(filter.id);
									SetError();
								}}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
		</>
	);
};
