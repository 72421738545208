import React, { useCallback, useState } from "react";
import { Box, Button, Container, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components/macro";
import DatePicker from "react-datepicker";
import { ErrorHelperText, PaperBase, SearchForm } from "../../styles/styles";
import { Titler } from "../common/Titler";
import EInvoiceStatusCount from "./eInvoiceStatusCount";
import { useProfile } from "../../hooks/profile";
import EWayBillStatusCount from "./eWayBillStatusCount";
import DailyCount from "./DailyCount";
import EInvoiceBuyerCount from "./eInvoiceBuyerCount";

const Dashboard = () => {
	// Greeting
	const { firstName } = useProfile();
	const currentHour = moment().format("HH");
	const afternoonLimit = 12;
	const eveningLimit = 17;

	let currentTimeofDay;
	if (currentHour >= afternoonLimit && currentHour <= eveningLimit)
		currentTimeofDay = "afternoon";
	else if (currentHour >= eveningLimit) currentTimeofDay = "evening";
	else currentTimeofDay = "morning";

	const { control, errors, handleSubmit, watch } = useForm({
		mode: "onTouched",
	});

	// Start Date + End Date

	const localStorageStartDate = localStorage.getItem("dashboardStartDate");
	const [startDate, setStartDate] = useState(
		localStorageStartDate && localStorageStartDate !== "null"
			? new Date(localStorageStartDate)
			: null
	);

	const localStorageEndDate = localStorage.getItem("dashboardEndDate");
	const [endDate, setEndDate] = useState(
		localStorageEndDate && localStorageEndDate !== "null" ? new Date(localStorageEndDate) : null
	);

	const [dateRange, setDateRange] = useState({
		startDate: moment(startDate).unix() || 1,
		endDate: moment(endDate).unix() || moment().unix(),
	});

	const { dateRangeLower, dateRangeHigher } = watch(["dateRangeLower", "dateRangeHigher"]);

	const dateRangeSearch = useCallback(() => {
		setDateRange({
			startDate: moment(startDate).unix() || 1,
			endDate: moment(endDate).unix() || moment().unix(),
		});

		localStorage.setItem("dashboardStartDate", startDate);
		localStorage.setItem("dashboardEndDate", endDate);
	}, [endDate, startDate]);

	const quickDateSearch = useCallback((range) => {
		let quickDateStart;
		let quickDateEnd = moment().endOf("day").toString();

		switch (range) {
			case "1D":
				quickDateStart = moment().subtract(1, "days").toString();
				quickDateEnd = moment().toString();
				setStartDate(new Date(moment().subtract(1, "days").toString()));
				break;
			case "1W":
				quickDateStart = moment()
					.subtract(1, "weeks")
					.add(1, "days")
					.startOf("day")
					.toString();
				setStartDate(
					new Date(moment().subtract(1, "weeks").add(1, "days").startOf("day").toString())
				);
				break;
			case "1M":
				quickDateStart = moment()
					.subtract(1, "months")
					.add(1, "days")
					.startOf("day")
					.toString();
				setStartDate(
					new Date(
						moment().subtract(1, "months").add(1, "days").startOf("day").toString()
					)
				);
				break;
			case "1Y":
				quickDateStart = moment()
					.subtract(1, "years")
					.add(1, "days")
					.startOf("day")
					.toString();
				setStartDate(
					new Date(moment().subtract(1, "years").add(1, "days").startOf("day").toString())
				);
				break;
			default:
				break;
		}

		setEndDate(new Date(quickDateEnd));

		setDateRange({
			startDate: moment(quickDateStart).unix() || 1,
			endDate: moment(quickDateEnd).unix(),
		});

		localStorage.setItem("dashboardStartDate", new Date(quickDateStart));
		localStorage.setItem("dashboardEndDate", new Date(quickDateEnd));
	}, []);

	// Total Count Tracker
	const [totalCount, setTotalCount] = useState({
		eInvoices: "",
		eWayBills: "",
	});

	return (
		<Container>
			<Titler title="Dashboard" />
			<Box mb={3}>
				<Grid container spacing={4} alignItems="center">
					<Grid item xs={4}>
						<Typography
							variant="h5"
							component="h1"
							align="center"
							css={`
								font-weight: 700;
							`}
						>
							Dashboard
						</Typography>
						<Typography
							variant="body2"
							align="center"
							css={`
								color: ${(props) => props.theme.light.font.grey};
							`}
							wrap="nowrap"
						>
							Good {currentTimeofDay} {firstName}!
						</Typography>
					</Grid>
					<Grid item xs>
						<PaperBase p={1} radius="5px" hoverShadow>
							<Grid
								container
								component={SearchForm}
								onSubmit={handleSubmit(dateRangeSearch)}
								spacing={1}
								css={`
									margin-bottom: -8px;
								`}
								wrap="nowrap"
								alignItems="center"
								autoComplete="off"
							>
								<Grid item xs={4}>
									<Controller
										render={() => (
											<DatePicker
												id="dateRangeLowerPicker"
												placeholderText="DD/MM/YYYY"
												dateFormat="dd/MM/yyyy"
												selected={startDate}
												onChange={(date) => setStartDate(date)}
												startDate={dateRangeLower}
												endDate={dateRangeHigher}
												customInput={
													<TextField
														variant="outlined"
														size="small"
														error={errors.dateRangeLower}
														label="Start Date"
														fullWidth
													/>
												}
												todayButton={
													(!dateRangeHigher ||
														moment(dateRangeHigher).isSameOrAfter(
															moment().format("DD/MM/YYYY")
														)) &&
													"Today"
												}
												showMonthDropdown
												showYearDropdown
												selectsStart
												isClearable
											/>
										)}
										name="dateRangeLower"
										valueName="selected"
										control={control}
										rules={{
											valueAsNumber: true,
										}}
										defaultValue={null}
									/>
									<ErrorHelperText>
										{errors.dateRangeLower?.message}
									</ErrorHelperText>
								</Grid>
								<Grid item xs={4}>
									<Controller
										render={() => (
											<DatePicker
												id="dateRangeHigherPicker"
												placeholderText="DD/MM/YYYY"
												dateFormat="dd/MM/yyyy"
												selected={endDate}
												onChange={(date) => setEndDate(date)}
												minDate={dateRangeLower}
												startDate={dateRangeLower}
												endDate={dateRangeHigher}
												customInput={
													<TextField
														variant="outlined"
														size="small"
														error={errors.dateRangeHigher}
														label="End Date"
														fullWidth
													/>
												}
												todayButton={
													(!dateRangeLower ||
														moment(dateRangeLower).isSameOrBefore(
															moment().format("DD/MM/YYYY")
														)) &&
													"Today"
												}
												showMonthDropdown
												showYearDropdown
												selectsEnd
												isClearable
											/>
										)}
										name="dateRangeHigher"
										valueName="selected"
										control={control}
										rules={{
											valueAsNumber: true,
										}}
										defaultValue={null}
									/>
									<ErrorHelperText>
										{errors.dateRangeHigher?.message}
									</ErrorHelperText>
								</Grid>

								<IconButton
									type="submit"
									title="Search"
									css={`
										height: fit-content;
										padding: 7px;
										margin-bottom: 3px;
									`}
								>
									<SearchIcon />
								</IconButton>
								<Grid item xs>
									<QuickDateButtons
										onClick={() => {
											quickDateSearch("1D");
										}}
										title="1 Day"
									>
										1D
									</QuickDateButtons>
								</Grid>
								<Grid item xs>
									<QuickDateButtons
										onClick={() => {
											quickDateSearch("1W");
										}}
										title="1 Week"
									>
										1W
									</QuickDateButtons>
								</Grid>
								<Grid item xs>
									<QuickDateButtons
										onClick={() => {
											quickDateSearch("1M");
										}}
										title="1 Month"
									>
										1M
									</QuickDateButtons>
								</Grid>
								<Grid item xs>
									<QuickDateButtons
										onClick={() => {
											quickDateSearch("1Y");
										}}
										title="1 Year"
									>
										1Y
									</QuickDateButtons>
								</Grid>
							</Grid>
						</PaperBase>
					</Grid>
				</Grid>
			</Box>

			<>
				<Box mb={2}>
					<Grid container spacing={5} alignItems="center">
						<Grid item xs={12} md={4}>
							<EInvoiceStatusCount
								fromDate={dateRange.startDate}
								toDate={dateRange.endDate}
								setTotalCount={setTotalCount}
							/>
						</Grid>

						<Grid item xs={12} md={8}>
							<EInvoiceBuyerCount
								fromDate={dateRange.startDate}
								toDate={dateRange.endDate}
								setTotalCount={setTotalCount}
							/>
						</Grid>
					</Grid>
				</Box>

				<Grid
					container
					spacing={3}
					alignItems="center"
					css={`
						margin-bottom: 16px;
					`}
				>
					<Grid item xs={12} md={8}>
						<DailyCount fromDate={dateRange.startDate} toDate={dateRange.endDate} />
					</Grid>

					<Grid item xs={12} md={4}>
						<EWayBillStatusCount
							fromDate={dateRange.startDate}
							toDate={dateRange.endDate}
							setTotalCount={setTotalCount}
						/>
					</Grid>
				</Grid>
			</>
		</Container>
	);
};

export default Dashboard;

const QuickDateButtons = styled(Button)`
	display: "inline-block";
	padding: 5px 5px;
	margin-bottom: 2.5px;
	min-height: 0;
	min-width: 0;
	color: ${(props) => props.theme.light.font.grey};
`;
