import React, { useState, useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Container, Typography, Grid } from "@material-ui/core";
import moment from "moment";
import { ErrorBox, PaperBase } from "../../styles/styles";
import ServerTable from "../common/ServerTable";
import { useProfile } from "../../hooks/profile";
import {} from "styled-components/macro";
import { findAllSubscriptions } from "../../services/stripeService";
import { paginate, sort } from "../../utils/TableUtils";

export const StripeSubscriptions = () => {
	const { orgId } = useProfile();

	const [loading, setLoading] = useState(false);

	const [subscriptionData, setSubscriptionData] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);

	const [subscriptionError, setSubscriptionError] = useState();

	const history = useHistory();

	const fetchSubscriptionData = useCallback(
		async ({ pageIndex, pageSize, sortBy }) => {
			setLoading(true);
			try {
				const { data } = await findAllSubscriptions(orgId);
				// console.log("subscriptions  Data: ", data);

				if (data !== undefined && data.data !== undefined) {
					let final_data = paginate(data.data, pageIndex, pageSize);
					if (sortBy.length) final_data = sort(final_data, sortBy);

					setSubscriptionData(final_data);

					let pageCnt = parseInt(data.data.length / pageSize, 10);

					if (data.data.length % pageSize !== 0) pageCnt += 1;

					setPageCount(pageCnt);

					setTotalRecords(data.data.length);
				} else setSubscriptionError("No Subscription(s) Found");
			} catch (e) {
				setSubscriptionError("No Subscription(s) Found");
			}
			setLoading(false);
		},
		[orgId]
	);

	const columns = useMemo(
		() => [
			{
				Header: "ID",
				accessor: "id",
				Cell: ({ row, value }) => (
					<Link id="subscriptionIDLink" to={`subscriptions/${row.original.id}`}>
						{value}
					</Link>
				),
			},
			{
				Header: "Plan",
				accessor: "plan.nickname",
			},

			{
				Header: "Quantity",
				accessor: "quantity",
			},

			{
				Header: "Status",
				accessor: "status",
				Cell: ({ value }) => (
					<span
						css={`
							font-weight: 600;
							text-transform: capitalize;
						`}
					>
						{value}
					</span>
				),
			},

			{
				Header: "Created Date",
				accessor: "created",
				Cell: ({ value }) => (value ? moment.unix(value).format("DD/MM/YYYY") : "N/A"),
			},

			{
				Header: "Total Amount",
				accessor: "plan.amount",
				Cell: ({ value }) => (
					<span
						css={`
							font-weight: 600;
						`}
					>
						{(Number(value) / 100).toLocaleString("en-IN", {
							style: "currency",
							currency: "INR",
							minimumFractionDigits: 2,
							maximumFractionDigits: 4,
						})}
					</span>
				),
				flipPosition: true,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history]
	);

	return (
		<Container>
			<PaperBase>
				<Box component={Grid} mb={3} container justify="space-between">
					<Typography variant="h5" component="h1">
						Subscriptions
					</Typography>
				</Box>

				{subscriptionError ? (
					<ErrorBox>{subscriptionError}</ErrorBox>
				) : (
					<ServerTable
						columns={columns}
						data={subscriptionData}
						fetchData={fetchSubscriptionData}
						loading={loading}
						pageCount={pageCount}
						totalRecords={totalRecords}
					/>
				)}
			</PaperBase>
		</Container>
	);
};

export default StripeSubscriptions;
