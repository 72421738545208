import React, { useRef, useState } from "react";
// import { useHistory } from "react-router-dom";

import { Auth } from "aws-amplify";
// import auth from "../../services/authService";

import { Form, Button, Modal, Col, Row, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { FaInfoCircle } from "react-icons/fa";
import { useSnackbar } from "notistack";
import { useUser } from "../../hooks/user";

export const PasswordChangeModal = ({
	isFirstTimeLogin,
	showModal,
	username,
	currentPassword,
	hideModal,
}) => {
	// let history = useHistory();
	const { logout } = useUser();
	const { enqueueSnackbar } = useSnackbar();

	const { register, errors, handleSubmit, watch } = useForm({
		mode: "onTouched",
	});

	// To check if passwords match
	const newPassword = useRef({});
	newPassword.current = watch("newPassword", "");

	// Loader
	const [loading, setLoading] = useState(false);

	// Error Msg
	const [currentPasswordIncorrect, setCurrentPasswordIncorrect] = useState();

	const passwordChangeSubmit = async (data) => {
		// Determine if temporary password needs to be changed
		if (isFirstTimeLogin) {
			setLoading(true);
			Auth.signIn(username, currentPassword)
				.then((user) => {
					Auth.completeNewPassword(user, data.newPassword, {})
						.then((user) => {
							console.log(user);
							logout();
							enqueueSnackbar("Password changed. Please login again.", {
								variant: "success",
							});
						})
						.catch((e) => {
							console.log("Error changing temporary password: ", e);
							setLoading(false);
						});
				})
				.catch((e) => {
					console.log("Error signing in: ", e);
					setLoading(false);
				});
		} else {
			Auth.currentAuthenticatedUser()
				.then((user) => {
					setLoading(true);
					return Auth.changePassword(user, data.currentPassword, data.newPassword);
				})
				.then((data) => {
					console.log(data);
					hideModal();
					enqueueSnackbar("Password Changed", {
						variant: "success",
					});
				})
				.catch((err) => {
					console.log(err);
					setCurrentPasswordIncorrect(err.message);
					setLoading(false);
				});
		}
	};

	// Tooltip for password format
	const pwFormatPopover = (
		<Popover id="pwFormatPopover">
			<Popover.Title as="h3">Password Requirements</Popover.Title>
			<Popover.Content>
				At least 8 characters long
				<br />
				At least 1 number
				<br />
				At least 1 special character
				<br />
				At least 1 uppercase character
				<br />
				At least 1 lowercase character
				<br />
			</Popover.Content>
		</Popover>
	);

	return (
		<Modal
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={showModal}
			backdrop={isFirstTimeLogin ? "static" : true}
			keyboard={!isFirstTimeLogin}
			onHide={hideModal}
		>
			<Modal.Header closeButton={!isFirstTimeLogin}>
				<Modal.Title id="contained-modal-title-vcenter">Change Password</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Col md={12}>
					{isFirstTimeLogin && (
						<p className="rejected">
							Your temporary password must be changed before proceeding.
						</p>
					)}
					<Form onSubmit={handleSubmit(passwordChangeSubmit)}>
						<Form.Group controlId="oldPassword">
							<Form.Label>Old Password</Form.Label>
							<Form.Control
								type="password"
								defaultValue={currentPassword}
								name="currentPassword"
								autoComplete="current-password"
								// TODO: use validate for onTouched validation, using currentAuthenticatedUser
								ref={register({
									required: "Required",
								})}
								disabled={isFirstTimeLogin}
							/>
							{errors.currentPassword && (
								<p className="form-error">{errors.currentPassword.message}</p>
							)}

							{currentPasswordIncorrect && (
								<p className="form-error">{currentPasswordIncorrect}</p>
							)}
						</Form.Group>
						<Form.Group controlId="newPassword">
							<Form.Label>
								New Password{" "}
								<OverlayTrigger placement="right" overlay={pwFormatPopover}>
									<FaInfoCircle style={{ cursor: "pointer" }} />
								</OverlayTrigger>
							</Form.Label>
							<Form.Control
								type="password"
								name="newPassword"
								autoComplete="new-password"
								ref={register({
									required: "Required",
									pattern: {
										value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
										message: "Invalid Password",
									},
								})}
							/>
							{errors.newPassword && (
								<p className="form-error">{errors.newPassword.message}</p>
							)}
						</Form.Group>
						<Form.Group controlId="confirmPassword">
							<Form.Label>Re-enter Password</Form.Label>
							<Form.Control
								type="password"
								name="confirmPassword"
								autoComplete="new-password"
								ref={register({
									validate: (value) =>
										value === newPassword.current || "Passwords don't match",
								})}
							/>
							{errors.confirmPassword && (
								<p className="form-error">{errors.confirmPassword.message}</p>
							)}
						</Form.Group>
						<br />
						<Form.Group as={Row} style={{ justifyContent: "flex-end" }}>
							<Col style={{ display: "flex", justifyContent: "flex-end" }}>
								{!isFirstTimeLogin && (
									<Button
										variant="outline-danger"
										className="mr-2"
										onClick={() => {
											hideModal();
										}}
										disabled={loading}
									>
										Cancel
									</Button>
								)}
								<Button type="submit" disabled={loading}>
									Continue
									{loading && (
										<Spinner
											className="button-spinner"
											animation="border"
											size="sm"
											role="status"
										>
											<span className="sr-only">Loading...</span>
										</Spinner>
									)}
								</Button>
							</Col>
						</Form.Group>
					</Form>
				</Col>
			</Modal.Body>
		</Modal>
	);
};

export default PasswordChangeModal;
