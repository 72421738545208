import React, { useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { Form, ListGroup } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/invoice.scss";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { PaperBox, Spinner } from "../../../../styles/styles";
import { defaultErrorToast } from "../../../../styles/global";
import { useProfile } from "../../../../hooks/profile";
import { Titler } from "../../../common/Titler";
import { uploadcXMLFile } from "../../../../services/gisService";

const XMLUpload = () => {
	const { orgId } = useProfile();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [uploadSpinner, setUploadSpinner] = useState(false);
	const [files, setFiles] = useState([]);
	const [sizeRejectCount, setSizeRejectCount] = useState(0);

	const onDropSuccess = async (acceptedFiles) => {
		if (files.length < 1) {
			const newFiles = files.concat(acceptedFiles);
			setFiles(newFiles);
		}
	};

	// Dealing with failed uploads, due to their size
	const onDropFailed = async (fileRejections) => {
		let count = 0;
		fileRejections.forEach((file) => {
			file.errors.forEach((error) => {
				if (error.code === "file-invalid-type") count -= 1;
				if (error.code === "file-too-large") count += 1;
			});
		});
		setSizeRejectCount(count);
	};

	const removeFromList = (index) => {
		setFiles(files.filter((file, i) => i !== index));
	};

	const [justUploaded, setJustUploaded] = useState(false);
	const FileUpload = async (event) => {
		try {
			setUploadSpinner(true);
			setJustUploaded(false);
			event.preventDefault();
			await uploadcXMLFile(files, orgId);
			enqueueSnackbar(`Invoice Uploaded`, {
				variant: "success",
			});
			setFiles([]);
			setUploadSpinner(false);
			// To Auto Refresh Table and scroll to it
			setJustUploaded(true);
			history.goBack();
		} catch (e) {
			console.log(e);
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
			setUploadSpinner(false);
		}
	};

	return (
		<Container disableGutters className="po-invoice-container">
			<Titler title="Invoice" />
			<PaperBox rounded className="upload-card" id="upload-card">
				<Typography variant="h5" gutterBottom>
					cXML Invoice
				</Typography>
				<Form onSubmit={FileUpload}>
					<Dropzone
						id="fileUploadDropzone1"
						maxFiles={1}
						onDrop={() => {
							setSizeRejectCount(0);
						}}
						onDropAccepted={onDropSuccess}
						onDropRejected={onDropFailed}
						accept=".xml"
						maxSize={1048576}
					>
						{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
							<div
								{...getRootProps()}
								className={`invoice-dropzone ${
									isDragActive && !isDragReject && "holding-files"
								}`}
							>
								<input {...getInputProps()} />
								<div className="text-center">
									{isDragActive ? (
										isDragReject ? (
											<p
												className="dropzone-content"
												style={{ color: "red" }}
											>
												ONLY XML FILE TYPE IS ALLOWED
											</p>
										) : (
											<p className="dropzone-content">
												Release to drop the file here
											</p>
										)
									) : (
										<p className="dropzone-content">
											Drag and drop XML file here, or click to select file.
											<br />
											<span style={{ fontWeight: 400 }}>
												(Max file size : 10MB)
											</span>
										</p>
									)}
									{sizeRejectCount > 0 && (
										<p
											className="dropzone-content"
											style={{ color: "red", fontWeight: 600 }}
										>
											{sizeRejectCount} file
											{sizeRejectCount === 1 ? " was" : "s were"} larger than
											10MB!
										</p>
									)}
								</div>
							</div>
						)}
					</Dropzone>
					<aside>
						<h5>Files Ready</h5>
						{files.length !== 0 ? (
							<ListGroup>
								{files.map((file, index) => (
									<ListGroup.Item key={index}>
										<span>
											{file.name} &nbsp; — &nbsp;{" "}
											{Math.round(file.size / 1024)} KB
										</span>
										<Button
											className="files-ready-delete"
											variant="text"
											color="primary"
											onClick={() => removeFromList(index)}
											disabled={!orgId}
											disableRipple
										>
											<span>Remove</span>
											<FaTimes />
										</Button>
									</ListGroup.Item>
								))}
							</ListGroup>
						) : (
							<p>No files uploaded yet.</p>
						)}
					</aside>

					<Box component={Grid} container mt={5} mb={3} justify="space-between">
						<Button
							variant="outlined"
							color="secondary"
							onClick={history.goBack}
							disableFocusRipple
						>
							Cancel
						</Button>
						<div>
							<Button
								style={{ fontSize: "0.87rem" }}
								id="retrieveDetailsButton"
								variant="contained"
								color="primary"
								type="submit"
								disableElevation
								disableFocusRipple
								fullWidth
								disabled={files.length === 0 || !orgId || uploadSpinner}
							>
								{uploadSpinner ? "Uploading" : "Upload XML File"}
								{uploadSpinner && <Spinner size={20} />}
							</Button>
						</div>
					</Box>
				</Form>
			</PaperBox>
		</Container>
	);
};

export default XMLUpload;
