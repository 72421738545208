/**
 * Smooth Scroll to the top
 *
 */
const smoothScroll = () => {
	if (window) {
		try {
			// Smooth scroll
			window.scroll({ top: 0, left: 0, behavior: "smooth" });
		} catch (e) {
			// Default if browser doesn't support smooth scroll
			window.scrollTo(0, 0);
		}
	}
};

export default smoothScroll;
