import React, { useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import {} from "styled-components/macro";
import { PaperBase, Spinner } from "../../styles/styles";
import { useProfile } from "../../hooks/profile";
import { geteInvoiceStatsByBuyer } from "../../services/statsService";

const EInvoiceBuyerCount = ({ fromDate, toDate, setTotalCount }) => {
	const { orgId } = useProfile();

	const [loading, setLoading] = useState(false);

	const [einVoiceData, setEInvoiceData] = useState({
		attributes: [],
		data: [],
		labels: [],
		total: "",
	});

	const colorPalette = [
		"#F42C3E",
		"#5A75F8",
		"#ffb300",
		"#ED217C",
		"#FF4A0D",
		"#00aeff",
		"#F42C3E",
		"#5A75F8",
		"#ffb300",
		"#ED217C",
		"#FF4A0D",
		"#00aeff",
	];

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const data = await geteInvoiceStatsByBuyer(orgId, fromDate, toDate);

				const formattedData = data.data.slice(0, 10).map((row, index) => ({
					value: row.count,
					itemStyle: {
						color: colorPalette[index],
					},
				}));

				// eslint-disable-next-line no-underscore-dangle
				const formattedLabels = data.data.slice(0, 10).map(
					(row) =>
						// eslint-disable-next-line no-underscore-dangle
						row._id
				);

				const total = formattedData.reduce((prev, current) => prev + current.value || 0, 0);
				setTotalCount((r) => ({ ...r, eInvoices: total }));

				setEInvoiceData({
					attributes: ["_id", "count"],
					data: formattedData,
					labels: formattedLabels,
					total,
				});
			} catch (e) {
				// console.log(e);
				setEInvoiceData({
					attributes: [],
					data: [],
					labels: [],
					total: "",
				});
			}
			setLoading(false);
		})();
	}, [orgId, fromDate, toDate, setTotalCount]);

	const options = useMemo(
		() => ({
			title: {
				text: "eInvoices By Customer",
				left: "center",
				top: 15,
				textStyle: {
					color: "#222",
					fontFamily: "Open Sans",
					fontWeight: 600,
				},
			},
			toolbox: {
				show: true,
				showTitle: false,
				right: 10,
				top: 10,
				feature: {
					mark: { show: true },
					saveAsImage: { show: true },
				},
			},
			grid: { containLabel: true, top: 60, bottom: 20, left: 15, right: 35 },
			xAxis: {
				axisLabel: { color: "#222", fontFamily: "Open Sans", fontWeight: 600 },
			},
			yAxis: {
				inverse: true,
				data: einVoiceData.labels,
				axisLabel: { color: "#222", fontFamily: "Open Sans", fontWeight: 600 },
			},
			series: [
				{
					type: "bar",
					data: einVoiceData.data,
					realtimeSort: true,
					label: {
						color: "black",
						show: true,
						position: "right",
						fontWeight: 600,
						fontSize: "0.9rem",
						offset: [0, 2],
					},
					barWidth: "30%",
				},
			],
		}),
		[einVoiceData.data, einVoiceData.labels]
	);

	return (
		<PaperBase
			p="0px"
			radius="5px"
			css={`
				height: 100%;
				min-height: 100%;
			`}
		>
			{loading ? (
				<div
					css={`
						display: flex;
						height: 100%;
						place-items: center;
						place-content: center;
						padding: 30px;
					`}
				>
					<Spinner ml="0px" />
				</div>
			) : (
				<ReactECharts
					option={options}
					css={`
						& div canvas {
							border-radius: 5px;
						}
					`}
					style={{ height: "360px" }}
				/>
			)}
		</PaperBase>
	);
};

export default EInvoiceBuyerCount;
