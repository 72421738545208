import React from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";

import { Container } from "@material-ui/core";
import { Row } from "react-bootstrap";

import StartPage from "./components/Start/StartPage";
import Dashboard from "./components/Dashboard/Dashboard";

import { TopBar } from "./components/Navbar/Topbar/Topbar";
import SideBar from "./components/Navbar/Sidebar/Sidebar";
import Footer from "./components/common/Footer";
import Error404 from "./components/common/404";
import ProfileManagement from "./components/Profile/Profile";

import UserList from "./components/Users/UserList";
import UserEdit from "./components/Users/UserEdit";
import UserCreate from "./components/Users/UserCreate";
import "./styles/global.scss";

import AutoLogoutTimer from "./utils/AutoLogOutTimer";
import { PasswordChangeModal } from "./components/Start/PasswordChange";
import { useUser } from "./hooks/user";
import TermsAndConditions from "./components/Start/TermsAndConditions";
import { ProfileProvider, useProfile } from "./hooks/profile";
import EInvoices from "./components/eInvoices/EInvoiceHome";
import OrganizationEdit from "./components/Organizations/OrganizationEdit";
import CustomerCreate from "./components/Customers/CustomerCreate";
import CustomerEdit from "./components/Customers/CustomerEdit";
import CustomerList from "./components/Customers/CustomerList";
import EInvoiceDetail from "./components/eInvoices/view/EInvoiceDetail";
import EWayBills from "./components/eWayBills/EWayBillHome";
import EWayBillDetail from "./components/eWayBills/view/EWayBillDetail";
import GenerateEInvoice from "./components/eInvoices/create/GenerateEInvoice";
import GenerateEWayBill from "./components/eWayBills/create/GenerateEWayBill";
import InvoiceValidate from "./components/eInvoices/upload/pdf/validation/InvoiceValidation";
import PDFUpload from "./components/eInvoices/upload/pdf/PDFUpload";
import StripeForm from "./components/stripe/stripe";
import StripeSuccess from "./components/stripe/stripesuccess";
import StripeCancel from "./components/stripe/stripecancel";
import AdminMainMenu from "./components/admin/Main";
import GSTNDetails from "./components/admin/GSTNDetails";
import AdminOrganizationEdit from "./components/admin/OrganizationUpdate";
import HelpCenter from "./components/Support/Help Center/HelpCenter";
import ContactUs from "./components/Support/ContactUs";
import StripeSubscriptions from "./components/stripe/subscriptions";
import StripeSubscriptionDetail from "./components/stripe/subscriptionDetail";
import SearchDetail from "./components/search/searchDetail";

const App = () => {
	const { user } = useUser();

	if (user !== undefined)
		return user ? (
			<AutoLogoutTimer
				ComposedComponent={() => (
					<ProfileProvider>
						<HomeRoutes />
					</ProfileProvider>
				)}
			/>
		) : (
			<LoginRoutes />
		);

	return null;
};

const LoginRoutes = () => {
	const location = useLocation();
	return (
		<Switch>
			<Route
				exact
				path="/login"
				render={() => (
					<StartPage
						prevLoc={location.state && location.state.prevLoc}
						tabbedTo="login"
					/>
				)}
			/>

			<Route
				exact
				path="/signup"
				render={() => (
					<StartPage
						prevLoc={location.state && location.state.prevLoc}
						tabbedTo="signup"
					/>
				)}
			/>

			<Route exact path="/otp" render={() => <StartPage tabbedTo="otp" />} />

			<Redirect to="/login" />
		</Switch>
	);
};

const HomeRoutes = () => {
	const location = useLocation();
	const { hasProfileLoaded } = useProfile();

	return (
		<>
			{location.state?.FirstTimeLogin?.boolean ? (
				<PasswordChangeModal
					isFirstTimeLogin={location.state.FirstTimeLogin.boolean}
					showModal
					username={location.state.FirstTimeLogin.username}
					currentPassword={location.state.FirstTimeLogin.currentPassword}
				/>
			) : hasProfileLoaded ? (
				<MainContent />
			) : (
				// TODO: Add a SkyscendPay loading screen for initial login; Currently:
				<span style={{ fontFamily: "Open Sans" }}>L O A D I N G</span>
			)}
		</>
	);
};

const MainContent = () => {
	const { termsAccepted, orgActive, orgSuperAdmin } = useProfile();
	return termsAccepted ? (
		<>
			<TopBar />
			<Row className="row-set home-row">
				<SideBar />

				<Container className="base-container container-set logged-in">
					{orgActive === true &&
						(orgSuperAdmin === false || orgSuperAdmin === undefined) && (
							<StaticRoutes />
						)}

					{orgActive === false &&
						(orgSuperAdmin === false || orgSuperAdmin === undefined) && (
							<StaticPaymentRoutes />
						)}
					{orgActive === true && orgSuperAdmin === true && <StaticAdminRoutes />}
				</Container>
			</Row>
			<Footer />
		</>
	) : (
		<TermsAndConditions />
	);
};

const StaticRoutes = () => (
	<Switch>
		<Route exact path="/" render={() => <Dashboard />} />
		<Route path={["/login", "/signup", "/otp"]}>
			<Redirect to="/" />
		</Route>
		<Route exact path="/dashboard" render={() => <Dashboard />} />
		<Route exact path="/profile" render={() => <ProfileManagement />} />
		<Route exact path="/orgmgmt" component={() => <OrganizationEdit />} />
		<Route exact path="/usermgmt" component={() => <UserList />} />
		<Route exact path="/usermgmt/user/modify/:userId" render={() => <UserEdit />} />
		<Route exact path="/usermgmt/user/add" render={() => <UserCreate />} />
		<Route exact path="/eInvoices" render={() => <EInvoices />} />
		<Route exact path="/eInvoices/:irn" render={() => <EInvoiceDetail />} />
		<Route exact path="/eInvoices/generate/list" render={() => <GenerateEInvoice />} />
		<Route exact path="/eWayBills" render={() => <EWayBills />} />
		<Route exact path="/eWayBills/:ewaybillNBR" render={() => <EWayBillDetail />} />
		<Route exact path="/eWayBills/generate/list" render={() => <GenerateEWayBill />} />
		<Route exact path="/eInvoices/generate/upload/pdf" component={() => <PDFUpload />} />
		<Route exact path="/customermgmt" component={() => <CustomerList />} />
		<Route exact path="/eInvoices/invoice/validate" component={() => <InvoiceValidate />} />
		<Route exact path="/stripe/payment" component={() => <StripeForm />} />
		<Route exact path="/stripe/payment/success" component={() => <StripeSuccess />} />
		<Route exact path="/stripe/payment/cancel" component={() => <StripeCancel />} />
		<Route exact path="/stripe/subscriptions" render={() => <StripeSubscriptions />} />
		<Route exact path="/global/search/:documentId" render={() => <SearchDetail />} />
		<Route
			exact
			path="/stripe/subscriptions/:subscriptionId"
			render={() => <StripeSubscriptionDetail />}
		/>
		<Route exact path="/geis/admin" component={() => <AdminMainMenu />} />
		<Route exact path="/geis/admin/gstn/:gstn" component={() => <GSTNDetails />} />
		<Route exact path="/geis/admin/org/:orgId" component={() => <AdminOrganizationEdit />} />
		<Route exact path="/faq" render={() => <HelpCenter />} />
		<Route exact path="/contactus" render={() => <ContactUs />} />
		<Route
			exact
			path="/customermgmt/customer/modify/:customerId"
			render={() => <CustomerEdit />}
		/>
		<Route exact path="/customermgmt/customer/add" render={() => <CustomerCreate />} />
		<Route path="*">
			<Error404 />
		</Route>
	</Switch>
);

const StaticAdminRoutes = () => (
	<Switch>
		<Route exact path="/" render={() => <AdminMainMenu />} />
		<Route path={["/login", "/signup", "/otp"]}>
			<Redirect to="/" />
		</Route>
		<Route exact path="/dashboard" render={() => <Dashboard />} />
		<Route exact path="/profile" render={() => <ProfileManagement />} />
		<Route exact path="/orgmgmt" component={() => <OrganizationEdit />} />
		<Route exact path="/usermgmt" component={() => <UserList />} />
		<Route exact path="/usermgmt/user/modify/:userId" render={() => <UserEdit />} />
		<Route exact path="/usermgmt/user/add" render={() => <UserCreate />} />
		<Route exact path="/eInvoices" render={() => <EInvoices />} />
		<Route exact path="/eInvoices/:irn" render={() => <EInvoiceDetail />} />
		<Route exact path="/eInvoices/generate/list" render={() => <GenerateEInvoice />} />
		<Route exact path="/eWayBills" render={() => <EWayBills />} />
		<Route exact path="/eWayBills/:ewaybillNBR" render={() => <EWayBillDetail />} />
		<Route exact path="/eWayBills/generate/list" render={() => <GenerateEWayBill />} />
		<Route exact path="/eInvoices/generate/upload/pdf" component={() => <PDFUpload />} />
		<Route exact path="/customermgmt" component={() => <CustomerList />} />
		<Route exact path="/eInvoices/invoice/validate" component={() => <InvoiceValidate />} />
		<Route exact path="/stripe/payment" component={() => <StripeForm />} />
		<Route exact path="/stripe/payment/success" component={() => <StripeSuccess />} />
		<Route exact path="/stripe/payment/cancel" component={() => <StripeCancel />} />
		<Route exact path="/stripe/subscriptions" render={() => <StripeSubscriptions />} />
		<Route exact path="/global/search/:documentId" render={() => <SearchDetail />} />
		<Route
			exact
			path="/stripe/subscriptions/:subscriptionId"
			render={() => <StripeSubscriptionDetail />}
		/>
		<Route exact path="/geis/admin" component={() => <AdminMainMenu />} />
		<Route exact path="/geis/admin/gstn/:gstn" component={() => <GSTNDetails />} />
		<Route exact path="/geis/admin/org/:orgId" component={() => <AdminOrganizationEdit />} />
		<Route exact path="/faq" render={() => <HelpCenter />} />
		<Route exact path="/contactus" render={() => <ContactUs />} />
		<Route
			exact
			path="/customermgmt/customer/modify/:customerId"
			render={() => <CustomerEdit />}
		/>
		<Route exact path="/customermgmt/customer/add" render={() => <CustomerCreate />} />
		<Route path="*">
			<Error404 />
		</Route>
	</Switch>
);

const StaticPaymentRoutes = () => (
	<Switch>
		<Route exact path="/" render={() => <StripeForm />} />
		<Route path={["/login", "/signup", "/otp"]}>
			<Redirect to="/" />
		</Route>
		<Route exact path="/dashboard" render={() => <Dashboard />} />
		<Route exact path="/profile" render={() => <ProfileManagement />} />
		<Route exact path="/orgmgmt" component={() => <OrganizationEdit />} />
		<Route exact path="/usermgmt" component={() => <UserList />} />
		<Route exact path="/usermgmt/user/modify/:userId" render={() => <UserEdit />} />
		<Route exact path="/usermgmt/user/add" render={() => <UserCreate />} />
		<Route exact path="/eInvoices" render={() => <EInvoices />} />
		<Route exact path="/eInvoices/:irn" render={() => <EInvoiceDetail />} />
		<Route exact path="/eInvoices/generate/list" render={() => <GenerateEInvoice />} />
		<Route exact path="/eWayBills" render={() => <EWayBills />} />
		<Route exact path="/eWayBills/:ewaybillNBR" render={() => <EWayBillDetail />} />
		<Route exact path="/eWayBills/generate/list" render={() => <GenerateEWayBill />} />
		<Route exact path="/eInvoices/generate/upload/pdf" component={() => <PDFUpload />} />
		<Route exact path="/customermgmt" component={() => <CustomerList />} />
		<Route exact path="/eInvoices/invoice/validate" component={() => <InvoiceValidate />} />
		<Route exact path="/stripe/payment" component={() => <StripeForm />} />
		<Route exact path="/stripe/payment/success" component={() => <StripeSuccess />} />
		<Route exact path="/stripe/payment/cancel" component={() => <StripeCancel />} />
		<Route exact path="/stripe/subscriptions" render={() => <StripeSubscriptions />} />
		<Route exact path="/global/search/:documentId" render={() => <SearchDetail />} />
		<Route
			exact
			path="/stripe/subscriptions/:subscriptionId"
			render={() => <StripeSubscriptionDetail />}
		/>
		<Route exact path="/geis/admin" component={() => <AdminMainMenu />} />
		<Route exact path="/geis/admin/gstn/:gstn" component={() => <GSTNDetails />} />
		<Route exact path="/geis/admin/org/:orgId" component={() => <AdminOrganizationEdit />} />
		<Route exact path="/faq" render={() => <HelpCenter />} />
		<Route exact path="/contactus" render={() => <ContactUs />} />
		<Route
			exact
			path="/customermgmt/customer/modify/:customerId"
			render={() => <CustomerEdit />}
		/>
		<Route exact path="/customermgmt/customer/add" render={() => <CustomerCreate />} />
		<Route path="*">
			<Error404 />
		</Route>
	</Switch>
);

export default App;
