import React, { useState } from "react";
import { Stepper, Step, StepLabel, Container, Grid } from "@material-ui/core";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { Titler } from "../../common/Titler";
import smoothScroll from "../../../utils/SmoothScroll";
import { useProfile } from "../../../hooks/profile";

export default () => {
	const { orgId } = useProfile();

	const [activeStep, setActiveStep] = useState(0);

	// Step Titles
	const steps = ["Transport Details", "Dispatch Info", "Shipping Info", "Review"];
	const [ewayBillData, setEwayBillData] = useState({});

	// Stepper Buttons
	const handleNext = () => {
		smoothScroll();
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		smoothScroll();
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const getValidationWorkflowStep = (stepIndex) => {
		switch (stepIndex) {
			case 0:
				return (
					<Step1
						orgId={orgId}
						ewayBillData={ewayBillData}
						setEwayBillData={setEwayBillData}
						handleNext={handleNext}
					/>
				);

			case 1:
				return (
					<Step2
						orgId={orgId}
						ewayBillData={ewayBillData}
						setEwayBillData={setEwayBillData}
						handleBack={handleBack}
						handleNext={handleNext}
					/>
				);
			case 2:
				return (
					<Step3
						orgId={orgId}
						ewayBillData={ewayBillData}
						setEwayBillData={setEwayBillData}
						handleBack={handleBack}
						handleNext={handleNext}
					/>
				);
			case 3:
				return (
					<Step4
						orgId={orgId}
						ewayBillData={ewayBillData}
						setEwayBillData={setEwayBillData}
						handleBack={handleBack}
					/>
				);
			default:
				return "Unknown Step!";
		}
	};

	return (
		<Container>
			<Titler title="Create eWayBill" />

			<Grid container>
				<Grid item xs={12}>
					<Stepper
						activeStep={activeStep}
						style={{ paddingLeft: 0, paddingRight: 0, marginBottom: "2rem" }}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>

					{getValidationWorkflowStep(activeStep)}
				</Grid>
			</Grid>
		</Container>
	);
};
