import React from "react";
import { Grid, Typography, Container } from "@material-ui/core";
import InputField from "../../../common/InputField";
import SelectField from "../../../common/SelectField";
import { PaperBox } from "../../../../styles/styles";
import {} from "styled-components/macro";

const employeeCount = [
	{
		value: "1-10",
		label: "1-10",
	},
	{
		value: "11-100",
		label: "11-100",
	},
	{
		value: "100-500",
		label: "100-500",
	},
	{
		value: "> 500",
		label: "> 500",
	},
];

const lastYrRevenue = [
	{
		value: "50-75 Cr",
		label: "₹ 50-75 Cr",
	},
	{
		value: "75-100 Cr",
		label: "₹ 75-100 Cr",
	},
	{
		value: "100-500 Cr",
		label: "₹ 100-500 Cr",
	},
	{
		value: "Over 500 Cr",
		label: "Over ₹ 500 Cr",
	},
];

const avgRevenue = [
	{
		value: "10-50 Cr",
		label: "₹ 10-50 Cr",
	},
	{
		value: "50-75 Cr",
		label: "₹ 50-75 Cr",
	},
	{
		value: "75-100 Cr",
		label: "₹ 75-100 Cr",
	},
	{
		value: "100-500 Cr",
		label: "₹ 100-500 Cr",
	},
	{
		value: "Over 500 Cr",
		label: "Over ₹ 500 Cr",
	},
];

const invoicesPerMonth = [
	{
		value: "< 500",
		label: "< 500",
	},
	{
		value: "500-1000",
		label: "500-1000",
	},
	{
		value: "1000-5000",
		label: "1000-5000",
	},
	{
		value: "> 5000",
		label: "> 5000",
	},
];

export default function OrganisationForm(props) {
	const {
		formField: {
			orgName,
			contactName,
			contactNumber,
			contactEmail,
			addressLine1,
			addressLine2,
			city,
			state,
			country,
			postalCode,
			orgPanNumber,
			orgGstNumber,
			lastYearRevenue,
			avgRevenueForLast3yrs,
			yearOfIncorporation,
			numberOfEmployee,
			numberOfInvoicesPerMonth,
		},
	} = props;
	return (
		<>
			<PaperBox rounded>
				<Typography
					variant="h6"
					css={`
						margin-bottom: 24px;
					`}
				>
					General Information
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<InputField name={orgName.name} label={orgName.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<InputField name={contactName.name} label={contactName.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<InputField
							name={contactNumber.name}
							label={contactNumber.label}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<InputField name={contactEmail.name} label={contactEmail.label} fullWidth />
					</Grid>
				</Grid>
			</PaperBox>
			<PaperBox rounded>
				<Typography
					variant="h6"
					css={`
						margin-bottom: 24px;
					`}
				>
					Address
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<InputField name={addressLine1.name} label={addressLine1.label} fullWidth />
					</Grid>
					<Grid item xs={12}>
						<InputField name={addressLine2.name} label={addressLine2.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={3}>
						<InputField name={city.name} label={city.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={3}>
						<InputField name={state.name} label={state.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={3}>
						<InputField name={country.name} label={country.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={3}>
						<InputField name={postalCode.name} label={postalCode.label} fullWidth />
					</Grid>
				</Grid>
			</PaperBox>
			<PaperBox rounded>
				<Typography
					variant="h6"
					css={`
						margin-bottom: 24px;
					`}
				>
					Details
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<InputField name={orgPanNumber.name} label={orgPanNumber.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={3}>
						<InputField name={orgGstNumber.name} label={orgGstNumber.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={3}>
						<SelectField
							name={lastYearRevenue.name}
							label={lastYearRevenue.label}
							data={lastYrRevenue}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={3}>
						<SelectField
							name={avgRevenueForLast3yrs.name}
							label={avgRevenueForLast3yrs.label}
							data={avgRevenue}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<SelectField
							name={numberOfInvoicesPerMonth.name}
							label={numberOfInvoicesPerMonth.label}
							data={invoicesPerMonth}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<SelectField
							name={numberOfEmployee.name}
							label={numberOfEmployee.label}
							data={employeeCount}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<InputField
							name={yearOfIncorporation.name}
							label={yearOfIncorporation.label}
							fullWidth
						/>
					</Grid>
				</Grid>
			</PaperBox>
		</>
	);
}
