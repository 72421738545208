import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import {} from "styled-components/macro";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
import { defaultErrorToast } from "../../../styles/global";
import { createWayBill } from "../../../services/gisService";
import { PaperBox } from "../../../styles/styles";

const ConfirmStep4 = ({ orgId, ewayBillData, handleBack }) => {
	const { enqueueSnackbar } = useSnackbar();
	console.log("Step 4 Data: ", ewayBillData);

	const history = useHistory();

	const [isLoading, setGenerateButton] = useState(false);

	const generateWayBillFunction = async () => {
		try {
			setGenerateButton(true);

			const formattedData = {
				...ewayBillData,
			};

			_.mapKeys(formattedData, (value, key) => {
				if (_.isEmpty(value)) delete formattedData[key];
			});

			const responseData = await createWayBill(orgId, formattedData);
			enqueueSnackbar(`eWay Bill created. ${responseData.data.ewaybillNBR}`, {
				variant: "success",
			});
			history.push("/eWayBills");
		} catch (e) {
			setGenerateButton(false);
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
		}
	};

	const handleCancel = () => {
		history.push("/eWayBills");
	};

	return (
		<>
			<Typography variant="h5" component="h1" gutterBottom>
				Summary
			</Typography>

			<PaperBox rounded>
				<Typography variant="h6" gutterBottom>
					Transport Info
				</Typography>
				<Grid container spacing={2}>
					<Grid item className="data-display" xs={12} md={4}>
						<span>IRN</span>
						<span>{ewayBillData.invoiceRefNBR || "N/A"}</span>
					</Grid>
				</Grid>
			</PaperBox>

			{ewayBillData.transportInfo.gstin && (
				<PaperBox rounded>
					<Typography variant="h6" gutterBottom>
						Transport Details
					</Typography>
					<Grid container spacing={2}>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Transporter GSTN</span>
							<span>{ewayBillData.transportInfo.gstin || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Name</span>
							<span>{ewayBillData.transportInfo.name || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Mode</span>
							<span>{ewayBillData.transportInfo.mode || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Distance</span>
							<span>{ewayBillData.transportInfo.distance || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Vehicle Number</span>
							<span>{ewayBillData.transportInfo.vehicleNBR || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Vehicle Type</span>
							<span>{ewayBillData.transportInfo.vehicleType || "N/A"}</span>
						</Grid>
					</Grid>
				</PaperBox>
			)}

			{ewayBillData.dispatchInfo !== undefined && ewayBillData.dispatchInfo.name && (
				<PaperBox rounded>
					<Typography variant="h6" gutterBottom>
						Dispatch Info
					</Typography>
					<Grid container spacing={2}>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Name</span>
							<span>{ewayBillData.dispatchInfo.name || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Address Line 1</span>
							<span>{ewayBillData.dispatchInfo.addressLine1 || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Address Line 2</span>
							<span>{ewayBillData.dispatchInfo.addressLine2 || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>City</span>
							<span>{ewayBillData.dispatchInfo.city || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>State</span>
							<span>{ewayBillData.dispatchInfo.state || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Postal Code</span>
							<span>{ewayBillData.dispatchInfo.pin || "N/A"}</span>
						</Grid>
					</Grid>
				</PaperBox>
			)}

			{ewayBillData.shippingInfo !== undefined && ewayBillData.shippingInfo.addressLine1 && (
				<PaperBox rounded>
					<Typography variant="h6" gutterBottom>
						Shipping Info
					</Typography>
					<Grid container spacing={2}>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Address Line 1</span>
							<span>{ewayBillData.shippingInfo.addressLine1 || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Address Line 2</span>
							<span>{ewayBillData.shippingInfo.addressLine2 || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>City</span>
							<span>{ewayBillData.shippingInfo.city || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>State</span>
							<span>{ewayBillData.shippingInfo.state || "N/A"}</span>
						</Grid>
						<Grid item className="data-display" xs={12} md={4}>
							<span>Postal Code</span>
							<span>{ewayBillData.shippingInfo.pin || "N/A"}</span>
						</Grid>
					</Grid>
				</PaperBox>
			)}

			<Box component={Grid} mb={3} container justify="space-between">
				<Grid item>
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Exit
					</Button>
				</Grid>
				<Grid item>
					<Button onClick={handleBack} style={{ marginRight: "1rem" }} disableFocusRipple>
						Back
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={generateWayBillFunction}
						disabled={isLoading}
						disableFocusRipple
					>
						{isLoading ? "Generating eWayBill" : "Generate eWayBill"}
						{isLoading && <Spinner size={20} />}
					</Button>
				</Grid>
			</Box>
		</>
	);
};

export default ConfirmStep4;
