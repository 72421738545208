import React, { useState } from "react";
import { Form } from "react-bootstrap";
import QRCode from "qrcode.react";
import { Box, Button, Container, Grid, IconButton } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import { defaultErrorToast } from "../../../styles/global";
import { useProfile } from "../../../hooks/profile";
import { Label, PaperBase, RequiredStar } from "../../../styles/styles";
import { downloadInvoice, geteInvoiceResponse } from "../../../services/gisService";
import {} from "styled-components/macro";
import SelectIRNModal from "../SelectIRNModal";

export const DownloadEInvoice = () => {
	const { orgId } = useProfile();
	const { enqueueSnackbar } = useSnackbar();
	const [invoiceDetail, setInvoiceDetails] = useState();

	const { register, errors, handleSubmit, reset, clearErrors } = useForm({
		mode: "onTouched",
		defaultValues: {},
	});

	const [isIRNSelected, setIRNSelected] = useState(true);
	const [selectedIRN, setSelectedIRN] = useState();

	const openIRNDialog = () => {
		setShowIRNModal(true);
	};

	const [showIRNSearchModal, setShowIRNModal] = useState(!isIRNSelected);
	const handleIRNModalClose = () => setShowIRNModal(false);

	const irnSelector = (choosenIRN) => {
		setSelectedIRN(choosenIRN);
		setIRNSelected(true);
		if (errors.irn) {
			errors.irn.message = "";
		}
	};

	// Submit
	const submitRequest = async (data) => {
		try {
			const invoiceData = await geteInvoiceResponse(orgId, data.irn);
			if (invoiceData && invoiceData.data && invoiceData.data.decodedQRCode) {
				setInvoiceDetails(invoiceData.data);
			}
		} catch (e) {
			enqueueSnackbar(defaultErrorToast(e), {
				variant: "error",
			});
			setInvoiceDetails();
		}
	};

	const downloadQRCode = async () => {
		const fileURL = await downloadInvoice(orgId, invoiceDetail.invoiceRefNBR);
		window.open(fileURL, "_blank", "fullscreen=yes");
	};

	return (
		<Container>
			<Box pt={3} pb="28px">
				<Form onSubmit={handleSubmit(submitRequest)}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<Form.Group controlId="irn">
								<Label>
									<span>
										Invoice Reference Number (IRN)
										<RequiredStar />
										<Box component={Tooltip} ml={1} title="Search">
											<IconButton
												color="primary"
												variant="contained"
												aria-label="search"
												component="span"
												size="small"
												onClick={openIRNDialog}
											>
												<SearchIcon />
											</IconButton>
										</Box>
									</span>
									{errors.irn && (
										<p className="form-error-inline">{errors.irn.message}</p>
									)}
								</Label>
								<Form.Control
									name="irn"
									type="text"
									value={selectedIRN}
									ref={register({
										required: "Required",
									})}
									onChange={(e) => {
										setSelectedIRN(e.target.value);
									}}
								/>
								<SelectIRNModal
									orgId={orgId}
									irnSelector={irnSelector}
									showIRNSearchModal={showIRNSearchModal}
									handleClose={handleIRNModalClose}
								/>
							</Form.Group>
						</Grid>

						<Grid item container justify="space-between" alignItems="center">
							<Grid item xs={6}>
								<Button
									color="secondary"
									onClick={() => {
										setSelectedIRN();
										setInvoiceDetails();
										reset();
										clearErrors();
									}}
									css={`
										padding: 0;
										font-weight: 600;
									`}
								>
									Clear
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="outlined"
									color="primary"
									type="submit"
									disableElevation
								>
									View Invoice
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Form>

				{invoiceDetail && (
					<>
						<PaperBase gap>
							<Grid container spacing={2} justify="center" align="center">
								<Grid item xs={12} md={12}>
									<QRCode
										id="qrCode"
										size={200}
										includeMargin="true"
										mountNode
										value={JSON.stringify(invoiceDetail.decodedQRCode)}
									/>
								</Grid>
							</Grid>

							<Grid container spacing={2}>
								<Grid item className="data-display" xs={12} md={12}>
									<span>Invoice Reference Number</span>
									<span>{invoiceDetail.invoiceRefNBR || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>Acknowledgement Number</span>
									<span>{invoiceDetail.ackNBR || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>Acknowledge Date</span>
									<span>{invoiceDetail.ackDate || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>Status</span>
									<span>{invoiceDetail.status || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>eWay Bill Number</span>
									<span>{invoiceDetail.eWayBillNBR || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>eWay Bill Date</span>
									<span>{invoiceDetail.eWayBillGenDate || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>eWay Bill Expiry Date</span>
									<span>{invoiceDetail.eWayBillExpriyDate || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>Seller GSTN</span>
									<span>{invoiceDetail.decodedQRCode.SellerGstin || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>Buyer GSTN </span>
									<span>{invoiceDetail.decodedQRCode.BuyerGstin || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>Document Number</span>
									<span>{invoiceDetail.decodedQRCode.DocNo || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>Document Type </span>
									<span>{invoiceDetail.decodedQRCode.DocTyp || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>Total Invoice Value</span>
									<span>{invoiceDetail.decodedQRCode.TotInvVal || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>Item Count </span>
									<span>{invoiceDetail.decodedQRCode.ItemCnt || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>HSN Code</span>
									<span>{invoiceDetail.decodedQRCode.MainHsnCode || "N/A"}</span>
								</Grid>

								<Grid item className="data-display" xs={12} md={6}>
									<span>IRN Date </span>
									<span>{invoiceDetail.decodedQRCode.IrnDt || "N/A"}</span>
								</Grid>
							</Grid>

							<Grid container spacing={2} justify="center" align="center">
								<Grid item xs={12} md={12}>
									<Button
										variant="outlined"
										color="secondary"
										onClick={downloadQRCode}
										disableFocusRipple
									>
										Download Invoice
									</Button>
								</Grid>
							</Grid>
						</PaperBase>
					</>
				)}
			</Box>
		</Container>
	);
};

export default DownloadEInvoice;
