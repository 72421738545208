import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import {} from "styled-components/macro";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { Label, PaperBox, RequiredStar } from "../../../styles/styles";

export default ({ orgId, ewayBillData, setEwayBillData, handleBack, handleNext }) => {
	const history = useHistory();

	useEffect(() => {
		// console.log("Step 3 Entry: ", ewayBillData);
	}, [ewayBillData]);

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {
			...ewayBillData,
		},
	});

	const NextStep = (data) => {
		// console.log(data);

		const shippingInfo = {
			addressLine1: data.shippingAddressLine1,
			addressLine2: data.shippingAddressLine2,
			city: data.shippingCity,
			state: data.shippingState,
			pin: data.shippingPostalCode,
		};

		_.mapKeys(shippingInfo, (value, key) => {
			if (_.isEmpty(value)) delete shippingInfo[key];
		});

		setEwayBillData({ ...ewayBillData, shippingInfo });
		// console.log("Step 3 Exit: ", { ...ewayBillData });
		handleNext();
	};

	const handleCancel = () => {
		history.push("/eWayBills");
	};

	const handleSkip = () => {
		handleNext();
	};

	return (
		<>
			<Form onSubmit={handleSubmit(NextStep)}>
				<Box component={Grid} mb={5} container justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							onClick={handleBack}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleSkip}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Skip
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>

				<PaperBox rounded>
					<Typography variant="h6" gutterBottom>
						Shipping Info
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Form.Group controlId="shippingAddressLine1">
								<Label>
									<span>
										Address Line 1 <RequiredStar />
									</span>
									{errors.shippingAddressLine1 && (
										<p className="form-error-inline">
											{errors.shippingAddressLine1.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="shippingAddressLine1"
									type="text"
									defaultValue={
										ewayBillData.shippingInfo !== undefined &&
										ewayBillData.shippingInfo.addressLine1 !== undefined
											? ewayBillData.shippingInfo.addressLine1
											: ""
									}
									ref={register({ required: "Required" })}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="shippingAddressLine2">
								<Label>
									<span>Address Line 2</span>
								</Label>
								<Form.Control
									name="shippingAddressLine2"
									type="text"
									defaultValue={
										ewayBillData.shippingInfo !== undefined &&
										ewayBillData.shippingInfo.addressLine2 !== undefined
											? ewayBillData.shippingInfo.addressLine2
											: ""
									}
									ref={register({})}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="shippingCity">
								<Label>
									<span>
										City <RequiredStar />
									</span>
								</Label>
								{errors.shippingCity && (
									<p className="form-error-inline">
										{errors.shippingCity.message}
									</p>
								)}
								<Form.Control
									name="shippingCity"
									type="text"
									defaultValue={
										ewayBillData.shippingInfo !== undefined &&
										ewayBillData.shippingInfo.city !== undefined
											? ewayBillData.shippingInfo.city
											: ""
									}
									ref={register({ required: "Required" })}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="shippingState">
								<Label>
									<span>
										State <RequiredStar />
									</span>
									{errors.shippingState && (
										<p className="form-error-inline">
											{errors.shippingState.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="shippingState"
									type="text"
									defaultValue={
										ewayBillData.shippingInfo !== undefined &&
										ewayBillData.shippingInfo.state !== undefined
											? ewayBillData.shippingInfo.state
											: ""
									}
									ref={register({ required: "Required" })}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="shippingPostalCode">
								<Label>
									<span>
										Postal Code <RequiredStar />
									</span>
									{errors.shippingPostalCode && (
										<p className="form-error-inline">
											{errors.shippingPostalCode.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="shippingPostalCode"
									type="text"
									defaultValue={
										ewayBillData.shippingInfo !== undefined &&
										ewayBillData.shippingInfo.pin !== undefined
											? ewayBillData.shippingInfo.pin
											: ""
									}
									ref={register({
										required: "Required",
										pattern: {
											value: /^\d*$/,
											message: "Invalid",
										},
									})}
								/>
							</Form.Group>
						</Grid>
					</Grid>
				</PaperBox>

				<Typography variant="body1" gutterBottom color="secondary">
					Note: Skip this section, If there is no Shipping Info.
				</Typography>

				<Box component={Grid} container mt={5} mb={3} justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							onClick={handleBack}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleSkip}
							style={{ marginRight: "1rem" }}
							disableFocusRipple
						>
							Skip
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>
			</Form>
		</>
	);
};
