import { Auth } from "aws-amplify";
import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL_BASE;

// Get User Details
export async function getProfile() {
	try {
		const { idToken } = await Auth.currentSession();
		return http.get(`${apiUrl}/user/${localStorage.getItem("sub")}`, {
			headers: {
				"content-type": "application/json",
				authorization: idToken.jwtToken,
			},
		});
	} catch (e) {
		return false;
	}
}

// Get Org Details
export async function getOrgProfile(orgId) {
	const { idToken } = await Auth.currentSession();

	const url = `${apiUrl}/org/${orgId}`;

	return http.get(url, {
		headers: {
			"content-type": "application/json",
			authorization: idToken.jwtToken,
		},
	});
}

// Get Linked Organisations List for an user
export async function getCustomerList(userId, orgId) {
	const { idToken } = await Auth.currentSession();

	const url = `${apiUrl}/org`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export function updateProfileSocials(userId, authDetails, fbID, googleID) {
	// console.log({ user_id }, { authDetails });
	return http.put(`${apiUrl}/profiles/social/${userId}`, {
		_id: userId,

		fbID: authDetails.type === "facebook" ? authDetails.userID || undefined : fbID || undefined,
		googleID:
			authDetails.type === "google" ? authDetails.userID || undefined : googleID || undefined,
	});
}

export default {
	getProfile,
	updateProfileSocials,
};
