import { Auth } from "aws-amplify";
import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL_BASE;

export async function getOrgDetails(orgId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function updateOrgDetails(orgId, updatedData) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}`;

	return http.patch(url, updatedData, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function registerOrgDetails(orgData, userData) {
	let orgUrl = apiUrl;
	orgUrl += `/registration`;

	const formattedOrgData = {
		organization: {
			name: orgData.orgName,
			contactName: orgData.contactName,
			contactNumber: orgData.contactNumber,
			contactEmail: orgData.contactEmail,
			region: orgData.country,
			address: {
				addressLine1: orgData.addressLine1,
				addressLine2: orgData.addressLine2,
				city: orgData.city,
				pin: orgData.postalCode,
				postalCode: orgData.postalCode,
				state: orgData.state,
				country: orgData.country,
			},
			financials: {
				lastyearRevenue: orgData.lastYearRevenue,
				AvgRevenueforlast3yrs: orgData.avgRevenueForLast3yrs,
				yearofIncorporation: orgData.yearOfIncorporation,
				numberofEmployees: orgData.numberOfEmployee,
				numberofInvoicespermonth: orgData.numberOfInvoicesPerMonth,
			},
			stripePaymentToken: "dummystripetoken",
			quota: 0,
			subscriptionValidity: true,
			gstn: orgData.orgGstNumber,
			panNumber: orgData.orgPanNumber,
		},
		user: {
			firstName: userData.firstName,
			lastName: userData.lastName,
			email: userData.email,
			phNum: userData.phNum,
			phCountryCode: userData.phCountryCode,
			userRole: [userData.userRole],
			country: orgData.country,
		},
	};

	return http.post(orgUrl, formattedOrgData, {
		headers: {
			"content-type": "application/json",
		},
	});
}

export async function getOrgListByStatus(status, pageIndex, pageSize, sortBy, searchQuery) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/admin/org?active=${status}&offset=${pageIndex}&size=${pageSize}`;

	if (searchQuery !== "W10=") url += `&search=${searchQuery}`;

	if (sortBy?.length)
		sortBy.forEach((item) => {
			url += `&sort=${item.id},${item.desc ? "desc" : "asc"}`;
		});

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function getOrgDetailsByOrgId(orgId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/admin/org/${orgId}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function updateOrgDetailsByAdmin(orgId, updatedData) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/admin/org/${orgId}`;

	return http.patch(url, updatedData, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export default {
	getOrgDetails,
	updateOrgDetails,
	registerOrgDetails,
	getOrgListByStatus,
	getOrgDetailsByOrgId,
	updateOrgDetailsByAdmin,
};
