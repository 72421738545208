export default {
	formId: "registrationForm",
	formField: {
		orgName: {
			name: "orgName",
			label: "Org Name*",
			requiredErrorMsg: "Name is required",
		},
		contactName: {
			name: "contactName",
			label: "Contact Name*",
			requiredErrorMsg: "Contact Name  is required",
		},
		contactNumber: {
			name: "contactNumber",
			label: "Contact Number*",
			requiredErrorMsg: "Contact Number  is required",
			invalidErrorMsg: "Contact Number is not valid.",
		},
		contactEmail: {
			name: "contactEmail",
			label: "Contact Email*",
			requiredErrorMsg: "Contact Email is required",
			invalidErrorMsg: "Contact Email is not valid.",
		},

		region: {
			name: "region",
			label: "Region*",
			requiredErrorMsg: "Region  is required",
		},
		addressLine1: {
			name: "addressLine1",
			label: "Address Line 1*",
			requiredErrorMsg: "Address Line 1 is required",
		},
		addressLine2: {
			name: "addressLine2",
			label: "Address Line 2",
		},
		city: {
			name: "city",
			label: "City*",
			requiredErrorMsg: "City is required",
		},
		state: {
			name: "state",
			label: "State*",
			requiredErrorMsg: "State is required",
		},
		postalCode: {
			name: "postalCode",
			label: "PostalCode*",
			requiredErrorMsg: "PostalCode is required",
			invalidErrorMsg: "Zipcode is not valid (e.g. 70000)",
		},
		country: {
			name: "country",
			label: "Country*",
			requiredErrorMsg: "Country is required",
		},
		orgPanNumber: {
			name: "orgPanNumber",
			label: "Pan Number",
			invalidErrorMsg: "Pan Number is not valid.",
		},
		orgGstNumber: {
			name: "orgGstNumber",
			label: "GST Number*",
			requiredErrorMsg: "GST Number is required",
			invalidErrorMsg: "GST Number is not valid.",
		},
		lastYearRevenue: {
			name: "lastYearRevenue",
			label: "Last Year Revenue*",
			requiredErrorMsg: "Last Year Revenue is required",
			invalidErrorMsg: "Last Year Revenue is not valid.",
		},
		avgRevenueForLast3yrs: {
			name: "avgRevenueForLast3yrs",
			label: "Avg Revenue For Last 3yrs*",
			requiredErrorMsg: "Avg Revenue For Last 3yrs is required",
			invalidErrorMsg: "Avg Revenue For Last 3yrs is not valid.",
		},
		yearOfIncorporation: {
			name: "yearOfIncorporation",
			label: "Year Of Incorporation*",
			requiredErrorMsg: "Year Of Incorporation is required",
			invalidErrorMsg: "Year Of Incorporation is not valid.",
		},
		numberOfEmployee: {
			name: "numberOfEmployee",
			label: "Number Of Employee(s)*",
			requiredErrorMsg: "Number Of Employee is required",
			invalidErrorMsg: "Number Of Employee is not valid.",
		},
		numberOfInvoicesPerMonth: {
			name: "numberOfInvoicesPerMonth",
			label: "Expected Number of Invoices / Month*",
			requiredErrorMsg: "Selection Required",
		},
		firstName: {
			name: "firstName",
			label: "First Name*",
			requiredErrorMsg: "First Name is required",
		},
		lastName: {
			name: "lastName",
			label: "Last Name*",
			requiredErrorMsg: "Last Name is required",
		},
		email: {
			name: "email",
			label: "Email*",
			requiredErrorMsg: "Email is required",
			invalidErrorMsg: "Email is not valid.",
		},
		phNum: {
			name: "phNum",
			label: "Phone Number*",
			requiredErrorMsg: "Phone Number is required",
		},
		phCountryCode: {
			name: "phCountryCode",
			label: "Country Code*",
			requiredErrorMsg: "Country Code is required",
		},
		userRole: {
			name: "userRole",
			label: "Role*",
			requiredErrorMsg: "Role is required",
		},
		userCountry: {
			name: "userCountry",
			label: "Country*",
			requiredErrorMsg: "Country is required",
		},
		userActive: {
			name: "userActive",
			label: "IsActive*",
		},
		termsAccepted: {
			name: "termsAccepted",
			label: "Terms*",
		},
	},
};
