import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box, Button, Grid, Typography, IconButton } from "@material-ui/core";
import { useForm } from "react-hook-form";
import _ from "lodash";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import { Label, PaperBox, RequiredStar } from "../../../styles/styles";
import SelectIRNModal from "../../eInvoices/SelectIRNModal";

export default ({ orgId, ewayBillData, setEwayBillData, handleNext }) => {
	const history = useHistory();

	useEffect(() => {
		// console.log("Step 1 Entry: ", ewayBillData);
	}, [ewayBillData]);

	const { register, errors, handleSubmit } = useForm({
		mode: "onTouched",
		defaultValues: {
			...ewayBillData,
		},
	});

	const [isIRNSelected, setIRNSelected] = useState(true);
	const [selectedIRN, setSelectedIRN] = useState();

	const openIRNDialog = () => {
		setShowIRNModal(true);
	};

	const [showIRNSearchModal, setShowIRNModal] = useState(!isIRNSelected);
	const handleIRNModalClose = () => setShowIRNModal(false);

	const irnSelector = (choosenIRN) => {
		setSelectedIRN(choosenIRN);
		setIRNSelected(true);
		if (errors.irn) {
			errors.irn.message = "";
		}
	};

	const NextStep = (data) => {
		console.log(data);

		const transportInfo = {
			name: data.name,
			gstin: data.gstn,
			mode: data.mode,
			distance: data.distance,
			documentNBR: data.documentNBR,
			documentDate: data.documentDate,
			vehicleNBR: data.vehicleNBR,
			vehicleType: data.vehicleType,
		};

		_.mapKeys(transportInfo, (value, key) => {
			if (_.isEmpty(value)) delete transportInfo[key];
		});

		const formattedData = {
			invoiceRefNBR: data.irn,
			transportInfo,
		};

		// console.log("Step 1 Exit: ", formattedData);

		setEwayBillData(formattedData);
		handleNext();
	};

	const handleCancel = () => {
		history.push("/eWayBills");
	};

	return (
		<>
			<Form onSubmit={handleSubmit(NextStep)}>
				<Box component={Grid} mb={5} container justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>

				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<Form.Group controlId="irn">
							<Label>
								<span>
									Invoice Reference Number (IRN)
									<RequiredStar />
									<Box component={Tooltip} ml={1} title="Search">
										<IconButton
											color="primary"
											variant="contained"
											aria-label="search"
											component="span"
											size="small"
											onClick={openIRNDialog}
										>
											<SearchIcon />
										</IconButton>
									</Box>
								</span>
								{errors.irn && (
									<p className="form-error-inline">{errors.irn.message}</p>
								)}
							</Label>
							<Form.Control
								name="irn"
								type="text"
								defaultValue={
									ewayBillData !== undefined &&
									ewayBillData.invoiceRefNBR !== undefined
										? ewayBillData.invoiceRefNBR
										: ""
								}
								value={selectedIRN}
								ref={register({
									required: "Required",
								})}
								onChange={(e) => {
									setSelectedIRN(e.target.value);
								}}
							/>
							<SelectIRNModal
								orgId={orgId}
								irnSelector={irnSelector}
								showIRNSearchModal={showIRNSearchModal}
								handleClose={handleIRNModalClose}
							/>
						</Form.Group>
					</Grid>
				</Grid>

				<PaperBox rounded>
					<Typography variant="h6" gutterBottom>
						Transport Details
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Form.Group controlId="gstn">
								<Label>
									<span>
										GSTN
										<RequiredStar />
									</span>
									{errors.gstn && (
										<p className="form-error-inline">{errors.gstn.message}</p>
									)}
								</Label>
								<Form.Control
									name="gstn"
									type="text"
									defaultValue={
										ewayBillData.transportInfo !== undefined &&
										ewayBillData.transportInfo.gstin !== undefined
											? ewayBillData.transportInfo.gstin
											: ""
									}
									ref={register({
										required: "Required",
									})}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="name">
								<Label>
									<span>Name</span>
								</Label>
								<Form.Control
									name="name"
									type="text"
									defaultValue={
										ewayBillData.transportInfo !== undefined &&
										ewayBillData.transportInfo.name !== undefined
											? ewayBillData.transportInfo.name
											: ""
									}
									ref={register()}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="mode">
								<Label>
									<span>Mode</span>
									{errors.mode && (
										<p className="form-error-inline">{errors.mode.message}</p>
									)}
								</Label>
								<Form.Control
									as="select"
									name="mode"
									ref={register()}
									defaultValue={
										ewayBillData.transportInfo !== undefined &&
										ewayBillData.transportInfo.mode !== undefined
											? ewayBillData.transportInfo.mode
											: ""
									}
								>
									<option key="" value="">
										{}
									</option>
									<option key="Road" value="Road">
										Road
									</option>
									<option key="Rail" value="Rail">
										Rail
									</option>
									<option key="Air" value="Air">
										Air
									</option>
									<option key="Ship" value="Ship">
										Ship
									</option>
								</Form.Control>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="distance">
								<Label>
									<span>
										Distance
										<RequiredStar />
									</span>
									{errors.distance && (
										<p className="form-error-inline">
											{errors.distance.message}
										</p>
									)}
								</Label>
								<Form.Control
									name="distance"
									type="text"
									defaultValue={
										ewayBillData.transportInfo !== undefined &&
										ewayBillData.transportInfo.distance !== undefined
											? ewayBillData.transportInfo.distance
											: "0"
									}
									ref={register({ required: true })}
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="documentNBR">
								<Label>
									<span>Document Number</span>
								</Label>
								<Form.Control
									defaultValue={
										ewayBillData.transportInfo !== undefined &&
										ewayBillData.transportInfo.documentNBR !== undefined
											? ewayBillData.transportInfo.documentNBR
											: ""
									}
									ref={register()}
									name="documentNBR"
									type="text"
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="documentDate">
								<Label>
									<span>Document Date (DD/MM/YYYY)</span>
								</Label>
								<Form.Control
									defaultValue={
										ewayBillData.transportInfo !== undefined &&
										ewayBillData.transportInfo.documentDate !== undefined
											? ewayBillData.transportInfo.documentDate
											: ""
									}
									ref={register()}
									name="documentDate"
									type="text"
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="vehicleNBR">
								<Label>
									<span>Vehicle Number</span>
								</Label>
								<Form.Control
									defaultValue={
										ewayBillData.transportInfo !== undefined &&
										ewayBillData.transportInfo.vehicleNBR !== undefined
											? ewayBillData.transportInfo.vehicleNBR
											: ""
									}
									ref={register()}
									name="vehicleNBR"
									type="text"
								/>
							</Form.Group>
						</Grid>

						<Grid item xs={12} md={6}>
							<Form.Group controlId="vehicleType">
								<Label>
									<span>Vehicle Type</span>
								</Label>
								<Form.Control
									as="select"
									name="vehicleType"
									defaultValue={
										ewayBillData.transportInfo !== undefined &&
										ewayBillData.transportInfo.vehicleType !== undefined
											? ewayBillData.transportInfo.vehicleType
											: ""
									}
									ref={register()}
								>
									<option key="" value="">
										{}
									</option>
									<option key="Regular" value="Regular">
										Regular
									</option>
									<option key="ODC" value="ODC">
										ODC
									</option>
								</Form.Control>
							</Form.Group>
						</Grid>
					</Grid>
				</PaperBox>

				<Box component={Grid} container mt={5} mb={3} justify="space-between">
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
						disableFocusRipple
					>
						Cancel
					</Button>
					<div>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disableFocusRipple
						>
							Next
						</Button>
					</div>
				</Box>
			</Form>
		</>
	);
};
