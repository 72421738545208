import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Button, Grid, Typography } from "@material-ui/core";
import {} from "styled-components/macro";
import { PaperBase } from "../../../styles/styles";
import { useProfile } from "../../../hooks/profile";
import { geteWayBill } from "../../../services/gisService";

export const EWayBillDetail = () => {
	const { orgId } = useProfile();
	const params = useParams();
	const { ewaybillNBR } = params;

	const history = useHistory();
	// const [isLoading, setLoading] = useState(false);
	const [ewayBillDetail, setEwayBillDetails] = useState();

	useEffect(() => {
		(async () => {
			const { data } = await geteWayBill(orgId, ewaybillNBR);
			// console.log({ eWayBill_Details: data });
			setEwayBillDetails(data);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId, ewaybillNBR]);

	return (
		<Container>
			<PaperBase
				css={`
					height: fit-content;
				`}
			>
				{ewayBillDetail && (
					<>
						<div
							css={`
								margin-bottom: 28px;
							`}
						>
							<Typography
								variant="h5"
								component="h1"
								css={`
									margin-bottom: 5px;
								`}
							>
								eWayBill Details
							</Typography>

							<Button
								color="secondary"
								onClick={history.goBack}
								css={`
									padding: 0 2px;
									min-width: auto;
								`}
								disableElevation
							>
								Back
							</Button>
						</div>

						<Grid container spacing={4}>
							<Grid item className="data-display" xs={12} md={12}>
								<span>Invoice Reference Number</span>
								<span>{ewayBillDetail.invoiceRefNBR || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>eWayBill Number</span>
								<span>{ewayBillDetail.ewaybillNBR || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Status</span>
								<span>{ewayBillDetail.status || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Generation Date</span>
								<span>{ewayBillDetail.generationDate || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Expiry Date</span>
								<span>{ewayBillDetail.expiryDate || "N/A"}</span>
							</Grid>
						</Grid>
					</>
				)}
			</PaperBase>
		</Container>
	);
};

export default EWayBillDetail;
