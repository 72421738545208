import FormModel from "./FormModel";

const {
	formField: {
		orgName,
		contactName,
		contactNumber,
		contactEmail,
		region,
		addressLine1,
		addressLine2,
		city,
		state,
		country,
		postalCode,
		orgPanNumber,
		orgGstNumber,
		lastYearRevenue,
		avgRevenueForLast3yrs,
		yearOfIncorporation,
		numberOfEmployee,
		numberOfInvoicesPerMonth,
		firstName,
		lastName,
		email,
		phNum,
		phCountryCode,
		termsAccepted,
		userActive,
		userRole,
		userCountry,
	},
} = FormModel;

export default {
	[orgName.name]: "",
	[contactName.name]: "",
	[contactNumber.name]: "",
	[contactEmail.name]: "",
	[region.name]: "",
	[addressLine1.name]: "",
	[addressLine2.name]: "",
	[city.name]: "",
	[state.name]: "",
	[country.name]: "",
	[postalCode.name]: "",
	[orgPanNumber.name]: "",
	[orgGstNumber.name]: "",
	[lastYearRevenue.name]: "",
	[avgRevenueForLast3yrs.name]: "",
	[yearOfIncorporation.name]: "",
	[numberOfEmployee.name]: "",
	[numberOfInvoicesPerMonth.name]: "",
	[firstName.name]: "",
	[lastName.name]: "",
	[email.name]: "",
	[phNum.name]: "",
	[phCountryCode.name]: "",
	[termsAccepted.name]: "",
	[userActive.name]: false,
	[userRole.name]: "Admin",
	[userCountry.name]: "",
};
