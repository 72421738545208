import { Auth } from "aws-amplify";
import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL_BASE;

export async function getActiveOrgUserList(orgId, pageIndex, pageSize, sortBy) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/user?offset=${pageIndex}&size=${pageSize}`;

	if (sortBy?.length)
		sortBy.forEach((item) => {
			url += `&sort=${item.id},${item.desc ? "desc" : "asc"}`;
		});

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function getInactiveOrgUserList(orgId, pageIndex, pageSize, sortBy) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/user?active=false&offset=${pageIndex}&size=${pageSize}`;

	if (sortBy?.length)
		sortBy.forEach((item) => {
			url += `&sort=${item.id},${item.desc ? "desc" : "asc"}`;
		});

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function getOrgUserDetails(orgId, userId) {
	const { idToken } = await Auth.currentSession();

	const url = `${apiUrl}/org/${orgId}/user/${userId}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function updateOrgUserDetails(orgId, userId, updatedData) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/user/${userId}`;

	return http.patch(url, updatedData, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function createOrgUserDetails(orgId, data) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/user`;

	return http.post(url, data, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function reInviteOrgUser(orgId, userId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/user/${userId}/reinvite`;

	return http.post(
		url,
		{},
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export async function deactivateOrgUser(orgId, userId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/user/${userId}/disable`;

	return http.post(
		url,
		{},
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export async function activateOrgUser(orgId, userId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/user/${userId}/enable`;

	return http.post(
		url,
		{},
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export async function disassociateOrgUser(orgId, userId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/user/${userId}/deassociate`;

	return http.post(
		url,
		{},
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export async function reInviteOrgUserByAdmin(email) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/admin/user/reinvite/email/${email}`;

	return http.post(
		url,
		{},
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export default {
	getActiveOrgUserList,
	getInactiveOrgUserList,
	getOrgUserDetails,
	updateOrgUserDetails,
	createOrgUserDetails,
	reInviteOrgUser,
	activateOrgUser,
	deactivateOrgUser,
	disassociateOrgUser,
	reInviteOrgUserByAdmin,
};
