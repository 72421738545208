import { Auth } from "aws-amplify";
import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL_BASE;

// Create stripe session
export async function createStripeSession(orgId, plan, metered, planName) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/registration/org/${orgId}/stripe/session/plan/${plan}/metered/${metered}/planName/${planName}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function getStripeSession(orgId, sessionId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/registration/org/${orgId}/stripe/session/${sessionId}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function getStripePlans() {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/registration/stripe/plans`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function findAllSubscriptions(orgId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/checkout/subscriptions`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function findSubscriptionById(orgId, subscriptionId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/checkout/subscriptions/${subscriptionId}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function cancelSubscriptionById(orgId, subscriptionId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/checkout/subscriptions/${subscriptionId}`;

	return http.delete(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function usageReportSummary(orgId, subscriptionId, subscriptionItemId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/checkout/subscriptions/${subscriptionId}/subscriptionitem/${subscriptionItemId}/usagereport`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function getActiveSubscription(orgId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/registration/org/${orgId}/subscription`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export default {
	createStripeSession,
	getStripeSession,
	getStripePlans,
	findAllSubscriptions,
	findSubscriptionById,
	cancelSubscriptionById,
	usageReportSummary,
	getActiveSubscription,
};
