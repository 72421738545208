import React from "react";

import { at } from "lodash";
import { useField } from "formik";
import { InputLabel, FormControl, Select, MenuItem, FormHelperText } from "@material-ui/core";

function SelectField(props) {
	const { label, data, ...rest } = props;
	const [field, meta] = useField(props);
	const { value: selectedValue } = field;
	const [touched, error] = at(meta, "touched", "error");
	const isError = touched && error && true;
	function renderHelperText() {
		if (isError) {
			return <FormHelperText>{error}</FormHelperText>;
		}
		return true;
	}

	return (
		<FormControl variant="outlined" {...rest} error={isError}>
			<InputLabel>{label}</InputLabel>
			<Select {...field} value={selectedValue ?? selectedValue} label={label}>
				{data.map((item, index) => (
					<MenuItem key={index} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
			{renderHelperText()}
		</FormControl>
	);
}

export default SelectField;
