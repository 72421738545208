import React from "react";
import { Grid, Typography, Container } from "@material-ui/core";
import InputField from "../../../common/InputField";
import { PaperBox } from "../../../../styles/styles";

export default function UserForm(props) {
	const {
		formField: { firstName, lastName, email, phNum, phCountryCode },
	} = props;

	return (
		<Container>
			<PaperBox>
				<Typography variant="h6" gutterBottom>
					User Details
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<InputField name={firstName.name} label={firstName.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<InputField name={lastName.name} label={lastName.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<InputField name={email.name} label={email.label} fullWidth />
					</Grid>
					<Grid item xs={12} md={2}>
						<InputField
							name={phCountryCode.name}
							label={phCountryCode.label}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<InputField name={phNum.name} label={phNum.label} fullWidth />
					</Grid>
					{/* <Grid item xs={12} md={6}>
					<InputField name={userRole.name} label={userRole.label} fullWidth />
				</Grid>
				<Grid item xs={12} md={4}>
					<InputField name={userCountry.name} label={userCountry.label} fullWidth />
				</Grid> */}
				</Grid>
			</PaperBox>
		</Container>
	);
}
