// This file is for styled-components that are used globally!
// Keep single-use styled-components in their respective files.

import React from "react";
import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Fade,
	FormHelperText,
	Grid,
	IconButton,
	Paper,
	Slide,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import { Form } from "react-bootstrap";
import styled from "styled-components/macro";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";

// Layouts

export const PaperBase = styled(Box).attrs((props) => ({
	component: Paper,
	p: props.p || 5,
	elevation: 0,
}))`
	/* box-shadow: 0 10px 40px -10px rgb(0 0 0 / 30%) !important; */
	box-shadow: ${(props) => props.hoverShadow && "0 4px 5px 0 rgb(31 38 135 / 13%)"};

	${(props) => props.hoverShadow && "&:hover, &:focus-within {"}
	box-shadow: ${(props) =>
		!props.removeShadow && "0 8px 20px 0 rgb(31 38 135 / 12.5%) !important"};
	${(props) => props.hoverShadow && "} "}

	background: rgba(255, 255, 255, 0.9) !important;
	border: 1px solid rgba(255, 255, 255, 0.18) !important;
	/* border: ${(props) =>
		(props.hoverShadow || props.darkerBorder) && "1px solid rgba(0, 0, 0, 0.15) !important"}; */
	border-radius: ${(props) => (props.radius ? `${props.radius} !important` : "10px !important")};

	margin-top: ${(props) => props.gap && "1.55rem"};
	/* overflow: hidden; */
`;

// TODO: scroll effect for PaperBox to highlight borders when in primary view
export const PaperBox = styled(Paper).attrs((props) => ({
	variant: "outlined",
	square: !props.rounded && true,
}))`
	padding: 40px 30px 35px 30px;
	border-radius: ${(props) => props.rounded && "5px"};

	margin-bottom: 1.5rem;
`;

// Form Elements

/** [span (RequiredStar) /span] {error} */
export const Label = styled(Form.Label)`
	display: flex;
	justify-content: space-between;
	font-weight: 700;
	color: ${(props) => (props.color ? props.color : props.theme.light.font.lightBlack)};
`;

export const RequiredStar = styled.span`
	&::after {
		content: "*";
	}
	color: ${(props) => props.theme.common.required};
`;

/**
 * Circular Spinner for Button loading
 *
 * @param {integer} size - Size of Spinner
 */
export const Spinner = styled(CircularProgress)`
	margin-left: ${(props) => props.ml || "10px"};
	margin-right: ${(props) => props.mr};
	margin-top: ${(props) => props.mt};
	margin-bottom: ${(props) => props.mb};
`;

/**
 * Refresh Button
 */
export const RefreshButton = styled(Button)`
	border-radius: 2px !important;
	border: 1px solid ${(props) => props.theme.light.secondary} !important;
	color: ${(props) => props.theme.light.font.black} !important;

	&:hover {
		background-color: ${(props) => props.theme.light.secondary} !important;
		color: ${(props) => props.theme.light.font.white} !important;
	}
`;

/**
 * List of [Line Item] Errors
 *
 * @param {Array} errors - {errors.items} - Items list
 * @param {number} index - Current iteration
 */
export const ErrorList = ({ errors, index }) => (
	<Box
		component={Grid}
		mt={1}
		mb={3}
		container
		direction="column"
		style={{ display: !errors?.[index] && "none" }}
	>
		{errors?.[index] &&
			Object.entries(errors[index]).map((error) => (
				<p className="form-error-inline">{error[1].message}</p>
			))}
	</Box>
);

/**
 * Modal System
 *
 * @param {string} id - ID for modal
 * @param {string} title - Text for for Modal Header
 * @param {boolean} open - State for opening/closing Modal
 * @param {boolean} [noDividers=false] - Removes top dividers in Modal
 * @param {callback} onClose
 */
export const SelectorModal = ({
	id,
	title,
	open,
	onClose,
	noDividers,
	hideCloseButton = false,
	extraHeaders,
	extraHeadersCondition,
	children,
	...other
}) => {
	const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

	return (
		<Dialog
			aria-labelledby={id}
			open={open}
			onClose={onClose}
			fullScreen={fullScreen}
			TransitionComponent={fullScreen ? SlideTransition : Fade}
			{...other}
		>
			<Grid
				container
				component={DialogTitle}
				id={id}
				justify="space-between"
				alignItems="center"
				disableTypography
			>
				<Typography variant="h5">{title}</Typography>
				<Grid item>
					{extraHeadersCondition && extraHeaders}
					{!hideCloseButton && (
						<IconButton aria-label="close" onClick={onClose}>
							<CloseIcon />
						</IconButton>
					)}
				</Grid>
			</Grid>

			<DialogContent dividers={!noDividers}>
				<Box p={1} pb={2}>
					{children}
				</Box>
			</DialogContent>
		</Dialog>
	);
};
const SlideTransition = (props) => <Slide direction="up" {...props} />;

/**
 * Reload + Dismiss Buttons for Snackbar Toasts
 *
 * @param key - key of the enqueueSnackbar
 */
export const ReloadDismissButtons = ({ key }) => {
	const { closeSnackbar } = useSnackbar();
	return (
		<>
			<WhiteTextButton
				aria-label="reload"
				size="small"
				onClick={() => {
					window.location.reload();
				}}
			>
				Reload
			</WhiteTextButton>
			<WhiteTextButton
				aria-label="dismiss"
				size="small"
				onClick={() => {
					closeSnackbar(key);
				}}
			>
				Dismiss
			</WhiteTextButton>
		</>
	);
};

/**
 * AlertBox primarily for Tables
 *
 * @param {string} [type="info"] - severity
 * @param {string} [variant="standard"] - standard/filled/outlined
 * @param {string} [mt=0] - margin top
 * @param {string} [mb="1rem"] - margin bottom
 * @param {boolean} [removePadding=false] - Remove all padding
 */
export const ErrorBox = ({
	type = "info",
	variant = "standard",
	mt = 0,
	mb = "1rem",
	removePadding = false,
	children,
	...rest
}) => (
	<Box mt={mt} mb={mb} {...rest}>
		<ErrorAlert severity={type} variant={variant} removePadding={removePadding}>
			{children}
		</ErrorAlert>
	</Box>
);

const ErrorAlert = styled(Alert)`
	padding-top: ${(props) => props.removePadding && "1px !important"};
	padding-bottom: ${(props) => props.removePadding && "0 !important"};
`;

export const CenteredErrorText = styled(Box).attrs((props) => ({
	display: props.display || "flex",
	justifyContent: props.justifyContent || "center",
	component: "span",
	mb: props.mb || "2rem",
	mt: props.mt || "0",
}))`
	font-weight: 600;
	color: ${(props) => (props.color ? props.color : props.theme.common.font.error)};
`;

const WhiteTextButton = styled(Button)`
	color: white !important;
	font-weight: 600;
`;

/**
 * Custom Info Tooltip
 *
 * @param {string} [type="info"] - severity
 */
export const InfoTooltip = ({ text, placement }) => (
	<Tooltip title={text} placement={placement} arrow interactive>
		<InfoIcon
			css={`
				font-size: 1.1rem !important;
				margin-left: 0.35rem !important;
				margin-bottom: 0.21rem !important;
				color: hsl(0, 0%, 20%);
			`}
		/>
	</Tooltip>
);

/**
 *	Chip Design for Searching / Filtering
 */
export const FilterChip = styled(Chip)`
	font-weight: 600;
	font-size: 0.88rem;
	height: 30px;

	border-radius: 3px;
	& svg {
		transform: scale(0.8);
	}

	/* background-color: ${(props) => props.theme.light.accent}; */
`;

/**
 * Error Helper Text
 */
export const ErrorHelperText = styled(FormHelperText)`
	color: red;
	font-weight: 600;
	max-height: 0;
`;

/**
 * Search Form
 */
export const SearchForm = styled.form`
	width: inherit;
`;

/**
 * Two Tab Titles
 */

export const TwoTabHeader = ({
	currentTable,
	altTable,
	setCardTitle,
	visitedCheck,
	visitedSetter,
}) => (
	<>
		<Typography variant="h5">{currentTable}</Typography>
		<Typography
			variant="body1"
			tabIndex="0"
			css={`
				color: ${(props) => props.theme.light.font.grey};
				margin-left: 1rem;
				position: relative;
				cursor: pointer;
				font-weight: 400;
				opacity: 0.9;
				font-size: 1.05rem;

				&:before,
				&:after {
					content: "";
					position: absolute;
					display: block;
					right: 0;
				}

				&:before {
					opacity: 0.75;
					height: 1px;
					margin-bottom: -3px;
					width: 100%;
					background: ${(props) => props.theme.light.font.grey};
					bottom: 0;
				}

				&:after {
					height: 3px;
					margin-top: 1px;
					width: 0;
					background: ${(props) => props.theme.light.primary};
					transition: width 0.2s ease-in-out;
				}
				&:hover {
					opacity: 1;
				}

				&:hover:after {
					width: 100%;
					left: 0;
				}
			`}
			onClick={() => {
				setCardTitle({
					currentTable: altTable,
					altTable: currentTable,
				});
				if (!visitedCheck) visitedSetter(true);
			}}
			onKeyPress={(event) => {
				if (event.key === "Enter") {
					setCardTitle({
						currentTable: altTable,
						altTable: currentTable,
					});
					if (!visitedCheck) visitedSetter(true);
				}
			}}
			role="button"
		>
			{altTable}
		</Typography>
	</>
);

// TODO: Implement CSS-in-JS to replace one-off inline styles

export const CompanyLogo = styled.img`
	background: rgba(255, 255, 255, 0.9);
	border: 1px solid hsl(0, 1%, 85%);
	border-radius: 5px;
`;
