import React from "react";
import styled from "styled-components";

const Footer = () => (
	<StyledFooter>
		<span>Build v191</span>
		<span>
			<a
				href="https://skyscendpay.com/privacy-policy"
				target="_blank"
				rel="nofollow noreferrer"
				title="Privacy Policy - click to read"
			>
				Privacy Policy
			</a>
			<span>© 2021 Skyscend LLC All rights reserved.</span>
		</span>
	</StyledFooter>
);

export default Footer;

const StyledFooter = styled.footer`
	z-index: 3;
	width: -webkit-fill-available;

	position: fixed;
	bottom: 0;

	background-color: ${(props) => props.theme.light.secondary};

	margin-left: 1rem;
	margin-right: 1rem;

	border-top-left-radius: 3px;
	border-top-right-radius: 3px;

	display: flex;
	justify-content: space-between;

	& span {
		color: ${(props) => props.theme.light.font.white};
		font-size: 0.75rem;
		padding: 1px 1rem;

		&:first-child {
			opacity: 0.2;
		}
	}

	& span a {
		color: ${(props) => props.theme.light.font.white} !important;
		font-size: 0.75rem;
		padding: 1px 1rem;
		text-decoration: none;
	}
`;
