import { Auth } from "aws-amplify";
import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL_BASE;

export async function getActiveOrgCustomerList(orgId, pageIndex, pageSize, sortBy) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/customer?offset=${pageIndex}&size=${pageSize}`;

	if (sortBy?.length)
		sortBy.forEach((item) => {
			url += `&sort=${item.id},${item.desc ? "desc" : "asc"}`;
		});

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function getInactiveOrgCustomerList(orgId, pageIndex, pageSize, sortBy) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/customer?active=false&offset=${pageIndex}&size=${pageSize}`;

	if (sortBy?.length)
		sortBy.forEach((item) => {
			url += `&sort=${item.id},${item.desc ? "desc" : "asc"}`;
		});

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function getOrgCustomerDetails(orgId, customerId) {
	const { idToken } = await Auth.currentSession();

	const url = `${apiUrl}/org/${orgId}/customer/${customerId}`;

	return http.get(url, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function updateOrgCustomerDetails(orgId, customerId, updatedData) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/customer/${customerId}`;

	return http.patch(url, updatedData, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function createOrgCustomerDetails(orgId, data) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/customer`;

	return http.post(url, data, {
		headers: {
			authorization: idToken.jwtToken,
		},
	});
}

export async function deactivateOrgCustomer(orgId, customerId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/customer/${customerId}/disable`;

	return http.post(
		url,
		{},
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export async function activateOrgCustomer(orgId, customerId) {
	const { idToken } = await Auth.currentSession();

	let url = apiUrl;
	url += `/org/${orgId}/customer/${customerId}/enable`;

	return http.post(
		url,
		{},
		{
			headers: {
				authorization: idToken.jwtToken,
			},
		}
	);
}

export default {
	getActiveOrgCustomerList,
	getInactiveOrgCustomerList,
	getOrgCustomerDetails,
	updateOrgCustomerDetails,
	createOrgCustomerDetails,
	activateOrgCustomer,
	deactivateOrgCustomer,
};
