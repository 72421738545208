import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Button, Grid, Typography } from "@material-ui/core";
import {} from "styled-components/macro";
import { PaperBase } from "../../../styles/styles";
import { useProfile } from "../../../hooks/profile";
import { geteInvoiceResponse } from "../../../services/gisService";

export const EInvoiceDetail = () => {
	const { orgId } = useProfile();
	const params = useParams();
	const { irn } = params;

	const history = useHistory();
	// const [isLoading, setLoading] = useState(false);
	const [invoiceDetail, setInvoiceDetails] = useState();

	useEffect(() => {
		(async () => {
			const { data } = await geteInvoiceResponse(orgId, irn);
			// console.log({ Invoice_Details: data });
			setInvoiceDetails(data);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId, irn]);

	return (
		<Container>
			<PaperBase
				css={`
					height: fit-content;
				`}
			>
				{invoiceDetail && (
					<>
						<div
							css={`
								margin-bottom: 28px;
							`}
						>
							<Typography
								variant="h5"
								component="h1"
								css={`
									margin-bottom: 5px;
								`}
							>
								Invoice Details
							</Typography>
							<Button
								color="secondary"
								onClick={history.goBack}
								css={`
									padding: 0 2px;
									min-width: auto;
								`}
								disableElevation
							>
								Back
							</Button>
						</div>

						<Grid container spacing={2}>
							<Grid item className="data-display" xs={12} md={12}>
								<span>Invoice Reference Number</span>
								<span>{invoiceDetail.invoiceRefNBR || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Acknowledgement Number</span>
								<span>{invoiceDetail.ackNBR || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Acknowledge Date</span>
								<span>{invoiceDetail.ackDate || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>Status</span>
								<span>{invoiceDetail.status || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>eWay Bill Number</span>
								<span>{invoiceDetail.eWayBillNBR || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>eWay Bill Date</span>
								<span>{invoiceDetail.eWayBillGenDate || "N/A"}</span>
							</Grid>

							<Grid item className="data-display" xs={12} md={6}>
								<span>eWay Bill Expiry Date</span>
								<span>{invoiceDetail.eWayBillExpriyDate || "N/A"}</span>
							</Grid>

							{invoiceDetail.decodedQRCode && (
								<>
									<Grid item className="data-display" xs={12} md={6}>
										<span>Seller GSTN</span>
										<span>
											{invoiceDetail.decodedQRCode.SellerGstin || "N/A"}
										</span>
									</Grid>

									<Grid item className="data-display" xs={12} md={6}>
										<span>Buyer GSTN </span>
										<span>
											{invoiceDetail.decodedQRCode.BuyerGstin || "N/A"}
										</span>
									</Grid>

									<Grid item className="data-display" xs={12} md={6}>
										<span>Document Number</span>
										<span>{invoiceDetail.decodedQRCode.DocNo || "N/A"}</span>
									</Grid>

									<Grid item className="data-display" xs={12} md={6}>
										<span>Document Type </span>
										<span>{invoiceDetail.decodedQRCode.DocTyp || "N/A"}</span>
									</Grid>

									<Grid item className="data-display" xs={12} md={6}>
										<span>Total Invoice Value</span>
										<span>
											{invoiceDetail.decodedQRCode.TotInvVal
												? Number(
														invoiceDetail.decodedQRCode.TotInvVal
												  ).toLocaleString("en-IN", {
														style: "currency",
														currency: "INR",
														minimumFractionDigits: 2,
														maximumFractionDigits: 4,
												  })
												: "N/A"}
										</span>
									</Grid>

									<Grid item className="data-display" xs={12} md={6}>
										<span>Item Count </span>
										<span>{invoiceDetail.decodedQRCode.ItemCnt || "N/A"}</span>
									</Grid>

									<Grid item className="data-display" xs={12} md={6}>
										<span>HSN Code</span>
										<span>
											{invoiceDetail.decodedQRCode.MainHsnCode || "N/A"}
										</span>
									</Grid>

									<Grid item className="data-display" xs={12} md={6}>
										<span>IRN Date </span>
										<span>{invoiceDetail.decodedQRCode.IrnDt || "N/A"}</span>
									</Grid>
								</>
							)}
						</Grid>
					</>
				)}
			</PaperBase>
		</Container>
	);
};

export default EInvoiceDetail;
