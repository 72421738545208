import React from "react";
import Helmet from "react-helmet";

const Titler = ({ title }) => {
	const defaultTitle = "Skyscend GeIS";
	const customTitle = `${title} | Skyscend GeIS`;
	return (
		<Helmet>
			<title>{title ? customTitle : defaultTitle}</title>
		</Helmet>
	);
};

// eslint-disable-next-line import/prefer-default-export
export { Titler };
